<template>
    <div id="optinmonster" />
  </template>

<script>
export default {
  name: 'OptinMonster',
  mounted () {
    this.loadScript()
  },
  methods: {
    loadScript () {
      const script = document.createElement('script')
      script.onload = () => console.log('OptinMonster component loaded')
      script.src = 'https://a.omappapi.com/app/js/api.min.js'
      script.async = true
      script.dataset.user = '261290'
      script.dataset.account = '278770'
      document.head.appendChild(script)
    }
  }
}
</script>
