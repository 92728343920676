import Vue from 'vue'
import * as upperFirst from 'lodash.upperfirst'
import * as camelCase from 'lodash.camelcase'

const requireComponent = require.context('../components/base', false, /base-[\w-]+\.vue$/)

requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName)
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, ''))
  )

  Vue.component(componentName, componentConfig.default || componentConfig)
})
