<template>
  <div class="app-footer secondary">
    <div class="content-center">
      <div class="content-constraint">
        <div class="footer-top">
          <div class="logo">
            <img src="/logo-light.svg" alt="logo">
          </div>
          <div class="footer-categories">
            <ul class="footer-category" aria-label="Shop">
              <div class="category-title">
                Shop
              </div>
              <li
                v-for="(category, i) in categories"
                :key="'category_' + i"
                class="link"
              >
                <button
                  v-if="category.data"
                  role="link"
                  @click="navigate(category)"
                >
                  {{ category.data.name }}
                </button>
              </li>
            </ul>

            <ul class="footer-category" aria-label="Events">
              <div class="category-title">
                Events
              </div>
              <li class="link">
                <nuxt-link to="/events" exact>
                  Upcoming Events
                </nuxt-link>
              </li>
              <li class="link">
                <nuxt-link to="/events?status=ended" exact>
                  Past Events
                </nuxt-link>
              </li>
            </ul>

            <ul class="footer-category mobile-category-padding" aria-label="About">
              <li class="category-title">
                About
              </li>
              <li class="link">
                <nuxt-link to="/pages/churches">
                  Churches
                </nuxt-link>
              </li>
              <li class="link">
                <nuxt-link to="/pages/about-us">
                  About Us
                </nuxt-link>
              </li>
              <li class="link">
                <nuxt-link to="/contact">
                  Contact Us
                </nuxt-link>
              </li>
              <li class="link">
                <a href="http://www.bakerpublishinggroup.com/careers" target="_blank">
                  Careers
                </nuxt-link>
              </a>
</li>
            </ul>
          </div>

          <div class="footer-store-info">
            <div class="store-info-title">
              Store Hours
            </div>
            <div v-if="!generalInfo.monday && !generalInfo.tuesday && !generalInfo.wednesday && !generalInfo.thursday && !generalInfo.friday" class="store-info">
              Monday&mdash;Friday: {{ generalInfo.weekday }}
            </div>
            <div v-if="generalInfo.monday" class="store-info">
              Monday: {{ generalInfo.monday }}
            </div>
            <div v-if="generalInfo.tuesday" class="store-info">
              Tuesday: {{ generalInfo.tuesday }}
            </div>
            <div v-if="generalInfo.wednesday" class="store-info">
              Wednesday: {{ generalInfo.wednesday }}
            </div>
            <div v-if="generalInfo.thursday" class="store-info">
              Thursday: {{ generalInfo.thursday }}
            </div>
            <div v-if="generalInfo.friday" class="store-info">
              Friday: {{ generalInfo.friday }}
            </div>
            <div class="store-info">
              Saturday: {{ generalInfo.saturday }}
            </div>
            <div class="store-info">
              Sunday: {{ generalInfo.sunday }}
            </div>
            <div v-if="generalInfo.holiday_1" class="store-info">
              {{ generalInfo.holiday_1 }}
            </div>
            <div v-if="generalInfo.holiday_2" class="store-info">
              {{ generalInfo.holiday_2 }}
            </div>

            <div class="store-info-title">
              Visit Us
            </div>
            <div class="store-info">
              {{ generalInfo.address }}
            </div>
            <div class="store-info">
              {{ generalInfo.city___state___zip }}
            </div>

            <div class="store-info-title">
              Call Us
            </div>
            <div class="store-info">
              {{ generalInfo.phone }}
            </div>
          </div>
          <social-follow-icons :social-links="generalInfo.social_links" />
        </div>
        <div class="footer-bottom">
          <div>{{ year }} Baker Book House Company</div>
          <nuxt-link class="link" to="/pages/privacy-policy">
            Privacy Policy
          </nuxt-link>
          <nuxt-link class="link" to="/pages/terms-of-use">
            Terms of Use
          </nuxt-link>
          <nuxt-link class="link" to="/pages/returns">
            Return Policy
          </nuxt-link>
          <nuxt-link class="link" to="/pages/shipping">
            Shipping
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { PRODUCTS_TYPES } from '@/store/products'
import SocialFollowIcons from '@/components/snippets/social-follow-icons'
import { getProductQuery } from '@/helpers/utilities'

export default {
  name: 'TheFooter',
  components: { SocialFollowIcons },
  computed: {
    ...mapState({
      generalInfo: state => state.layout.generalInfo,
      categories: state => state.products.categories
    }),
    year () {
      return new Date().getFullYear()
    }
  },
  methods: {
    ...mapActions({
      resetProductList: PRODUCTS_TYPES.actions.reset
    }),
    navigate (category) {
      const query = getProductQuery(category)
      if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
        return
      }
      this.resetProductList()
      this.$router.push({ name: 'products', query })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';

  .app-footer {
    z-index: 1;

    .logo {
      width: 20.2em;
      margin-top: 1.6em;
      img {
        width: 100%;
      }
    }
    .footer-top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 4.2em;
      border-bottom: 1px solid $white;
      padding-bottom: 3.8em;
      margin-bottom: 3.3em;
    }
  }

  @media only screen and (max-width: 900px) {
    .app-footer .logo {
      display: none;
    }
  }

  .footer-categories {
    display: flex;
    justify-content: space-between;
    flex-grow: 0.4;
  }

  .app-footer {
    .footer-category {
      .category-title {
        color: $white;
        font-size: 1.4em;
        margin-bottom: 0.6em;
      }
      .link {
        margin-bottom: 0.8em;
        a, button{
          color: $white;
          font-size: 1.2em;
          font-weight: $font-weight-thin;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .footer-store-info {
      font-weight: $font-weight-thin;
      .store-info-title {
        color: $white;
        font-size: 1.4em;
        font-weight: $font-weight-bold;
        margin-bottom: 0.6em;
        &:not(:first-of-type) {
          font-size: 1.2em;
          margin-top: 1.1em;
          margin-bottom: 0;
          font-weight: $font-weight-default;
        }
      }
      .store-info {
        color: $white;
        font-size: 1.2em;
        margin-bottom: 0.8em;
      }
    }
    .footer-follow-us {
      .title {
        font-size: 1.4em;
        margin-bottom: 0.6em;
        color: $white;
      }
      .links {
        display: flex;
        .mdi {
          font-size: 2.2em;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: $white;
          color: $charcoal-grey;
          width: 1em;
          height: 1em;
          border-radius: 100%;
          margin-right: 0.3em;
        }
      }
    }
    .footer-bottom {
      font-size: 1.2em;
      margin-bottom: 5.2em;
      div {
        color: $white;
        font-weight: $font-weight-bold;
      }
      .link {
        display: inline-block;
        text-decoration: none;
        margin-top: 0.3em;
        margin-right: 0.3em;
        border-right: 1px solid $white;
        padding-right: 0.6em;
        color: $white;
        &:last-of-type {
          border-right: none;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .app-footer {
      .footer-top {
        flex-direction: column;
        align-items: center;
        border: none;
      }
      .footer-categories {
        display: inline-block;
        column-count: 2;
      }
      .mobile-category-padding {
        padding-top: 1em;
      }
      .footer-category {
        .link {
          margin-bottom: .7em;
        }
        margin: 0 2em .7em;
      }
      .footer-store-info {
        text-align: center;
        margin-top: 4em;
        order: 1;
      }
      .footer-follow-us {
        width: 100%;
        border-top: 1px solid $white;
        padding-top: 2em;
        text-align: center;
        .links {
          display: inline-flex;
        }
      }
    }
    .footer-bottom {
      text-align: center;
      font-weight: $font-weight-thin;
    }
    .app-footer .footer-bottom .link {
      display: block;
      border: none;
    }
  }

  ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
