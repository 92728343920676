<template>
  <default-layout>
    <v-container class="my-5 content-constraint">
      <div class="d-flex">
        <client-only>
          <account-side-nav
            v-if="isAuthenticated"
            class="d-none d-md-flex flex-column"
          />
        </client-only>
        <div class="account-page-wrapper">
          <nuxt />
        </div>
      </div>
    </v-container>
  </default-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import DefaultLayout from './default'
import AccountSideNav from '@/components/pages/account/account-side-nav'
import { AUTH_TYPES } from '@/store/auth'

export default {
  name: 'Account',
  components: {
    DefaultLayout,
    AccountSideNav
  },
  computed: {
    ...mapGetters({
      isAuthenticated: AUTH_TYPES.getters.isAuthenticated
    })
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';

  .account-page-wrapper {
    flex: 2 2 auto;
    overflow: hidden;
  }

  @media screen and (max-width: 658px) {
    .d-none {
      display: none !important;
    }
  }
</style>
