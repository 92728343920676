import {
  removeNamespace,
  setCreatedAndModified,
  tempReduxCartUpdate
} from '@/helpers/utilities'
import { ShippingMethodTypeEnum } from '@/helpers/enums/shipping-enums'
import { TaxExemptStatusEnum } from '@/helpers/enums/tax-exemption-status-enum'
import { AUTH_TYPES } from '@/store/auth'
import { ORGANIZATIONS_TYPES } from '@/store/organizations'
import { CartItemErrorTypeEnum, CartStateEnum } from '@/helpers/enums/cart-enums'
import * as gaUtility from '@/helpers/ga-utility'

export const CART_TYPES = {
  getters: {
    freeShippingAmount: 'cart/freeShippingAmount',
    valueNeededForFreeShipping: 'cart/valueNeededForFreeShipping',
    freeShipping: 'cart/freeShipping',
    selectedShippingMethod: 'cart/selectedShippingMethod',
    deliveryShippingOption: 'cart/deliveryShippingOption',
    deliveryShippingOptions: 'cart/deliveryShippingOptions',
    pickupShippingOption: 'cart/pickupShippingOption',
    notificationsByPosition: 'cart/notificationsByPosition',
    validatedPromoCodes: 'cart/validatedPromoCodes',
    itemCount: 'cart/itemCount'
  },
  mutations: {
    SET_LOADING: 'cart/SET_LOADING',
    SET_RECOMMENDED_PRODUCTS_LOADING: 'cart/SET_RECOMMENDED_PRODUCTS_LOADING',
    SET_CART: 'cart/SET_CART',
    SET_CART_STATE: 'cart/SET_CART_STATE',
    ADD_ITEM_TO_CART: 'cart/ADD_ITEM_TO_CART',
    UPDATE_ITEM_IN_CART: 'cart/UPDATE_ITEM_IN_CART',
    REMOVE_ITEM_FROM_CART: 'cart/REMOVE_ITEM_FROM_CART',
    ADD_PROMOTION_CODE: 'cart/ADD_PROMOTION_CODE',
    REMOVE_PROMOTION_CODE: 'cart/REMOVE_PROMOTION_CODE',
    ADD_NOTIFICATION: 'cart/ADD_NOTIFICATION',
    REMOVE_NOTIFICATION: 'cart/REMOVE_NOTIFICATION',
    CLEAR_NON_TIMED_NOTIFICATIONS: 'cart/CLEAR_NON_TIMED_NOTIFICATIONS',
    ADD_PRODUCT_TO_SAVED: 'cart/ADD_PRODUCT_TO_SAVED',
    REMOVE_PRODUCT_FROM_SAVED: 'cart/REMOVE_PRODUCT_FROM_SAVED',
    SET_SELECTED_SHIPPING_METHOD_TYPE: 'cart/SET_SELECTED_SHIPPING_METHOD_TYPE',
    SET_SHIPPING_ZIPCODE: 'cart/SET_SHIPPING_ZIPCODE',
    SET_SHIPPING_ADDRESS: 'cart/SET_SHIPPING_ADDRESS',
    SET_RECOMMENDED_PRODUCTS: 'cart/SET_RECOMMENDED_PRODUCTS',
    SET_TAX_EXEMPT_STATUS: 'cart/SET_TAX_EXEMPT_STATUS',
    UPDATE_GIFT_CARD: 'cart/UPDATE_GIFT_CARD',
    SET_IS_OPT_IN_SELECTED: 'cart/SET_IS_OPT_IN_SELECTED'
  },
  actions: {
    pushNotification: 'cart/pushNotification',
    removeNotification: 'cart/removeNotification',
    fetchCart: 'cart/fetchCart',
    setCart: 'cart/setCart',
    addItemToCart: 'cart/addItemToCart',
    addItemsToCart: 'cart/addItemsToCart',
    updateItemsToCart: 'cart/updateItemsToCart',
    updateItemInCart: 'cart/updateItemInCart',
    removeItemFromCart: 'cart/removeItemFromCart',
    removeItemsFromCart: 'cart/removeItemsFromCart',
    addPromotionCode: 'cart/addPromotionCode',
    removePromotionCode: 'cart/removePromotionCode',
    saveCart: 'cart/saveCart',
    addProductToSaved: 'cart/addProductToSaved',
    removeProductFromSaved: 'cart/removeProductFromSaved',
    setSelectedShippingMethodType: 'cart/setSelectedShippingMethodType',
    setShippingZipCode: 'cart/setShippingZipCode',
    setShippingAddress: 'cart/setShippingAddress',
    validateCart: 'cart/validateCart',
    submitCartForOrder: 'cart/submitCartForOrder',
    resetCartState: 'cart/resetCartState',
    getRecommendedProducts: 'cart/getRecommendedProducts',
    clearCart: 'cart/clearCart',
    setTaxExemptStatus: 'cart/setTaxExemptStatus',
    updateGiftCard: 'cart/updateGiftCard',
    setOptInSelected: 'cart/setOptInSelected'
  }
}

const _types = removeNamespace('cart', CART_TYPES)

const fetchCart = ($axios, identifier) => {
  return $axios.get(`store/userdata/${identifier}/cart`)
}

function initialState () {
  return {
    loading: true,
    recommendedProductsLoading: true,
    userDataId: null,
    cartState: CartStateEnum.Draft,
    items: [],
    notifications: [],
    saved: [],
    totalItemCartPrice: 0,
    totalShippingCost: 0,
    estimatedSalesTax: 0,
    cartTotal: 0,
    taxExemptStatus: TaxExemptStatusEnum.NotDetermined,
    promotionApplication: null,
    selectedShippingMethodType: ShippingMethodTypeEnum.Standard,
    shippingOptions: [],
    shippingZipCode: '49546',
    shippingAddress: null,
    isCanadaShipping: false,
    promotionCodes: [],
    errors: [],
    recommendedProducts: [],
    currentPricingInfo: [],
    isOptInSelected: false
  }
}

const state = () => ({
  ...initialState()
})

const getters = {
  [_types.getters.itemCount] (state) {
    if (state.items) {
      return state.items.reduce((a, b) => a + (b.quantityToOrder || 0), 0)
    }
    return 0
  },
  [_types.getters.freeShippingAmount] (state, getters) {
    const shipMethod = getters.selectedShippingMethod
    return shipMethod && shipMethod.freeShippingThreshold
  },
  [_types.getters.valueNeededForFreeShipping] (state, getters) {
    if (state.totalShippingCost > 0 && getters.freeShippingAmount > 0) {
      // exclude gift cards from free shipping front-end logic (note: there's logic in the api to exlude gift card prices from this as well)
      const giftcards = state.items.filter(x => x.product.giftCards?.length > 0)
      const giftcardTotalAmount = giftcards.reduce(function (previousVal, currentElement) { return (previousVal + currentElement.product.basePrice) }, 0)
      const cartAmountWithoutGiftCards = state.totalItemCartPrice - giftcardTotalAmount
      return getters.freeShippingAmount - cartAmountWithoutGiftCards
    } else {
      return null
    }
  },
  [_types.getters.freeShipping] (state, getters) {
    return state.totalShippingCost === 0
  },
  [_types.getters.selectedShippingMethod] (state) {
    return state.shippingOptions.find(x => x.type === state.selectedShippingMethodType)
  },
  [_types.getters.deliveryShippingOption] (state) {
    if (state.selectedShippingMethodType === ShippingMethodTypeEnum.Pickup) {
      return state.shippingOptions.find(x => x.type !== ShippingMethodTypeEnum.Pickup && x.type !== ShippingMethodTypeEnum.Email)
    } else {
      return state.shippingOptions.find(x => x.type === state.selectedShippingMethodType)
    }
  },
  [_types.getters.deliveryShippingOptions] (state) {
    return state.shippingOptions.filter(x => x.type !== ShippingMethodTypeEnum.Pickup && x.type !== ShippingMethodTypeEnum.Email)
  },
  [_types.getters.pickupShippingOption] (state) {
    return state.shippingOptions.find(x => x.type === ShippingMethodTypeEnum.Pickup)
  },
  [_types.getters.notificationsByPosition]: state => (position) => {
    return state.notifications.filter(x => x.position === position)
  },
  [_types.getters.validatedPromoCodes] (state) {
    if (state.promotionCodes.length > 0) {
      return state.promotionCodes.filter(x =>
        (state.promotionApplication))
    }
    return []
  }
}

const mutations = {
  [_types.mutations.SET_LOADING] (state, isLoading) {
    state.loading = isLoading
  },
  [_types.mutations.SET_RECOMMENDED_PRODUCTS_LOADING] (state, areLoading) {
    state.recommendedProductsLoading = areLoading
  },
  [_types.mutations.SET_CART] (state, cart) {
    this.$logger.debug(`Set cart: ${JSON.stringify(cart)}`)
    Object.assign(state, {
      ...cart,
      notifications: state.notifications,
      shippingZipCode: cart.shippingZipCode ? cart.shippingZipCode : state.shippingZipCode
    })
  },
  [_types.mutations.SET_CART_STATE] (state, cartState) {
    state.cartState = cartState
  },
  [_types.mutations.ADD_ITEM_TO_CART] (state, item) {
    const existing = state.items.find(i => i.product.id === item.product.id)
    if (existing && !existing.product?.giftCards?.length) {
      // You can't just update the quantity of gift cards, the recipients contact information must also be saved, better to treat it as a new product
      existing.quantityToOrder += item.quantityToOrder
      state.items = tempReduxCartUpdate(state.items, existing)
      return
    }
    gaUtility.updateCart(item, this.$gtm, 'addToCart')
    state.items.push(item)
  },
  [_types.mutations.UPDATE_GIFT_CARD] (state, items) {
    // previous item is from vuex state, updated item is updated clone
    const { previousItem, updatedItem } = items
    if (!previousItem || !updatedItem) {
      throw new Error('Could not find gift card in cart to update')
    }
    let itemFound = false
    state.items = state.items.map((x) => {
      if (x === previousItem) {
        if (itemFound) {
          throw new Error('gift card already updated, issue with state')
        }
        itemFound = true
        return updatedItem
      } else {
        return x
      }
    })
  },
  [_types.mutations.UPDATE_ITEM_IN_CART] (state, item) {
    const existingItem = state.items.find(i => i.product.id === item.product.id)
    if (existingItem.quantityToOrder > item.quantityToOrder) {
      const newCount = existingItem.quantityToOrder - item.quantityToOrder
      item.quantity = newCount
      gaUtility.updateCart(item, this.$gtm, 'removeFromCart')
    } else if (existingItem.quantityToOrder < item.quantityToOrder) {
      const addCount = item.quantityToOrder - existingItem.quantityToOrder
      item.quantity = addCount
      gaUtility.updateCart(item, this.$gtm, 'addToCart')
    }
    state.items = tempReduxCartUpdate(state.items, item)
  },
  [_types.mutations.REMOVE_ITEM_FROM_CART] (state, item) {
    if (this.$gtm !== undefined) {
      gaUtility.updateCart(item, this.$gtm, 'removeFromCart')
    }
    state.items = state.items.filter(i => i !== item)
  },
  [_types.mutations.ADD_PROMOTION_CODE] (state, promotionCode) {
    state.promotionCodes = [...state.promotionCodes, promotionCode]
  },
  [_types.mutations.REMOVE_PROMOTION_CODE] (state, promotionCode) {
    state.promotionCodes = state.promotionCodes.filter(p => p !== promotionCode)
  },
  [_types.mutations.ADD_NOTIFICATION] (state, notification) {
    state.notifications.push(notification)
  },
  [_types.mutations.REMOVE_NOTIFICATION] (state, notificationId) {
    state.notifications = state.notifications.filter(a => a.id !== notificationId)
  },
  [_types.mutations.CLEAR_NON_TIMED_NOTIFICATIONS] (state) {
    state.notifications = state.notifications.filter(x => !!x.timeout)
  },
  [_types.mutations.ADD_PRODUCT_TO_SAVED] (state, product) {
    state.items = state.items.filter(i => i.product.id !== product.id)
    const existing = state.saved.find(i => i.product.id === product.id)
    if (existing) {
      return
    }
    const item = { product }
    setCreatedAndModified(item, true)
    state.saved.push(item)
  },
  [_types.mutations.REMOVE_PRODUCT_FROM_SAVED] (state, product) {
    state.saved = state.saved.filter(i => i.product.id !== product.id)
  },
  [_types.mutations.SET_SELECTED_SHIPPING_METHOD_TYPE] (state, shippingMethodType) {
    state.selectedShippingMethodType = shippingMethodType
  },
  [_types.mutations.SET_SHIPPING_ZIPCODE] (state, zipcode) {
    state.shippingZipCode = zipcode
  },
  [_types.mutations.SET_SHIPPING_ADDRESS] (state, address) {
    state.shippingAddress = address
  },
  [_types.mutations.SET_RECOMMENDED_PRODUCTS] (state, recommendedProducts) {
    state.recommendedProducts = recommendedProducts
  },
  [_types.mutations.SET_TAX_EXEMPT_STATUS] (state, status) {
    state.taxExemptStatus = status
  },
  [_types.mutations.SET_IS_OPT_IN_SELECTED] (state, isOptInSelected) {
    state.isOptInSelected = isOptInSelected
  }
}

const actions = {
  [_types.actions.pushNotification] ({ state, commit }, payload) {
    if (!payload.position) {
      payload.position = 'bottom'
    }
    commit(_types.mutations.ADD_NOTIFICATION, payload)
    if (payload.timeout) {
      setTimeout(() => {
        commit(_types.mutations.REMOVE_NOTIFICATION, payload.id)
      }, payload.timeout)
    }
  },
  [_types.actions.removeNotification] ({ state, commit }, payload) {
    commit(_types.mutations.REMOVE_NOTIFICATION, payload)
  },
  [_types.actions.fetchCart] ({ commit, rootGetters, dispatch }, payload = null) {
    commit(_types.mutations.SET_LOADING, true)
    const identifier = (payload) ? payload.userDataId : rootGetters[AUTH_TYPES.getters.userDataId]
    this.$logger.debug(`Fetching cart: ${identifier}`)
    return fetchCart(this.$axios, identifier)
      .then((res) => {
        if (res.status === 200 && (!payload || payload.setCart)) {
          dispatch(_types.actions.setCart, res.data)
        }
      })
      .finally(() => commit(_types.mutations.SET_LOADING, false))
  },
  [_types.actions.setCart] ({ commit, dispatch }, payload) {
    // updating cart, clear all previous notifications
    commit(_types.mutations.CLEAR_NON_TIMED_NOTIFICATIONS)

    // add notifications for any cart level errors
    payload.errors.forEach((e) => {
      dispatch(_types.actions.pushNotification, {
        id: e.errorType,
        name: 'Cart Error',
        message: e.message,
        type: 'warn',
        position: 'top'
      })
    })

    // convert unfixable cart errors to notifications
    const errorItems = payload.items.filter(i => i.errors.filter(e => e.errorType === CartItemErrorTypeEnum.ProductDoesNotExist || e.errorType === CartItemErrorTypeEnum.ProductNotAvailable).length > 0)
    errorItems.forEach((item) => {
      const notification = {
        id: item.product.id,
        type: 'warn',
        position: 'bottom'
      }
      if (item.errors.find(x => x.errorType === CartItemErrorTypeEnum.ProductDoesNotExist)) {
        notification.name = `Product not Found`
        notification.message = (item.product.author) ? `${item.product.title} by ${item.product.author}` : item.product.title
      } else {
        notification.name = `Out of Stock`
        notification.link = {
          route: `/products/${item.product.id}`,
          text: (item.product.author) ? `${item.product.title} by ${item.product.author}` : item.product.title
        }
      }
      dispatch(_types.actions.pushNotification, notification)
    })
    // set the cart after unfixable products are removed
    payload.items = payload.items.filter(i => !errorItems.includes(i))
    commit(_types.mutations.SET_CART, payload)
  },
  [_types.actions.addItemToCart] ({ state, commit, dispatch }, payload) {
    if (!payload.quantityToOrder) {
      payload.quantityToOrder = 1
    }
    commit(_types.mutations.ADD_ITEM_TO_CART, payload)
    if (payload.product.productTypeId !== -1) {
      dispatch(_types.actions.getRecommendedProducts, payload.product.id)
    }
    let bookCount = 0

    state.items.forEach((x) => {
      if (!x.product.giftCards.length) {
        bookCount++
      }
    })
    if (payload.product.giftCards.length && bookCount === 0) {
      commit(_types.mutations.SET_SELECTED_SHIPPING_METHOD_TYPE, ShippingMethodTypeEnum.Email)
    } else {
      commit(_types.mutations.SET_SELECTED_SHIPPING_METHOD_TYPE, ShippingMethodTypeEnum.Standard)
    }

    return dispatch(_types.actions.saveCart)
      .then((cart) => {
        return cart.items.find(x => x.product.id === payload.product.id)
      })
  },
  [_types.actions.addItemsToCart] ({ commit, dispatch }, payload) {
    if (!payload || !payload.length) {
      return
    }
    payload.forEach(item => commit(_types.mutations.ADD_ITEM_TO_CART, item))

    dispatch(_types.actions.getRecommendedProducts, payload[0].product.id)
    return dispatch(_types.actions.saveCart)
      .then((cart) => {
        return cart.items
      })
  },
  [_types.actions.updateItemInCart] ({ commit, dispatch }, payload) {
    commit(_types.mutations.UPDATE_ITEM_IN_CART, payload)
    return dispatch(_types.actions.saveCart)
  },
  [_types.actions.updateGiftCard] ({ commit, dispatch }, payload) {
    commit(_types.mutations.UPDATE_GIFT_CARD, payload)
    return dispatch(_types.actions.saveCart)
  },
  [_types.actions.updateItemsToCart] ({ commit, dispatch }, payload) {
    if (!payload || !payload.length) {
      return
    }
    payload.forEach(item => commit(_types.mutations.UPDATE_ITEM_IN_CART, item))
    // commit(_types.mutations.UPDATE_ITEM_IN_CART, payload)
    return dispatch(_types.actions.saveCart)
  },
  [_types.actions.removeItemFromCart] ({ state, commit, dispatch }, payload) {
    if (payload && payload.product && state.items.length > 1 &&
      payload.product.id === state.items[0].product.id) {
      // If the first item was removed, update the recommended products
      if (payload.product.productTypeId !== -1) {
        dispatch(_types.actions.getRecommendedProducts, state.items[1].product.id)
      }
    } else if (state.items.length === 1) {
      commit(_types.mutations.SET_RECOMMENDED_PRODUCTS, [])
    }
    commit(_types.mutations.REMOVE_ITEM_FROM_CART, payload)
    return dispatch(_types.actions.saveCart)
  },
  [_types.actions.removeItemsFromCart] ({ state, commit, dispatch }, payload) {
    if (payload && payload[0].product && state.items.length > 1 &&
      payload[0].product.id === state.items[0].product.id) {
      // If the first item was removed, update the recommended products
      if (payload.product.productTypeId !== -1) {
        dispatch(_types.actions.getRecommendedProducts, state.items[1].product.id)
      }
    } else if (state.items.length === 1) {
      commit(_types.mutations.SET_RECOMMENDED_PRODUCTS, [])
    }
    payload.forEach(item => commit(_types.mutations.REMOVE_ITEM_FROM_CART, item))
    return dispatch(_types.actions.saveCart)
  },
  [_types.actions.addPromotionCode] ({ commit, dispatch }, payload) {
    commit(_types.mutations.ADD_PROMOTION_CODE, payload)
    return dispatch(_types.actions.saveCart)
      .then((cart) => {
        if (cart.promotionCodeError) {
          throw new Error(cart.promotionCodeError)
        }
        return cart.items.find(x => x.promotionCodes === payload)
      })
  },
  [_types.actions.removePromotionCode] ({ commit, dispatch }, payload) {
    commit(_types.mutations.REMOVE_PROMOTION_CODE, payload)
    return dispatch(_types.actions.saveCart)
  },
  [_types.actions.saveCart] ({ state, commit, dispatch, rootState, rootGetters }, payload) {
    commit(_types.mutations.SET_LOADING, true)
    const identifier = rootGetters[AUTH_TYPES.getters.userDataId]
    if (!identifier) {
      commit(_types.mutations.SET_LOADING, false)
      throw { statusCode: 500, message: 'Your cart could not be saved' }
    }
    const organizationId = rootGetters[ORGANIZATIONS_TYPES.getters.browsingAsOrgId]
    const model = {
      userDataId: identifier,
      organizationId,
      cartState: state.cartState,
      items: state.items,
      saved: state.saved,
      totalItemCartPrice: state.totalItemCartPrice,
      totalShippingCost: state.totalShippingCost,
      estimatedSalesTax: state.estimatedSalesTax,
      cartTotal: state.cartTotal,
      selectedShippingMethodType: state.selectedShippingMethodType,
      shippingZipCode: state.shippingZipCode,
      promotionCodes: state.promotionCodes,
      shippingAddress: rootState.checkout.selectedShippingAddress && rootState.checkout.selectedShippingAddress.address,
      taxExemptStatus: state.taxExemptStatus,
      currentPricingInfo: state.currentPricingInfo,
      errors: []
    }

    this.$logger.debug(`Saving cart: ${identifier}`)
    return this.$axios.post(`store/userdata/${identifier}/cart`, { ...model })
      .then((res) => {
        dispatch(_types.actions.setCart, res.data)
        return res.data
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.cart) {
          dispatch(_types.actions.setCart, err.response.data.cart)
        }
        throw err
      })
      .finally(() => commit(_types.mutations.SET_LOADING, false))
  },
  [_types.actions.addProductToSaved] ({ commit, dispatch }, payload) {
    commit(_types.mutations.ADD_PRODUCT_TO_SAVED, payload)
    return dispatch(_types.actions.saveCart)
  },
  [_types.actions.removeProductFromSaved] ({ commit, dispatch }, payload) {
    commit(_types.mutations.REMOVE_PRODUCT_FROM_SAVED, payload)
    return dispatch(_types.actions.saveCart)
  },
  [_types.actions.setSelectedShippingMethodType] ({ commit, dispatch }, payload) {
    commit(_types.mutations.SET_SELECTED_SHIPPING_METHOD_TYPE, payload)
    return dispatch(_types.actions.saveCart)
  },
  [_types.actions.setShippingZipCode] ({ commit, dispatch, state }, payload) {
    commit(_types.mutations.SET_SHIPPING_ZIPCODE, payload)
    if (state.shippingAddress && state.shippingAddress.postalCode && state.shippingAddress.postalCode !== payload) {
      // if newly entered zip doesn't match address, clear the previously entered address
      commit(_types.mutations.SET_SHIPPING_ADDRESS, null)
    }
    return dispatch(_types.actions.saveCart)
  },
  [_types.actions.setShippingAddress] ({ commit, dispatch }, payload) {
    commit(_types.mutations.SET_SHIPPING_ADDRESS, payload)
    return dispatch(_types.actions.saveCart)
  },
  [_types.actions.validateCart] ({ commit, dispatch }) {
    commit(_types.mutations.SET_CART_STATE, CartStateEnum.SubmittedForCheckout)
    return dispatch(_types.actions.saveCart)
  },
  [_types.actions.setOptInSelected] ({ commit }, isSelected) {
    commit(_types.mutations.SET_IS_OPT_IN_SELECTED, isSelected)
  },
  [_types.actions.submitCartForOrder] ({ commit, dispatch, state, rootState, rootGetters }, SubmitCartCount) {
    commit(_types.mutations.SET_CART_STATE, CartStateEnum.SubmittedForOrder)
    commit(_types.mutations.SET_LOADING, true)
    const organizationId = rootGetters[ORGANIZATIONS_TYPES.getters.browsingAsOrgId]

    const model = {
      userDataId: state.userDataId,
      organizationId,
      cartState: state.cartState,
      items: state.items,
      saved: state.saved,
      totalItemCartPrice: state.totalItemCartPrice,
      totalShippingCost: state.totalShippingCost,
      estimatedSalesTax: state.estimatedSalesTax,
      cartTotal: state.cartTotal,
      promotionApplication: state.promotionApplication,
      selectedShippingMethodType: state.selectedShippingMethodType,
      shippingZipCode: state.shippingZipCode,
      promotionCodes: state.promotionCodes,
      guestEmail: rootState.checkout.guestUser && rootState.checkout.guestUser.email,
      primaryPickupPerson: rootState.checkout.primaryPickupPerson,
      secondaryPickupPerson: rootState.checkout.secondaryPickupPerson,
      shippingAddress: rootState.checkout.selectedShippingAddress && rootState.checkout.selectedShippingAddress.address,
      billingAddress: rootState.checkout.selectedBillingAddress && rootState.checkout.selectedBillingAddress.address,
      paymentMethodId: rootState.checkout.selectedPaymentMethod?.id,
      paymentSourceToken: rootState.checkout.selectedPaymentMethod?.paymentSourceToken,
      taxExemptStatus: state.taxExemptStatus,
      currentPricingInfo: state.currentPricingInfo,
      giftCardNumbers: rootState.checkout.giftCardPayments.map(x => x.giftCardNumber),
      submitCartCount: SubmitCartCount,
      isOptInSelected: state.isOptInSelected
    }

    return this.$axios.post(`store/orders`, { ...model })
      .then((res) => {
        const orderStatus = res.data.orderStatus !== 'undefined' ? res.data.orderStatus.name : null
        if (orderStatus !== null && orderStatus === 'Accepted') {
          const orderProducts = gaUtility.buildProductList(res.data.batches)
          // TODO the coupon code should be in the res.data object
          gaUtility.purchase(res.data, orderProducts, this.$gtm)
        }
        return res.data
      })
      .catch((err) => {
        if (err && err.cart) {
          dispatch(_types.actions.setCart, err.cart)
        }
        if (err.isCartException) {
          this.$router.push('/cart')
        }
        throw err
      })
      .finally(() => commit(_types.mutations.SET_LOADING, false))
  },
  [_types.actions.resetCartState] ({ commit }) {
    commit(_types.mutations.SET_CART_STATE, CartStateEnum.Draft)
  },
  [_types.actions.getRecommendedProducts] ({ commit, state }, productId) {
    if (!productId) {
      if (state.items.length) {
        productId = state.items[0].product.id
      } else {
        return
      }
    }
    commit(_types.mutations.SET_LOADING, true)
    commit(_types.mutations.SET_RECOMMENDED_PRODUCTS_LOADING, true)
    this.$axios.get(`store/products/${productId}/recommended?count=5`)
      .then((res) => {
        commit(_types.mutations.SET_RECOMMENDED_PRODUCTS, res.data)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        commit(_types.mutations.SET_RECOMMENDED_PRODUCTS_LOADING, false)
        commit(_types.mutations.SET_LOADING, false)
      })
  },
  [_types.actions.clearCart] ({ dispatch }) {
    dispatch(_types.actions.setCart, { ...initialState(), loading: false, loadingRelatedProducts: false })
  },
  [_types.actions.setTaxExemptStatus] ({ commit, dispatch }, payload) {
    commit(_types.mutations.SET_TAX_EXEMPT_STATUS, payload)
    return dispatch(_types.actions.saveCart)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
