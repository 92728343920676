import * as platform from 'platform'
import * as moment from 'moment'
import { removeNamespace } from '@/helpers/utilities'
import { setCookieWithSpecificExpirationTime, getItem, getCookie } from '@/helpers/local-storage'
import { AUTH_TYPES } from '@/store/auth'

const userData = getItem('user_data', true)
let sessionCookie = getCookie('session_data', true)

export const SESSION_TYPES = {
  mutations: {
    SET_SESSION_DATA: 'session/SET_SESSION_DATA',
    SET_SESSIONS: 'session/SET_SESSIONS',
    REMOVE_SESSION: 'session/REMOVE_SESSION'
  },
  actions: {
    getBrowserSession: 'session/getBrowserSession',
    setSessionData: 'session/setSessionData',
    getSessions: 'session/getSessions',
    updateLastActivity: 'session/updateLastActivity',
    associateUserWithSession: 'session/associateUserWithSession',
    signOutOfSession: 'session/signOutOfSession'
  }
}

const _types = removeNamespace('session', SESSION_TYPES)

const updateSessionData = (commit, data) => {
  // setCookie('session_data', data, 999, true)

  const refreshLifeTime = getCookie('refresh_valid_till')
  if (refreshLifeTime) {
    // this session_cookie should only live as long as the refresh token, since it contains the user's customer id after they log in
    setCookieWithSpecificExpirationTime('session_data', data, refreshLifeTime, true)
  } else {
    const expiration = new Date()
    // sets expiration to one week from now
    // this is a guest cart, so we don't want to keep it around for too long
    expiration.setTime(expiration.getTime() + (7 * 24 * 60 * 60 * 1000))
    setCookieWithSpecificExpirationTime('session_data', data, expiration, true)
  }

  commit(_types.mutations.SET_SESSION_DATA, data)
}

const state = () => ({
  current: null,
  sessions: []
})

const getters = {}

const mutations = {
  [_types.mutations.SET_SESSION_DATA] (state, data) {
    this.$logger.debug(`Setting session data: ${JSON.stringify(data)}`)
    state.current = data
  },
  [_types.mutations.SET_SESSIONS] (state, sessions) {
    state.sessions = sessions
  },
  [_types.mutations.REMOVE_SESSION] (state, session) {
    state.sessions = state.sessions.filter(s => s.id !== session)
  }
}

const actions = {
  async [_types.actions.getBrowserSession] ({ commit, dispatch }) {
    sessionCookie = getCookie('session_data', true)
    if (sessionCookie) {
      await this.$axios.get(`store/sessions/${sessionCookie.id}`)
        .then((res) => {
          if (res.data.willSignOut) {
            sessionCookie.willSignOut = false
            dispatch(_types.actions.setSessionData, sessionCookie)
              .then(() => {
                dispatch(AUTH_TYPES.actions.clearSession, null, { root: true })
              })
            return
          }
          updateSessionData(commit, res.data)
          sessionCookie = getCookie('session_data', true)
        })
    }
    const { name, version, ua, os } = platform
    const sessionData = {
      userAgent: encodeURIComponent(`${name};${version};${ua}`),
      operatingSystem: `${os.family} ${os.version}`,
      userId: (userData) ? userData.id : null,
      active: true,
      lastActivity: moment().format()
    }
    if (sessionCookie) {
      sessionData.id = sessionCookie.id
      sessionData.sessionGuid = sessionCookie.sessionGuid
    }
    return dispatch(_types.actions.setSessionData, sessionData)
  },
  [_types.actions.setSessionData] ({ commit }, payload) {
    return this.$axios.post('store/sessions', { ...payload })
      .then((res) => {
        updateSessionData(commit, res.data)
      })
  },
  [_types.actions.getSessions] ({ commit }) {
    return this.$axios.get('store/sessions')
      .then((res) => {
        commit(_types.mutations.SET_SESSIONS, res.data)
      })
  },
  [_types.actions.updateLastActivity] ({ commit }) {
    if (!sessionCookie) { return }
    return this.$axios.post(`store/sessions/update-last-activity/${sessionCookie.id}`)
      .then((res) => {
        updateSessionData(commit, res.data)
      })
  },
  [_types.actions.associateUserWithSession] ({ commit }, payload) {
    return this.$axios.post(`store/sessions/associate-user-with-session/${payload}`)
      .then((res) => {
        updateSessionData(commit, res.data)
      })
  },
  [_types.actions.signOutOfSession] ({ state, commit, dispatch }, payload) {
    return this.$axios.post(`store/sessions/sign-out-of-session/${payload}`)
      .then(() => {
        commit(_types.mutations.REMOVE_SESSION, payload)
        if (state.data.id === payload) {
          dispatch(AUTH_TYPES.actions.clearSession, null, { root: true })
        }
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
