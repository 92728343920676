<template>
  <div
    class="d-flex align-center base-checkbox"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <v-checkbox
      v-model="input"
      v-bind="$attrs"
      :label="label"
      :off-icon="hover ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'"
      color="primary"
      :ripple="false"
      :class="{ 'hover' : hover, 'large': large }"
      :value="val"
      :multiple="multiple"
      hide-details
      @change="$emit('input', input)"
    >
      <template v-slot:label>
        <slot />
      </template>
    </v-checkbox>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckbox',
  props: {
    label: {
      type: String,
      default: null
    },
    val: {
      type: [String, Object, Number, Boolean],
      default: null
    },
    value: {
      type: [Boolean, Array],
      default: null
    },
    large: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hover: false,
      input: this.$attrs.value || false
    }
  },
  computed: {
    multiple () {
      return Array.isArray(this.value)
    }
  },
  watch: {
    value () {
      this.input = this.value
    }
  },
  created () {
    this.input = this.value
  }
}
</script>
