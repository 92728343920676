<template>
  <div class="base-select">
    <v-select
      :label="_label"
      v-bind="$attrs"
      :class="{ mobile: $breakpoint.is.smAndDown }"
      outlined
      :rules="validationRules"
      @change="$emit('input', $event)"
    >
      <template v-slot:selection="data">
        <slot name="selection" :item="data.item">
          <span :style="{ fontSize: displayFontSize + 'px !important' }">{{
            data.item[$attrs["item-text"] ? $attrs["item-text"] : "text"]
          }}</span>
        </slot>
      </template>
      <template v-slot:item="data">
        <slot name="item" :item="data.item">
          <span
:style="{ fontSize: selectFontSize + 'px !important' }"
>{{ data.item[$attrs["item-text"] ? $attrs["item-text"] : "text"] }}
          </span>
        </slot>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: "BaseSelect",
  props: {
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    uppercase: {
      type: Boolean,
      default: true
    },
    displayFontSize: {
      type: Number,
      default: 15
    },
    selectFontSize: {
      type: Number,
      default: 15
    }
  },
  computed: {
    _label () {
      if (!this.label) {
        return this.label
      }
      const label = this.uppercase ? this.label.toUpperCase() : this.label
      return this.required ? label + "*" : label
    },
    validationRules () {
      const rules = [...this.rules]
      if (this.required) {
        rules.push(value => !!value || "Required")
      }
      return rules
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./scss/variables";

.base-select {
  .mobile {
    font-size: $font-input-size !important;
  }
}
</style>
