import { defaultCheck, reduxUpdate, removeNamespace, extractChapterException } from '@/helpers/utilities'
import { CHECKOUT_TYPES } from '@/store/checkout'

export const ACCOUNT_TYPES = {
  getters: {
    loading: 'account/loading',
    addressBook: 'account/addressBook',
    paymentMethods: 'account/paymentMethods',
    hasPaymentMethods: 'account/hasPaymentMethods',
    hasAddresses: 'account/hasAddresses',
    addressById: 'account/addressById'
  },
  mutations: {
    SET_LOADING_ADDRESS_BOOK: 'account/SET_LOADING_ADDRESS_BOOK',
    SET_ADDRESS_BOOK: 'account/SET_ADDRESS_BOOK',
    SET_ADDRESS_BOOK_LOADED: 'account/SET_ADDRESS_BOOK_LOADED',
    PUSH_ADDRESS_BOOK_ENTRY: 'account/PUSH_ADDRESS_BOOK_ENTRY',
    UPDATE_ADDRESS_BOOK_ENTRY: 'account/UPDATE_ADDRESS_BOOK_ENTRY',
    REMOVE_ADDRESS_BOOK_ENTRY: 'account/REMOVE_ADDRESS_BOOK_ENTRY',
    SET_SELECTED_ADDRESS_BOOK_ENTRY: 'account/SET_SELECTED_ADDRESS_BOOK_ENTRY',
    SET_LOADING_PAYMENT_METHODS: 'account/SET_LOADING_PAYMENT_METHODS',
    SET_PAYMENT_METHODS: 'account/SET_PAYMENT_METHODS',
    SET_PAYMENT_METHODS_LOADED: 'account/SET_PAYMENT_METHODS_LOADED',
    PUSH_NEW_PAYMENT_METHOD: 'account/PUSH_NEW_PAYMENT_METHOD',
    UPDATE_PAYMENT_METHOD: 'account/UPDATE_PAYMENT_METHOD',
    REMOVE_PAYMENT_METHOD: 'account/REMOVE_PAYMENT_METHOD',
    SET_PROCESSING: 'account/SET_PROCESSING',
    PUSH_NEW_GIFT_CARD_PAYMENT_METHOD: 'account/PUSH_NEW_GIFT_CARD_PAYMENT_METHOD',
    REMOVE_ALL_GIFT_CARDS: 'account/REMOVE_ALL_GIFT_CARDS',
    SET_LOADING_GIFT_CARD_PAYMENT_METHODS: 'account/SET_LOADING_GIFT_CARD_PAYMENT_METHODS'
  },
  actions: {
    fetchAddressBook: 'account/fetchAddressBook',
    fetchAddress: 'account/fetchAddress',
    createAddressBookEntry: 'account/createAddressBookEntry',
    updateAddressBookEntry: 'account/updateAddressBookEntry',
    deleteAddressBookEntry: 'account/deleteAddressBookEntry',
    fetchPaymentMethods: 'account/fetchPaymentMethods',
    savePaymentMethod: 'account/savePaymentMethod',
    deletePaymentMethod: 'account/deletePaymentMethod',
    saveGiftCardPaymentMethod: 'account/saveGiftCardPaymentMethod',
    removeAllGiftCards: 'account/removeAllGiftCards',
    fetchGiftCardPaymentMethods: 'account/fetchGiftCardPaymentMethods'
  }
}

const _types = removeNamespace('account', ACCOUNT_TYPES)

const state = () => ({
  loadingAddressBook: false,
  processing: false,
  addressBook: [],
  addressBookLoaded: false,
  selectedAddressBookEntry: null,
  loadingPaymentMethods: false,
  paymentMethods: [],
  giftCardPaymentMethods: [],
  paymentMethodsLoaded: false,
  loadingGiftCardPaymentMethods: false
})

const getters = {
  [_types.getters.loading] (state) {
    return state.loadingAddressBook || state.loadingPaymentMethods
  },
  [_types.getters.addressBook] (state) {
    return [...state.addressBook].sort((a, b) => (a.isDefault === b.isDefault) ? 0 : a.isDefault ? -1 : 1)
  },
  [_types.getters.paymentMethods] (state) {
    return [...state.paymentMethods].sort((a, b) => (a.isDefault === b.isDefault) ? 0 : a.isDefault ? -1 : 1)
  },
  [_types.getters.hasPaymentMethods] (state) {
    return state.paymentMethods.length > 0
  },
  [_types.getters.hasAddresses] (state) {
    return state.addressBook.length > 0
  },
  [_types.getters.addressById]: state => (id) => {
    return state.addressBook.find(x => x.id === id)
  }
}

const mutations = {
  [_types.mutations.SET_LOADING_ADDRESS_BOOK] (state, bool) {
    state.loadingAddressBook = bool
  },
  [_types.mutations.SET_ADDRESS_BOOK] (state, addresses) {
    state.addressBook = addresses
  },
  [_types.mutations.SET_ADDRESS_BOOK_LOADED] (state, bool) {
    state.addressBookLoaded = bool
  },
  [_types.mutations.PUSH_ADDRESS_BOOK_ENTRY] (state, entry) {
    state.addressBook.push(entry)
  },
  [_types.mutations.UPDATE_ADDRESS_BOOK_ENTRY] (state, entry) {
    state.addressBook = reduxUpdate(state.addressBook, entry)
  },
  [_types.mutations.REMOVE_ADDRESS_BOOK_ENTRY] (state, entry) {
    state.addressBook = state.addressBook.filter(e => e.id !== entry.id)
  },
  [_types.mutations.SET_SELECTED_ADDRESS_BOOK_ENTRY] (state, entry) {
    state.selectedAddressBookEntry = entry
  },
  [_types.mutations.SET_LOADING_PAYMENT_METHODS] (state, bool) {
    state.loadingPaymentMethods = bool
  },
  [_types.mutations.SET_PAYMENT_METHODS] (state, methods) {
    state.paymentMethods = methods
  },
  [_types.mutations.SET_PAYMENT_METHODS_LOADED] (state, bool) {
    state.paymentMethodsLoaded = bool
  },
  [_types.mutations.PUSH_NEW_PAYMENT_METHOD] (state, method) {
    state.paymentMethods.push(method)
  },
  [_types.mutations.UPDATE_PAYMENT_METHOD] (state, method) {
    state.paymentMethods = reduxUpdate(state.paymentMethods, method)
  },
  [_types.mutations.REMOVE_PAYMENT_METHOD] (state, method) {
    state.paymentMethods = state.paymentMethods.filter(m => m.id !== method.id)
  },
  [_types.mutations.SET_PROCESSING] (state, processing) {
    state.processing = processing
  },
  [_types.mutations.PUSH_NEW_GIFT_CARD_PAYMENT_METHOD] (state, card) {
    const payments = [...state.giftCardPaymentMethods]
    payments.push(card)
    state.giftCardPaymentMethods = payments
  },
  [_types.mutations.REMOVE_ALL_GIFT_CARDS] (state) {
    state.giftCardPaymentMethods = []
  },
  [_types.mutations.SET_LOADING_GIFT_CARD_PAYMENT_METHODS] (state, isLoading) {
    state.loadingGiftCardPaymentMethods = isLoading
  }
}

const actions = {
  [_types.actions.fetchAddressBook] ({ state, commit, getters }, force = false) {
    if (state.addressBookLoaded && !force) {
      return new Promise(resolve => resolve(getters.addressBook))
    }
    commit(_types.mutations.SET_LOADING_ADDRESS_BOOK, true)
    return this.$axios.get('store/user-addresses')
      .then((res) => {
        commit(_types.mutations.SET_ADDRESS_BOOK, res.data)
        commit(_types.mutations.SET_ADDRESS_BOOK_LOADED, true)
        return getters.addressBook
      })
      .finally(() => commit(_types.mutations.SET_LOADING_ADDRESS_BOOK, false))
  },
  [_types.actions.fetchAddress] ({ state, getters, dispatch, commit }, payload) {
    if (state.addressBookLoaded) {
      const address = getters.addressById(payload)
      commit(_types.mutations.SET_SELECTED_ADDRESS_BOOK_ENTRY, address)
      return new Promise(resolve => resolve(address))
    }
    dispatch(_types.actions.fetchAddressBook)
      .then(() => {
        const address = getters.addressById(payload)
        commit(_types.mutations.SET_SELECTED_ADDRESS_BOOK_ENTRY, address)
        return address
      })
  },
  [_types.actions.createAddressBookEntry] ({ state, commit }, payload) {
    commit(_types.mutations.SET_PROCESSING, true)
    return this.$axios.post('store/user-addresses', { ...payload })
      .then((res) => {
        defaultCheck(state.addressBook, res.data, commit, _types.mutations.UPDATE_ADDRESS_BOOK_ENTRY)
        commit(_types.mutations.PUSH_ADDRESS_BOOK_ENTRY, res.data)
        return res.data
      })
      .finally(() => commit(_types.mutations.SET_PROCESSING, false))
  },
  [_types.actions.updateAddressBookEntry] ({ state, commit }, payload) {
    commit(_types.mutations.SET_PROCESSING, true)
    return this.$axios.put(`store/user-addresses/${payload.id}`, { ...payload })
      .then((res) => {
        defaultCheck(state.addressBook, res.data, commit, _types.mutations.UPDATE_ADDRESS_BOOK_ENTRY)
        commit(_types.mutations.UPDATE_ADDRESS_BOOK_ENTRY, res.data)
        return res.data
      })
      .finally(() => commit(_types.mutations.SET_PROCESSING, false))
  },
  [_types.actions.deleteAddressBookEntry] ({ commit }, payload) {
    commit(_types.mutations.SET_LOADING_ADDRESS_BOOK, true)
    return this.$axios.delete(`store/user-addresses/${payload.id}`)
      .then(() => {
        commit(_types.mutations.REMOVE_ADDRESS_BOOK_ENTRY, payload)
      })
      .finally(() => commit(_types.mutations.SET_LOADING_ADDRESS_BOOK, false))
  },
  [_types.actions.fetchPaymentMethods] ({ commit, state, rootState, getters, dispatch }, force = false) {
    if (state.paymentMethodsLoaded && !force) {
      return new Promise(resolve => resolve(getters.paymentMethods))
    }
    commit(_types.mutations.SET_LOADING_PAYMENT_METHODS, true)
    return this.$axios.get('store/payment-methods')
      .then((res) => {
        commit(_types.mutations.SET_PAYMENT_METHODS, res.data)
        // set default payment method for checkout
        if (!rootState.checkout.selectedPaymentMethod && res.data.length > 0) {
          dispatch(CHECKOUT_TYPES.actions.setSelectedPaymentMethod, res.data[0], { root: true })
        }
        commit(_types.mutations.SET_PAYMENT_METHODS_LOADED, true)
        return getters.paymentMethods
      })
      .finally(() => commit(_types.mutations.SET_LOADING_PAYMENT_METHODS, false))
  },
  [_types.actions.savePaymentMethod] ({ commit, state }, payload) {
    commit(_types.mutations.SET_LOADING_PAYMENT_METHODS, true)
    return this.$axios.post('store/payment-methods', { ...payload })
      .then((res) => {
        defaultCheck(state.paymentMethods, res.data, commit, _types.mutations.UPDATE_PAYMENT_METHOD)
        commit(_types.mutations.PUSH_NEW_PAYMENT_METHOD, res.data)
        return res.data
      })
      .catch((err) => {
        const exception = extractChapterException(err)
        if (exception) {
          throw exception
        }
        throw err
      })
      .finally(() => commit(_types.mutations.SET_LOADING_PAYMENT_METHODS, false))
  },

  [_types.actions.deletePaymentMethod] ({ commit }, payload) {
    commit(_types.mutations.SET_LOADING_PAYMENT_METHODS, true)
    return this.$axios.delete(`store/payment-methods/${payload.id}`)
      .then(() => {
        commit(_types.mutations.REMOVE_PAYMENT_METHOD, payload)
      })
      .finally(() => commit(_types.mutations.SET_LOADING_PAYMENT_METHODS, false))
  },
  [_types.actions.saveGiftCardPaymentMethod] ({ commit }, payload) {
    commit(_types.mutations.PUSH_NEW_GIFT_CARD_PAYMENT_METHOD, payload)
  },
  [_types.actions.removeAllGiftCards] ({ commit }) {
    commit(_types.mutations.REMOVE_ALL_GIFT_CARDS)
  },

  [_types.actions.fetchGiftCardPaymentMethods] ({ commit, rootState }) {
    if (!rootState.auth.authenticatedUser) {
      commit(_types.mutations.REMOVE_ALL_GIFT_CARDS)
      return
    }
    commit(_types.mutations.SET_LOADING_GIFT_CARD_PAYMENT_METHODS, true)

    return this.$axios.get('store/GiftCard/getGiftCardPayment')
      .then((res) => {
        const cards = res.data || []
        if (cards.length === undefined) {
          throw new Error('data received is not array of gift cards')
        }
        cards.forEach((card) => {
          if (card.giftCard.amount > 0 && card.giftCard.isActive === true) {
            commit(_types.mutations.PUSH_NEW_GIFT_CARD_PAYMENT_METHOD, card.giftCard)
          }
        })
      })
      .finally(() => commit(_types.mutations.SET_LOADING_GIFT_CARD_PAYMENT_METHODS, false))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
