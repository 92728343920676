import Prismic from 'prismic-javascript'
import PrismicConfig from '~/prismic.config.js'

let client = null

const setAPI = async (req) => {
  if (!client) {
    client = await Prismic.getApi(PrismicConfig.endpoint, { accessToken: PrismicConfig.access_token, req })
  }
}

const clearAPI = () => {
  client = null
}

const getByType = async (type, req) => {
  try {
    await setAPI(req)
    const result = await client.query(Prismic.Predicates.at('document.type', type), {})

    return (result)
      ? {
        documents: result.results
      }
      : null
  } catch (e) {
    console.error(e)
  }
}

const getByCategory = async (categoryId, req) => {
  try {
    await setAPI(req)
    const result = await client.query([
      Prismic.Predicates.at('document.type', 'product_list_marketing_content'),
      Prismic.Predicates.at('my.product_list_marketing_content.category', categoryId)
    ], {})

    return (result)
      ? {
        documents: result.results
      }
      : null
  } catch (e) {
    console.error(e)
  }
}

const getSingle = async (type, req) => {
  try {
    await setAPI(req)
    const result = await client.getSingle(type)

    return (result)
      ? {
        document: result.data,
        documentId: result.id
      }
      : null
  } catch (e) {
    console.error(e)
  }
}

const getByUID = async (type, uid, req) => {
  try {
    await setAPI(req)
    const result = await client.getByUID(type, uid)

    return (result)
      ? {
        document: result.data,
        documentId: result.id
      }
      : null
  } catch (e) {
    console.error(e)
  }
}

export default {
  clearAPI,
  getByType,
  getByCategory,
  getSingle,
  getByUID
}
