export const STORAGE_KEY = 'BBH_CLIENT'

export const getItem = (key, parse = false) => {
  if (typeof localStorage !== 'undefined') {
    const item = localStorage.getItem(STORAGE_KEY + '.' + key)
    return (item) ? (parse) ? JSON.parse(item) : item : null
  }
}

export const storeItem = (key, item, stringify = false) => {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem(STORAGE_KEY + '.' + key, (stringify) ? JSON.stringify(item) : item)
  }
}

export const clearItem = (key) => {
  if (typeof localStorage !== 'undefined') {
    if (!getItem(key)) { return }
    localStorage.removeItem(STORAGE_KEY + '.' + key)
  }
}

//* session storage */
export const getSessionItem = (key, parse = false) => {
  if (typeof sessionStorage !== 'undefined') {
    const item = sessionStorage.getItem(STORAGE_KEY + '.' + key)
    return (item) ? (parse) ? JSON.parse(item) : item : null
  }
}

export const storeSessionItem = (key, item, stringify = false) => {
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.setItem(STORAGE_KEY + '.' + key, (stringify) ? JSON.stringify(item) : item)
  }
}

export const clearSessionItem = (key) => {
  if (typeof sessionStorage !== 'undefined') {
    if (!getSessionItem(key)) { return }
    sessionStorage.removeItem(STORAGE_KEY + '.' + key)
  }
}

export const clearAllSessionItems = () => {
  if (typeof sessionStorage !== 'undefined') {
    sessionStorage.clear()
  }
}
/* end session storage */

export const setCookieWithSpecificExpirationTime = (name, value, epochTime, stringify = false) => {
  if (typeof document !== 'undefined') {
    let expires = ''
    if (epochTime) {
      const date = new Date()
      date.setTime(epochTime)
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = STORAGE_KEY + '.' + name + '=' + ((stringify) ? JSON.stringify(value) : value || '') + expires + '; path=/'
  }
}

export const setCookie = (name, value, days, stringify = false) => {
  if (typeof document !== 'undefined') {
    let expires = ''
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie = STORAGE_KEY + '.' + name + '=' + ((stringify) ? JSON.stringify(value) : value || '') + expires + '; path=/'
  }
}

export const getCookie = (name, parse = false) => {
  if (typeof document !== 'undefined') {
    const nameEQ = STORAGE_KEY + '.' + name + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length)
      }
      if (c.indexOf(nameEQ) === 0) {
        const val = c.substring(nameEQ.length, c.length)
        return (parse) ? JSON.parse(val) : val
      }
    }
  }
  return null
}

export const clearCookie = (name) => {
  if (typeof document !== 'undefined') {
    document.cookie = STORAGE_KEY + '.' + name + '=; Max-Age=-99999999;'
  }
}
