// eslint-disable-next-line no-extend-native
String.prototype.convertCamelCaseToSentenceCase = function () {
  let output = ''
  const len = this.length
  let char

  for (let i = 0; i < len; i++) {
    char = this.charAt(i)

    if (i === 0) {
      output += char.toUpperCase()
    } else if (char !== char.toLowerCase() && char === char.toUpperCase()) {
      output += ' ' + char
    } else if (char === '-' || char === '_') {
      output += ' '
    } else {
      output += char
    }
  }

  return output
}

// eslint-disable-next-line no-extend-native
String.prototype.convertToWordCase = function () {
  return this.split(' ')
    .map(w => (w.charAt(0).toUpperCase() + w.substr(1, w.length).toLowerCase()))
    .join(' ')
}

// eslint-disable-next-line no-extend-native
String.prototype.convertToSentenceCase = function () {
  return this.charAt(0).toUpperCase() + this.substr(1, this.length).toLowerCase()
}
