import { removeNamespace } from '@/helpers/utilities'
import prismicAPI from '@/helpers/prismic-api'
import { SearchTypeEnum } from '@/helpers/enums/product-enums'
import * as gaUtility from '@/helpers/ga-utility'

export const PRODUCTS_TYPES = {
  mutations: {
    SET_SEARCH: 'products/SET_SEARCH',
    SET_SEARCH_TYPE: 'products/SET_SEARCH_TYPE',
    SET_SORT_BY: 'products/SET_SORT_BY',
    SET_PAGE_NUMBER: 'products/SET_PAGE_NUMBER',
    SET_FILTERS: 'products/SET_FILTERS',
    SET_FILTER_TYPES: 'products/SET_FILTER_TYPES',
    SET_GRID_VIEW: 'products/SET_GRID_VIEW',
    SET_LOADING: 'products/SET_LOADING',
    SET_PRODUCTS: 'products/SET_PRODUCTS',
    SET_TOTAL: 'products/SET_TOTAL',
    SET_CATEGORIES: 'products/SET_CATEGORIES',
    SET_LOADING_CATEGORIES: 'products/SET_LOADING_CATEGORIES',
    SET_MARKETING_CONTENT: 'products/SET_MARKETING_CONTENT',
    SET_LOADING_MARKETING_CONTENT: 'products/SET_LOADING_MARKETING_CONTENT',
    RESET: 'products/RESET'
  },
  actions: {
    changeSearch: 'products/changeSearch',
    changeSearchType: 'products/changeSearchTypes',
    changeFilters: 'products/changeFilters',
    changeSortBy: 'products/changeSortBy',
    fetchProducts: 'products/fetchProducts',
    loadMore: 'products/loadMore',
    loadCategories: 'products/loadCategories',
    clearMarketingContent: 'products/clearMarketingContent',
    loadMarketingContent: 'products/loadMarketingContent',
    reset: 'products/reset',
    updateUrl: 'products/updateUrl'
  }
}

const _types = removeNamespace('products', PRODUCTS_TYPES)
const sortOptions = [
  { text: 'Featured', value: null },
  { text: 'Most Popular', value: 'rating desc' },
  { text: 'Release Date', value: 'releaseDate desc' },
  { text: 'Price', value: 'sortPrice' }
]

const defaultState = () => ({
  search: null,
  searchType: SearchTypeEnum.Products,
  sortBy: sortOptions[0],
  sortOptions,
  filters: [],
  filterTypes: [],
  gridView: true,
  loading: false,
  products: [],
  total: 0,
  pageNumber: 0,
  categories: null,
  loadingCategories: false,
  marketingContentSlices: null,
  loadingMarketingContent: false
})

const getters = {}

const mutations = {
  [_types.mutations.SET_SEARCH] (state, search) {
    state.search = search
  },
  [_types.mutations.SET_SEARCH_TYPE] (state, type) {
    state.searchType = type
  },
  [_types.mutations.SET_SORT_BY] (state, sort) {
    state.sortBy = sort
  },
  [_types.mutations.SET_PAGE_NUMBER] (state, pageNumber) {
    state.pageNumber = pageNumber
  },
  [_types.mutations.SET_FILTERS] (state, filters) {
    state.filters = filters
  },
  [_types.mutations.SET_FILTER_TYPES] (state, filterTypes) {
    state.filterTypes = filterTypes
  },
  [_types.mutations.SET_GRID_VIEW] (state, gridView) {
    state.gridView = gridView
  },
  [_types.mutations.SET_PRODUCTS] (state, products) {
    state.products = products
  },
  [_types.mutations.SET_LOADING] (state, loading) {
    state.loading = loading
  },
  [_types.mutations.SET_TOTAL] (state, total) {
    state.total = total
  },
  [_types.mutations.SET_CATEGORIES] (state, categories) {
    state.categories = categories
  },
  [_types.mutations.SET_LOADING_CATEGORIES] (state, loading) {
    state.loadingCategories = loading
  },
  [_types.mutations.SET_MARKETING_CONTENT] (state, content) {
    state.marketingContentSlices = content
  },
  [_types.mutations.SET_LOADING_MARKETING_CONTENT] (state, content) {
    state.marketingContentSlices = content
  },
  [_types.mutations.RESET] (state) {
    // keep categories, reset everything else
    const { categories, ...defaultWithoutCategories } = defaultState()
    Object.assign(state, defaultWithoutCategories)
  }
}

const actions = {
  [_types.actions.changeSearch] ({ commit, dispatch, state }, search) {
    const clearedSearch = !search && !!state.search
    commit(_types.mutations.SET_SEARCH, search)
    commit(_types.mutations.SET_PAGE_NUMBER, 0)
    commit(_types.mutations.SET_PRODUCTS, [])
    commit(_types.mutations.SET_TOTAL, 0)
    commit(_types.mutations.SET_SORT_BY, sortOptions[0])
    commit(_types.mutations.SET_FILTER_TYPES, [])
    commit(_types.mutations.SET_FILTERS, state.searchType === SearchTypeEnum.Bibles ? [{ filterType: 'category', value: 'Bibles' }] : [])
    commit(_types.mutations.SET_LOADING, false)
    commit(_types.mutations.SET_MARKETING_CONTENT, null)
    if (!search && !clearedSearch) {
      return
    }
    gaUtility.search(search, this.$gtm)
    this.$router.push('/products')
    dispatch(_types.actions.fetchProducts)
  },
  [_types.actions.changeSearchType] ({ state, commit, dispatch }, searchType) {
    commit(_types.mutations.SET_SEARCH_TYPE, searchType)
    if (!state.search) {
      return
    }
    commit(_types.mutations.SET_FILTERS, state.searchType === SearchTypeEnum.Bibles ? [{ filterType: 'category', value: 'Bibles' }] : [])
    this.$router.push('/products')
    dispatch(_types.actions.fetchProducts)
  },
  [_types.actions.changeFilters] ({ commit, dispatch }, filters) {
    commit(_types.mutations.SET_FILTERS, filters)
    dispatch(_types.actions.fetchProducts)
  },
  [_types.actions.changeSortBy] ({ commit, dispatch }, { sortBy, loadData }) {
    commit(_types.mutations.SET_SORT_BY, sortBy)
    if (loadData) {
      dispatch(_types.actions.fetchProducts)
    }
  },
  async [_types.actions.fetchProducts] ({ commit, state }) {
    commit(_types.mutations.SET_LOADING, true)
    await this.$axios.post('store/products/search', {
      pageNumber: state.pageNumber,
      search: state.search,
      sortBy: state.sortBy.value,
      filters: state.filters,
      searchType: state.searchType
    }).then(async (res) => {
      if (!res || !res.data) {
        return
      }
      if (res.data.total === 1) {
        await this.$router.replace('/products/' + res.data.results[0].id)
        return
      }
      commit(_types.mutations.SET_PRODUCTS, res.data.results)
      commit(_types.mutations.SET_FILTER_TYPES, res.data.filterTypes)
      commit(_types.mutations.SET_TOTAL, res.data.total)
    }).finally(() => { commit(_types.mutations.SET_LOADING, false) })
  },
  async [_types.actions.loadMore] ({ commit, state }) {
    commit(_types.mutations.SET_LOADING, true)
    commit(_types.mutations.SET_PAGE_NUMBER, state.pageNumber + 1)
    await this.$axios.post('store/products/search', {
      pageNumber: state.pageNumber,
      search: state.search,
      sortBy: state.sortBy.value,
      filters: state.filters,
      searchType: state.searchType
    })
      .then((res) => {
        commit(_types.mutations.SET_PRODUCTS, [...state.products, ...res.data.results])
        commit(_types.mutations.SET_TOTAL, res.data.total)
      })
      .finally(() => {
        commit(_types.mutations.SET_LOADING, false)
      })
  },
  async [_types.actions.loadCategories] ({ commit, state }, { req, error }) {
    try {
      if (state.categories || state.loadingCategories) {
        return
      }
      commit(_types.mutations.SET_LOADING_CATEGORIES, true)
      const result = await prismicAPI.getByType('category', req)
      commit(_types.mutations.SET_LOADING_CATEGORIES, false)
      if (!result) {
        return
      }
      /* Sort the results by the Sort Order field on each category */
      const categories = result.documents.sort((a, b) => {
        if (!a.data || !b.data) {
          return 0
        }
        return a.data.sort_order - b.data.sort_order
      })
      commit(_types.mutations.SET_CATEGORIES, [...categories])
    } catch (e) {
      error(e)
    }
  },
  [_types.actions.clearMarketingContent] ({ commit }) {
    commit(_types.mutations.SET_MARKETING_CONTENT, null)
  },
  async [_types.actions.loadMarketingContent] ({ commit, state }, categoryName) {
    if (
      state.marketingContentSlices ||
      state.loadingMarketingContent ||
      !state.categories
    ) {
      return
    }
    const splitCategoryName = categoryName.split('|')
    const mostSpecificCategory = splitCategoryName[splitCategoryName.length - 1]
    let category = state.categories.find(x => mostSpecificCategory === x.data.name) // try to match exactly first because 'used books' will match 'books' on an includes find
    if (!category || !category.id) {
      category = state.categories.find(x => mostSpecificCategory.includes(x.data.name))
    }
    if (!category || !category.id) {
      return
    }
    commit(_types.mutations.SET_LOADING_MARKETING_CONTENT, true)
    const result = await prismicAPI.getByCategory(category.id)
    commit(_types.mutations.SET_LOADING_MARKETING_CONTENT, false)
    if (
      !result ||
      !result.documents ||
      !result.documents.length ||
      !result.documents[0].data ||
      !result.documents[0].data.body
    ) {
      return
    }
    commit(
      _types.mutations.SET_MARKETING_CONTENT,
      result.documents[0].data.body
    )
  },
  [_types.actions.reset] ({ commit }) {
    commit(_types.mutations.RESET)
  },
  [_types.actions.updateUrl] ({ state }, filters) {
    const filterQuery = getFilterUrl(filters, state.searchType)
    const newUrl = `${this.$router.currentRoute.path}?${filterQuery}`
    if (this.$router.currentRoute.fullPath !== newUrl) {
      this.$router.replace(newUrl)
    }
  }
}

export default {
  state: defaultState,
  getters,
  mutations,
  actions
}

/* Helper functions for building url params from filters */
const getMostSpecificCategory = (filters) => {
  // ex. Given the filters 'Books', 'Books|Fiction' this will return 'Books|Fiction'
  const categories = filters.filter(x => x.filterType === 'category')
  if (!categories || !categories.length) {
    return null
  }
  const splitValuesByLength = categories.map(x => x.value.split('|')).sort((a, b) => {
    return a.length - b.length
  })
  const mostSpecific = splitValuesByLength[splitValuesByLength.length - 1]
  return mostSpecific.join('|')
}
const sortFilters = (filters) => {
  filters.sort((a, b) => {
    // First sort alphabetically by type, then by value
    if (a.filterType > b.filterType) {
      return 1
    }
    if (a.filterType < b.filterType) {
      return -1
    }
    if (a.filterValue > b.filterValue) {
      return 1
    }
    if (a.filterValue < b.filterValue) {
      return -1
    }
    return 0
  })
}
const getFilterUrl = (filters, searchType) => {
  const routeFilters = [ ...filters.filter(x => x.filterType !== 'category') ] // Copy all filters except category filters
  const category = getMostSpecificCategory(filters)
  if (category) {
    routeFilters.push({ filterType: 'category', value: category }) // Add only the most specific category
  }
  if (searchType === SearchTypeEnum.Bibles) {
    routeFilters.push({ filterType: 'type', value: 'Bibles' }) // Add search type if we're searching Bibles
  }
  sortFilters(routeFilters) // Sort to make sure filters are always added to the url in the same order for SEO
  return routeFilters.map((filter) => { // Turn all the filters into one big filter string
    return getFilterUrlParam(filter)
  }).join('&')
}
const getFilterUrlParam = (filter) => {
  let result = `${encodeURIComponent(filter.filterType)}=${encodeURIComponent(filter.value)}`
  if (filter.from) { // Add range values if applicable: ex. ages=3-5 years_f3_t5
    result += `_f${filter.from}`
  }
  if (filter.to) {
    result += `_t${filter.to}`
  }
  return result
}
