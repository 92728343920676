<template>
  <v-dialog v-model="dialog" max-width="500" @click:outside="emitCloseDialog()">
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <slot />
      </div>
    </template>
    <v-card class="confirm-dialog-card-wrapper">
      <v-card-title class="bpg-card-title-block">
        {{ title }}
        <v-icon class="ml-auto close-button" @click="emitCloseDialog()">
          mdi-close
        </v-icon>
      </v-card-title>
      <div class="bpg-card-title-block-content pa-4 pa-md-8">
        <div class="flex-column">
          <div class="fx-1 mb-5">
            <!-- <p class="font-default-size">
              {{ message }}
            </p> -->
            <div class="font-default-size" v-html="message" />
          </div>
          <div class="d-flex flex-column flex-sm-row fx-1">
            <base-button
              :text="cancelText"
              background-color="light-grey"
              class="flex-sm-grow-0 mobile"
              @click="emitCloseDialog()"
            />
            <base-button
              :text="confirmText"
              class="ml-0 ml-sm-2 mt-3 mt-sm-0 flex-sm-grow-0 mobile"
              :loading="loading"
              @click="emitCallback()"
            />
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConditionalConfirmDialog',
  props: {
    title: {
      type: String,
      default: 'Remove Item'
    },
    message: {
      type: String,
      default: 'Are you sure you want to complete this action? It cannot be undone.'
    },
    loading: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    },
    confirmText: {
      type: String,
      default: 'Confirm Remove'
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitCallback () {
      this.$emit('dialogCallback')
    },
    emitCloseDialog () {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
