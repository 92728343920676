import { getCookie } from '@/helpers/local-storage'

export default function () {
  // Try token refresh lifetime
  // const refreshLifetime = getItem('refresh_valid_till')
  // now that this is being saved as a cookie with a 1 hour expiration, we dont need to worry about legacy refresh_valid_till value in local storage, those will never be accessed again
  const refreshLifetime = getCookie('refresh_valid_till')
  if (refreshLifetime) {
    return Date.now() < refreshLifetime
  }
  // user can no longer be authenticated
  return false
}
