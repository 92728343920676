<template>
  <div class="main-nav">
    <div class="app-header" :class="{ sticking: sticking }">
      <div class="content-center">
        <div class="content-constraint" :class="{ 'align-center': events }">
          <div
            class="menu-and-logo-and-cart-and-account"
            :class="{
              'focus-in-search': searchHasFocus,
              'align-center mt-0': events,
            }"
          >
            <div class="menu-and-logo mr-4 mr-sm-0">
              <div v-a11y-clickable class="mdi mdi-menu clickable" @click="toggleMobileNav()" />
              <div>
                <nuxt-link to="/" class="logo-container" exact aria-label="home">
                  <div class="logo" />
                </nuxt-link>
                <div class="catch-hover-container" />
              </div>
            </div>
            <div class="fx-1 search-and-cart-and-categories">
              <div class="d-flex align-content-center fx-1">
                <div v-if="!events" class="search fx-1 pr-md-8 pl-sm-8">
                  <the-search-bar
                    :sticking="sticking"
                    @focus="searchHasFocus = $event"
                  />
                </div>
                <div v-show="events">
                  <div class="d-flex mt-md-4">
                    <a
                      href="/events"
                      class="link font-medium pr-3 pr-md-5 pr-lg-7"
                      :class="{
                        'accent--text':
                          $router.currentRoute.fullPath === '/events',
                      }"
                    >
                      <span
                        class="clickable text-no-underline"
                      >Upcoming Events</span>
                    </a>
                    <a
                      href="/events?status=ended"
                      class="link font-medium pr-3 pr-md-5 pr-lg-7"
                      :class="{
                        'accent--text':
                          $router.currentRoute.fullPath ===
                          '/events?status=ended',
                      }"
                    >
                      <span
                        class="clickable text-no-underline"
                      >Past Events</span>
                    </a>
                  </div>
                </div>
                <div
                  class="cart-and-account mr-0 mr-sm-2"
                  :class="{ 'ml-auto': events }"
                >
                  <div id="account-dropdown-anchor" class="account">
                    <v-menu
                      content-class="account-dropdown-menu"
                      attach="#account-dropdown-anchor"
                      offset-y
                      left
                    >
                      <template v-slot:activator="{ on }">
                        <div v-a11y-clickable class="d-flex align-end clickable" v-on="on">
                          <v-icon class="mdi primary--text pb-1">
                            mdi-account
                          </v-icon>
                          <button aria-label="account" aria-haspopup="true">
                            <div>
                              <div class="welcome secondary--text">
                                {{ accountGreeting }}
                              </div>
                              <div class="my-account secondary--text">
                                My Account
                              </div>
                            </div>
                          </button>
                        </div>
                      </template>
                      <h1 class="text-center">
                        Account
                      </h1>
                      <client-only>
                        <v-list>
                          <div v-if="!authenticatedUser">
                            <v-list-item
                              class="account-list-item"
                              @click="signIn"
                            >
                              <span class="primary--text">Sign In</span>
                            </v-list-item>
                            <v-list-item
                              class="account-list-item"
                              @click="signUp"
                            >
                              <span class="primary--text">Create Account</span>
                            </v-list-item>
                          </div>
                          <div v-else>
                            <nuxt-link
                              to="/account"
                              tag="v-list-item"
                              class="account-list-item"
                            >
                              <span
                                class="primary--text"
                              >Account Settings</span>
                            </nuxt-link>
                            <nuxt-link
                              to="/account/orders"
                              tag="v-list-item"
                              class="account-list-item"
                            >
                              <span class="primary--text">Order History</span>
                            </nuxt-link>
                            <nuxt-link
                              to="/wishlist"
                              tag="v-list-item"
                              class="account-list-item"
                            >
                              <span class="primary--text">My Wishlist</span>
                            </nuxt-link>
                            <v-list-item
                              class="account-list-item"
                              @click="signOut"
                            >
                              <span class="primary--text">Sign Out</span>
                            </v-list-item>
                            <div
                              v-if="
                                browsingAsOptions &&
                                browsingAsOptions.length > 1
                              "
                            >
                              <v-subheader class="browse-as-subheader">
                                <span
                                  class="browse-as-label"
                                >Browse Baker as:</span>
                              </v-subheader>
                              <v-list-item-group
                                :value="browsingAs"
                                active-class="active-v-list-item"
                                @change="setBrowsingAs($event)"
                              >
                                <v-list-item
                                  v-for="opt in browsingAsOptions"
                                  :key="opt.id"
                                  :value="opt"
                                  class="account-list-item browse-as-option"
                                >
                                  <v-list-item-icon>
                                    <v-icon
                                      v-if="
                                        browsingAs && opt.id === browsingAs.id
                                      "
                                      color="#352d2d"
                                    >
                                      mdi-check
                                    </v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <span class="primary--text">{{
                                        opt.id === "Self"
                                          ? authenticatedUser.userProfile
                                              .fullName
                                          : opt.name
                                      }}</span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      <span>{{
                                        opt.id === "Self"
                                          ? "Personal"
                                          : "Organization"
                                      }}</span>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </div>
                          </div>
                        </v-list>
                      </client-only>
                    </v-menu>
                  </div>
                  <button
                    v-if="!events"
                    id="cart-dropdown-anchor"
                    aria-label="cart"
                    class="cart"
                    aria-haspopup="true"
                    @click="showCartMenu = true"
                  >
                    <client-only>
                      <nuxt-link
                        v-a11y-clickable.link
                        to="/cart"
                        tag="div"
                        exact
                        class="clickable d-sm-none"
                      >
                        <v-badge
                          :value="itemCount > 0"
                          color="gray"
                          class="v-badge-default"
                          :class="{ 'mt-sm-2': !sticking }"
                        >
                          <template v-slot:badge>
                            {{ itemCount }}
                          </template>
                          <v-icon class="mdi primary--text mr-0">
                            mdi-cart
                          </v-icon>
                        </v-badge>
                      </nuxt-link>
                    </client-only>

                    <v-menu
                      v-model="showCartMenu"
                      content-class="cart-dropdown-menu overflow-y-auto"
                      attach="#cart-dropdown-anchor"
                      offset-y
                      left
                    >
                      <template v-slot:activator="{ on }">
                        <client-only>
                          <div
                            class="clickable d-none d-sm-flex align-end"
                            v-on="on"
                          >
                            <v-badge
                              :value="itemCount > 0"
                              color="gray"
                              class="v-badge-default"
                            >
                              <template v-slot:badge>
                                {{ itemCount }}
                              </template>
                              <v-icon class="mdi primary--text">
                                mdi-cart
                              </v-icon>
                            </v-badge>
                            <span class="subtext">Cart</span>
                          </div>
                        </client-only>
                      </template>
                      <div class="scrollable-content">
                        <h1
                          class="text-center text-uppercase"
                          :class="{ 'mb-8': itemCount, 'mb-4': !itemCount }"
                        >
                          Your Cart
                        </h1>
                        <div v-if="itemCount">
                          <div
                            v-for="(item, i) of cartItems"
                            :key="'cart-dropdown-item_' + i"
                            class="cart-dropdown-item-wrapper"
                            @click="$event.stopPropagation()"
                          >
                            <cart-list-item :item="item" />
                          </div>
                        </div>
                      </div>
                      <div class="absolute-content">
                        <div v-if="!itemCount">
                          <p class="cart-empty text-center font-large py-6">
                            Your cart is empty
                          </p>
                        </div>
                        <order-total-progress-bar
                          v-if="itemCount"
                          class="mt-3 mb-6"
                          full-width-bar
                        />
                        <div
                          v-if="itemCount && (promotionApplication && promotionApplication.length > 0)"
                          class="discount-item"
                        >
                          <div class="discount-sub-text-wrapper">
                            <span class="accent--text">Discounts</span>
                              <div v-for="(pa, i) in promotionApplication" :key="'item+promotion_' + i">
                                <span class="info-text">{{ pa.promotion.name }}</span>
                              </div>
                          </div>
                            <div v-for="(pa, i) in promotionApplication" :key="'item+promotion_' + i">
                                <span class="accent--text">
                                  -{{ pa.totalDiscountAmount | formatCurrency }}
                                </span>
                              </div>
                      </div>
                        <nuxt-link to="/cart" tag="div" exact>
                          <base-button text="View Cart" x-large block />
                        </nuxt-link>
                      </div>
                    </v-menu>
                  </button>
                </div>
              </div>
              <div class="d-flex align-content-center fx-1">
                <div
                  v-if="!events"
                  class="categories d-flex pl-sm-8"
                  @mouseleave="leaveHover"
                >
                  <div
                  v-for="(category, i) in categories"
                  :key="'category_' + i"
                  class="link clickable pr-3 pr-md-5 pr-lg-7"
                  @mouseover="handleHover(category)"
                >
                  <button
                    v-if="category.data"
                    role="link"
                    @click="navigate(category)"
                  >
                    {{ category.data.name }}
                  </button>
                  </div>

                  <div class="link clickable pr-3 pr-md-5 pr-lg-7" @mouseover="leaveHover">
                    <nuxt-link to="/gift-cards" class="keepCase">
                      eGIFT CARDS
                    </nuxt-link>
                  </div>

                  <div class="link clickable pr-3 pr-md-5 pr-lg-7" @mouseover="leaveHover">
                    <nuxt-link to="/contact">
                      Contact Us
                    </nuxt-link>
                  </div>
                </div>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <the-sub-nav
      v-if="!events"
      :category="selectedCategory"
      @mouseleave="leaveHover"
      @close="closeSubNav"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex"
import { AUTH_TYPES } from "@/store/auth"
import { LAYOUT_TYPES } from "@/store/layout"
import { CART_TYPES } from "@/store/cart"
import { PRODUCTS_TYPES } from "@/store/products"
import { ORGANIZATIONS_TYPES } from "@/store/organizations"
import { getProductQuery } from "@/helpers/utilities"
import CartListItem from "@/components/snippets/cart-list-item"
import OrderTotalProgressBar from "@/components/snippets/order-total-progress-bar"
import TheSearchBar from "@/components/layout/the-search-bar"
import TheSubNav from "@/components/layout/the-sub-nav"

export default {
  name: "TheMainNav",
  components: {
    TheSearchBar,
    TheSubNav,
    CartListItem,
    OrderTotalProgressBar
  },
  data () {
    return {
      sticking: false,
      searchHasFocus: false,
      showCartMenu: false,
      hoveredCategory: null,
      selectedCategory: null,
      ignoreHover: false
    }
  },
  computed: {
    ...mapState({
      cartItems: state => state.cart.items,
      promotionApplication: state => state.cart.promotionApplication,
      authenticatedUser: state => state.auth.authenticatedUser,
      categories: state => state.products.categories,
      generalInfo: state => state.layout.generalInfo
    }),
    ...mapGetters({
      itemCount: CART_TYPES.getters.itemCount,
      browsingAs: ORGANIZATIONS_TYPES.getters.browsingAs,
      browsingAsOptions: ORGANIZATIONS_TYPES.getters.browsingAsOptions
    }),
    events () {
      return this.$router.currentRoute.fullPath.includes("/events")
    },
    accountGreeting () {
      let greeting = "Sign In"
      if (this.authenticatedUser) {
        if (this.browsingAs.id === "Self") {
          greeting = `Hi, ${this.authenticatedUser.userProfile.firstName}!`
        } else {
          greeting = `Hi, ${this.browsingAs.name}!`
        }
      }
      return greeting
    }
  },
  mounted () {
    this.headerSize()
    // window.addEventListener("scroll", this.handleScroll)
  },
  destroyed () {
    window.removeEventListener("scroll", this.handleScroll)
  },
  methods: {
    ...mapMutations({
      setDisplayMobileMenu: LAYOUT_TYPES.mutations.SET_DISPLAY_MOBILE_MENU
    }),
    ...mapActions({
      signIn: AUTH_TYPES.actions.b2cSignIn,
      signUp: AUTH_TYPES.actions.b2cSignUp,
      signOut: AUTH_TYPES.actions.clearSession,
      setBrowsingAs: ORGANIZATIONS_TYPES.actions.setBrowsingAs,
      resetProductList: PRODUCTS_TYPES.actions.reset
    }),
    handleScroll () {
      // this.showCartMenu = false
      // this.headerSize()
    },
    headerSize () {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight || 0
      const clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight || 0
      /* On stick, the tab nav disappears and the main nav shrinks, which caused scrollTop to change to 0 and back
       * on certain window sizes, and therefore alternate quickly between sticking and not sticking.
       * The following line checks the height difference against a number that is slightly higher than
       * the combination of the tab nav height and the height of the main nav that is lost when it's sticking,
       * and the 'sticking' value is not set when within that range. */
      const ignoreForRange = scrollHeight - clientHeight <= 125

      if (scrollTop > 35 && !this.sticking && !ignoreForRange) {
        this.sticking = true
      } else if (scrollTop < 35 && this.sticking) {
        this.sticking = false
      }
    },
    toggleMobileNav (close = false) {
      this.setDisplayMobileMenu(!this.displayMobileMenu)
      let scrollPos = 0
      if (!close) {
        scrollPos = window.scrollY
        document.body.style.top = "-" + scrollPos + "px"
        document.body.style.position = "fixed"
        document.body.style.width = "100%"
      } else {
        document.body.style.position = ""
        document.body.style.top = ""
        document.body.style.width = ""
        window.scrollTo(window.scrollX, scrollPos)
      }
    },
    navigate (category) {
      this.ignoreHover = true // Set to true to avoid subnav flash on mouse move
      this.closeSubNav()
      const query = getProductQuery(category)
      if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
        return
      }
      this.resetProductList()
      this.$router.push({ name: "products", query })
    },
    handleHover (category) {
      if (this.ignoreHover) {
        return
      }
      this.hoveredCategory = category
      if (category.data.navigation_columns.length === 0) {
        this.hoveredCategory = null
        this.selectedCategory = null
        return
      }
      // Prevent sub-nav flash by adding a delay
      setTimeout(() => {
        // if the mouse is still hovering the same category it was before the timeout, open that sub-nav
        if (this.hoveredCategory === category) {
          this.selectedCategory = category
        }
      }, 300)
    },
    leaveHover (event) {
      this.ignoreHover = false
      this.hoveredCategory = null
      if (event && event.target && event.target.classList &&
        (event.target.classList.contains('sub-nav') ||
          event.target.classList.contains('catch-hover-container') ||
          event.target.classList.contains('categories'))) {
        return
      }
      this.selectedCategory = null
    },
    closeSubNav () {
      this.selectedCategory = null
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';
  @import './scss/accessibility';

a {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.catch-hover-container {
  width: 100%;
  height: 50%;
}

.main-nav {
  position: sticky;
  top: 0;
  z-index: 200;
}

.app-header {
  position: sticky;
  top: 0;
  z-index: 200;
  background-color: $white;
  transition: box-shadow 0.5s;

  .content-constraint {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 16em;
    transition: height 0.5s;
  }

  &.sticking {
    box-shadow: 0 0.5em 0.5em 0 rgba(0, 0, 0, 0.4);

    .content-constraint {
      justify-content: space-between;
      height: 7em;
    }

    .logo {
      margin: 0 2em;
      width: 13em;
      height: 4em;
    }

    .search-and-cart-and-categories {
      display: flex;

      .search {
        display: flex;
        margin-right: 0;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        max-width: none;
        padding-right: 0 !important;
      }

      .categories {
        order: -1;
        padding: 2.5em 0;
      }
    }

    .menu-and-logo-and-cart-and-account {
      align-items: center;
      margin-top: 0;

      .cart-and-account {
        align-items: center;
      }
    }
  }

  .menu-and-logo-and-cart-and-account {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 4em;
    // bug 20888 not sure if this is useful anymore, but it appears to be adding extra space in the nav on small (tablet) screens.
    //width: 100%;
  }

  .menu-and-logo {
    display: flex;
    transition: width 0.5s;
    justify-content: space-around;
  }

  .mdi-menu {
    font-size: 3.5em;
    color: $grey;
    flex-shrink: 0;
  }

  .cart-and-account {
    transition: width 0.5s;
    display: flex;
    align-items: flex-end;
    margin: 0.5em 2em;

    .mdi {
      font-size: 2.2em;
      margin-bottom: -0.1em;
      margin-right: 0.2em;
    }

    .account {
      display: flex;
      align-items: flex-end;
      margin-right: 2em;
      white-space: nowrap;

      .welcome {
        font-weight: 300;
        font-size: 1.2em;
      }

      .my-account {
        font-weight: 700;
        font-size: 1.4em;
        text-transform: uppercase;
      }

      .account-dropdown-menu {
        font-weight: 700;
        min-width: 28em !important;
        background: $white;
        padding: 1.6em;
        border-collapse: collapse;
        padding: 16px 24px;
        max-height: 500px;
        overflow-y: auto;

        .browse-as-subheader {
          padding-left: 0;

          .browse-as-label {
            font-size: 1.5em;
            font-weight: 300;
            font-style: normal;
            color: $charcoal-grey;
          }
        }

        .account-list-item {
          border-top: 1px solid $grey;
          border-bottom: 1px solid $grey;
          margin: -1px 0;
          padding-left: 0;

          &.browse-as-option {
            .v-list-item__icon {
              margin-left: 0px;
              margin-right: 16px;
            }

            .v-list-item__subtitle {
              font-weight: 500;
              color: grey;
            }
          }

          &.browse-as-option:first-child {
            border-top: none;
          }

          &.browse-as-option:last-child {
            border-bottom: none;
          }

          span {
            font-size: 1.7em;
          }
        }
      }
    }

    .cart {
      white-space: nowrap;
      display: flex;
      align-items: flex-end;

      span.subtext {
        font-size: 1.4em;
        text-transform: uppercase;
        font-weight: 700;
      }

      .mdi {
        margin-bottom: 0.08em;
      }

      .cart-dropdown-menu {
        min-width: 48.8em !important;
        background: $white;

        .scrollable-content {
          padding: 3.2em 3.2em 1em 3.2em;
          max-height: 50vh;
          overflow-y: auto;

          .cart-dropdown-item-wrapper {
            padding-top: 7px;
            border-top: 1px solid $light-grey;
          }
        }

        .absolute-content {
          padding: 0 3.2em 3.2em 3.2em;

          .discount-item {
            font-size: 1.6em;
            display: flex;
            justify-content: space-between;
            font-weight: $font-weight-extra;
            margin: 1.5em 0;

            .discount-sub-text-wrapper {
              display: flex;
              flex-direction: column;
              flex: 1;
            }
          }

          .cart-empty {
            border-top: 1px solid $light-grey;
          }
        }
      }
    }
  }

  .logo {
    width: 20.5em;
    height: 7em;
    margin: 0.5em 2.5em;
    flex-shrink: 0;
    background-image: url("../../static/logo-dark.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .search {
    max-width: 68.9em;
  }

  .categories {
    padding-top: 2em;
    padding-bottom: 2.8em;

      .link a, .link button {
        color: $charcoal-grey;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        font-size: 1.4em;
        white-space: nowrap;
        text-decoration: none !important;
      }
    }

  .search-bar {
    width: 100%;
  }
}

@media only screen and (max-width: 1150px) {
  .app-header .cart-and-account {
    align-items: center;

    .cart {
      .mdi {
        margin-bottom: 0;
      }
    }

    .mdi {
      font-size: 3em;
    }

    .welcome,
    .my-account,
    .cart span.subtext {
      display: none;
    }
  }
}

@media only screen and (max-width: 1010px) {
  .app-header {
    font-size: 0.99vw;

    &.sticking {
      .content-constraint {
        align-items: center;
      }

      .categories {
        display: none !important;
      }
    }
  }
}

@media only screen and (min-width: 771px) {
  .app-header {
    .mdi-menu {
      display: none;
    }

    &.sticking .content-constraint {
      padding: 0;
    }
  }
}

@media only screen and (max-width: 770px) {
  .app-header {
    font-size: 10px;

    .content-constraint,
    &.sticking .content-constraint {
      flex-direction: column;
      align-items: flex-start;
      height: auto;
    }

    .menu-and-logo-and-cart-and-account {
      margin: 1em 0 !important;
      align-items: center;
    }

    .menu-and-logo {
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 8px;
    }

    &.sticking {
      .menu-and-logo {
        width: 13em;
        margin-bottom: 0;
      }
      .cart-and-account {
        width: 40px;
      }
    }

    .menu-and-logo-and-cart-and-account {
      flex-wrap: wrap;
      // bug 20888 not sure if this is useful anymore, but it appears to be adding extra space in the nav on small (tablet) screens.
      //width: 100%;
    }

    .categories {
      display: none !important;
    }

    &.sticking {
      .menu-and-logo-and-cart-and-account {
        flex-wrap: nowrap;

        &.focus-in-search {
          .menu-and-logo,
          .cart-and-account,
          #cart-dropdown-anchor {
            width: 0;
            overflow: hidden;
            margin: 0 !important;
          }
        }
      }
    }

    .logo {
      width: 13em;
      height: 4em;
      margin: 0 2em;
    }

    &.sticking .logo {
      width: 5em;
      margin: 0 2em;
      background-image: url("../../static/logo-small-dark.svg");
    }

    .cart-and-account .account {
      display: none;
    }
  }
}

  .active-v-list-item {
    color: rgba(255, 255, 255, 0) !important;
  }

  .logo-container {
    outline: none !important;
    &:focus-visible {
      .logo {
        @extend .focus-outline;
      }
    }
  }
  .keepCase {
    text-transform: none !important;
  }
</style>
