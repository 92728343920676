export const UserOrganizationStatusEnum = Object.freeze({
  CREATED: 0,
  INVITATION_PENDING: 1,
  ACTIVE: 2,
  INACTIVE: 3,
  INVITATION_DECLINED: 4,
  DELETED: 5
})

export const BakerFirstStatusEnum = Object.freeze({
  INITIAL: 0,
  PENDING_APPROVAL: 1,
  APPROVED: 2,
  DENIED: 3
})
