import { removeNamespace } from '@/helpers/utilities'

export const TAX_EXEMPTION_TYPES = {
  getters: {
    customerExemptions: 'exemptions/customerExemptions',
    organizationExemptions: 'exemptions/organizationExemptions'
  },
  mutations: {
    SET_LOADING: 'exemptions/SET_LOADING',
    SET_EXEMPTIONS: 'exemptions/SET_EXEMPTIONS'
  },
  actions: {
    fetchExemptions: 'exemptions/fetchExemptions'
  }
}

const _types = removeNamespace('exemptions', TAX_EXEMPTION_TYPES)

const state = () => ({
  loading: true,
  exemptions: []
})

const getters = {
  [_types.getters.customerExemptions] (state) {
    return state.exemptions.filter(x => !x.organizationId)
  },
  [_types.getters.organizationExemptions]: state => (organizationId) => {
    return state.exemptions.filter(x => x.organizationId === organizationId)
  }
}

const mutations = {
  [_types.mutations.SET_LOADING] (state, bool) {
    state.loading = bool
  },
  [_types.mutations.SET_EXEMPTIONS] (state, exemptions) {
    state.exemptions = exemptions
  }
}

const actions = {
  [_types.actions.fetchExemptions] ({ commit }) {
    commit(_types.mutations.SET_LOADING, true)
    return this.$axios.get('store/tax-exemption-certificates')
      .then((res) => {
        commit(_types.mutations.SET_EXEMPTIONS, res.data)
        return res.data
      })
      .finally(() => commit(_types.mutations.SET_LOADING, false))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
