import * as cloneDeep from 'lodash.clonedeep'
// import Vue from 'vue'

export default function ({ $axios, store }, inject) {
  class GiftCardCache {
    constructor () {
      this._giftCardProductCache = Object.freeze([])
      this._giftCardImage = null
    }

    /** Returns all gift card products, the first call fetches them from the backend and caches
         * the response, making all future calls instant.
         *
         * @returns array of gift card products
         */
    async getGiftCardProducts () {
      if (!this._giftCardProductCache || this._giftCardProductCache.length <= 0) {
        const giftCardProductData = await $axios.get('store/GiftCard/products')
        this._giftCardProductCache = Object.freeze(giftCardProductData.data)
      }
      return cloneDeep(this._giftCardProductCache)
    }

    /** Gets the list price options for the gift cards
     * @returns Array of numbers
     */
    async getAmounts () {
      const giftCardProducts = await this.getGiftCardProducts()
      const giftCardAmounts = giftCardProducts.map(g => g.basePrice).sort((a, b) => a - b)
      return giftCardAmounts
    }

    async getGiftCardImage () {
      if (!this._giftCardImage) {
        const image = await $axios.get('store/GiftCard/Image')
        this._giftCardImage = image.data
      }
      return this._giftCardImage.publisherUrl
    }

    async getGiftCardPaymentMethods () {
      const giftCardPaymentMethods = await $axios.get('store/GiftCard/GetGiftCardPayment')
      return giftCardPaymentMethods
    }
  }

  const giftCardCache = new GiftCardCache()

  inject('giftCardCache', giftCardCache)
}
