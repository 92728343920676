<template>
  <div v-click-outside="clickOutside" class="search-bar d-flex" :class="{'active': active}" @click="setActive(true)">
    <v-select
      v-show="showSelect"
      :value="searchType"
      :items="selectItems"
      class="text-uppercase search-select"
      outlined
      append-icon="mdi-chevron-down"
      aria-label="Search Type"
      attach=".search-select"
      @change="changeSearchType($event)"
    />
    <v-combobox
      :value="search"
      :items="items"
      :loading="isLoading"
      :search-input.sync="searchInput"
      hide-no-data
      hide-details
      placeholder="SEARCH"
      append-icon="mdi-magnify"
      outlined
      :auto-select-first="false"
      :filter="filter"
      class="search-autocomplete"
      :class="{ 'select-hidden': !showSelect }"
      attach=".search-autocomplete .v-menu"
      @change="changeSearch($event)"
      @click:append="changeSearch(searchInput)"
    >
      <template v-slot:item="data">
        <template>
          <span v-html="data.item" />
        </template>
      </template>
    </v-combobox>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { PRODUCTS_TYPES } from '@/store/products'
import { SearchTypeEnum } from '@/helpers/enums/product-enums'

export default {
  props: {
    sticking: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    characterLimit: 85,
    entries: [],
    isLoading: false,
    model: null,
    searchInput: null,
    selectItems: [{ text: 'BOOKS', value: SearchTypeEnum.Products }, { text: 'BIBLES', value: SearchTypeEnum.Bibles }],
    active: false,
    timerId: null,
    cancel: null,
    filter: () => true // Just return true since filtering is handled on the backend
  }),
  computed: {
    ...mapState({
      search: state => state.products.search,
      searchType: state => state.products.searchType
    }),
    showSelect () {
      if (!this.sticking) {
        return true
      }
      return this.active
    },
    items () {
      return this.entries.map((entry) => {
        return entry && entry.length > this.characterLimit ? entry.slice(0, this.characterLimit) + '...' : entry
      })
    }
  },
  watch: {
    search () {
      // When searched value changes, clear out entries so that dropdown box hides
      this.clear()
    },
    searchInput (input) {
      // User cleared out input
      if (!input || !input.trim()) {
        this.clear()
      }

      // Debounce so that search doesn't run until user stops typing
      // clearTimeout(this.timerId)
      // this.timerId = setTimeout(() => {
      //   this.loadSearchAutocompleteItems(input)
      // }, 500)
    }
  },
  methods: {
    ...mapActions({
      changeSearchDispatch: PRODUCTS_TYPES.actions.changeSearch,
      changeSearchType: PRODUCTS_TYPES.actions.changeSearchType
    }),
    clickOutside () {
      this.setActive(false)
      this.clear()
    },
    setActive (active) {
      if (this.active === active) { return }
      this.active = active
      this.$emit('focus', active)
    },
    clear () {
      this.entries = []
      this.count = 0
      clearTimeout(this.timerId)
      if (this.cancel) {
        this.cancel()
      }
    },
    changeSearch (searchTerm) {
      if (!searchTerm) {
        return
      }
      const term = searchTerm.replace('<b>', '').replace('</b>', '')
      this.changeSearchDispatch(term)
    },
    loadSearchAutocompleteItems (input) {
      this.isLoading = true
      const CancelToken = this.$axios.CancelToken
      this.$axios.get('store/products/suggest',
        {
          params: {
            search: input,
            searchType: this.searchType
          }
        },
        {
          cancelToken: new CancelToken((c) => {
            this.cancel = c
          })
        })
        .then((res) => {
          if (!res || !res.data) {
            this.count = 0
            this.entries = []
          } else {
            this.count = res.data.length
            this.entries = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
    }
  }
}
</script>
<style lang="scss" scoped>
  .search-autocomplete {
    min-width: 100px;
  }
</style>
