<template>
  <v-app>
    <v-container v-if="isAuthenticated" class="my-5 content-constraint">
        <nuxt />
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import { AUTH_TYPES } from '@/store/auth'

export default {
  name: 'Clean',
  components: {
  },
  computed: {
    ...mapGetters({
      isAuthenticated: AUTH_TYPES.getters.isAuthenticated
    })
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';

  @media screen and (max-width: 658px) {
    .d-none {
      display: none !important;
    }
  }
</style>
