import LogRocket from 'logrocket'

if (process.env.LOGROCKET_KEY) {
  LogRocket.init(process.env.LOGROCKET_KEY)
}

export default ({ app }, inject) => {
  const logLevel = process.env.LOG_LEVEL
  inject('logger', initializeLogger({ app, options: { logLevel } }))
}

export const initializeLogger = ({ app, options }) => {
  const logger = {}
  const logLevels = Object.keys(LogLevels).map(l => l.toLowerCase())
  logLevels.forEach((logLevel, logLevelIndex) => {
    if (logLevelIndex >= logLevels.indexOf(options.logLevel)) {
      logger[logLevel] = (message, error) => {
        printLogMessage(logLevel, message)
        recordAppInsightTrace(app.$appInsights, logLevelIndex, message)
      }
    } else {
      logger[logLevel] = () => undefined
    }
  })

  return logger
}

export const LogLevels = Object.freeze({
  DEBUG: 'debug',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
  FATAL: 'fatal'
})

const printLogMessage = (logLevel, message) => {
  if (logLevel === 'warn' || logLevel === 'error' || logLevel === 'fatal') {
    console[logLevel === 'fatal' ? 'error' : logLevel](message)
  } else {
    console.log(message)
  }
}

const recordAppInsightTrace = (appInsights, severityLevel, message) => {
  appInsights.trackTrace({
    message,
    severityLevel
  })
}
