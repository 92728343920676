import { removeNamespace } from '@/helpers/utilities'
import { CART_TYPES } from '@/store/cart'

export const CHECKOUT_TYPES = {
  mutations: {
    SET_GUEST_USER: 'checkout/SET_GUEST_USER',
    SET_GUEST_CHECKOUT: 'checkout/SET_GUEST_CHECKOUT',
    SET_PROCESS_STEP: 'checkout/SET_PROCESS_STEP',
    SET_SHIPPING_ADDRESS_CONFIRMED: 'checkout/SET_SHIPPING_ADDRESS_CONFIRMED',
    SET_SELECTED_SHIPPING_ADDRESS: 'checkout/SET_SELECTED_SHIPPING_ADDRESS',
    SET_PRIMARY_PICKUP_PERSON: 'checkout/SET_PRIMARY_PICKUP_PERSON',
    SET_SECONDARY_PICKUP_PERSON: 'checkout/SET_SECONDARY_PICKUP_PERSON',
    SET_SELECTED_PAYMENT_METHOD: 'checkout/SET_SELECTED_PAYMENT_METHOD',
    ADD_GIFT_CARD_PAYMENT: 'checkout/ADD_GIFT_CARD_PAYMENT',
    REMOVE_GIFT_CARD_PAYMENT: 'checkout/REMOVE_GIFT_CARD_PAYMENT',
    REMOVE_ALL_GIFT_CARD_PAYMENT: 'checkout/REMOVE_ALL_GIFT_CARD_PAYMENT',
    SET_BILLING_ADDRESS_CONFIRMED: 'checkout/SET_BILLING_ADDRESS_CONFIRMED',
    SET_SELECTED_BILLING_ADDRESS: 'checkout/SET_SELECTED_BILLING_ADDRESS',
    SET_BILLING_IS_SHIPPING: 'checkout/SET_BILLING_IS_SHIPPING',
    SET_PAYMENT_ERROR: 'checkout/SET_PAYMENT_ERROR',
    SET_IS_COVERED_BY_GIFT_CARDS: 'checkout/SET_IS_COVERED_BY_GIFT_CARDS'
  },
  actions: {
    setSelectedShippingAddress: 'checkout/setSelectedShippingAddress',
    setSelectedPaymentMethod: 'checkout/setSelectedPaymentMethod',
    addGiftCardPayment: 'checkout/addGiftCardPayment',
    removeGiftCardPayment: 'checkout/removeGiftCardPayment',
    removeAllGiftCardPayment: 'checkout/removeAllGiftCardPayment',
    setSelectedBillingAddress: 'checkout/setSelectedBillingAddress',
    setPaymentError: 'checkout/setPaymentError',
    setProcessStep: 'checkout/setProcessStep'
  }
}

const _types = removeNamespace('checkout', CHECKOUT_TYPES)

const state = () => ({
  guestUser: null,
  processStep: 1,
  shippingAddressConfirmed: false,
  selectedShippingAddress: null,
  primaryPickupPerson: null,
  secondaryPickupPerson: null,
  selectedPaymentMethod: null,
  giftCardPayments: [],
  billingAddressConfirmed: false,
  selectedBillingAddress: null,
  billingIsShipping: false,
  paymentError: null,
  isFullyCoveredByGiftCards: false
})

const getters = {}

const mutations = {
  [_types.mutations.SET_GUEST_USER] (state, user) {
    state.guestUser = user
  },
  [_types.mutations.SET_PROCESS_STEP] (state, step) {
    state.processStep = step
  },
  [_types.mutations.SET_SHIPPING_ADDRESS_CONFIRMED] (state, bool) {
    state.shippingAddressConfirmed = bool
  },
  [_types.mutations.SET_SELECTED_SHIPPING_ADDRESS] (state, address) {
    state.selectedShippingAddress = address
  },
  [_types.mutations.SET_PRIMARY_PICKUP_PERSON] (state, person) {
    state.primaryPickupPerson = person
  },
  [_types.mutations.SET_SECONDARY_PICKUP_PERSON] (state, person) {
    state.secondaryPickupPerson = person
  },
  [_types.mutations.SET_SELECTED_PAYMENT_METHOD] (state, method) {
    state.selectedPaymentMethod = method
  },
  [_types.mutations.ADD_GIFT_CARD_PAYMENT] (state, giftCardData) {
    const payments = [...state.giftCardPayments]
    payments.push(giftCardData)
    state.giftCardPayments = payments
  },
  [_types.mutations.SET_IS_COVERED_BY_GIFT_CARDS] (state, cartState) {
    const sum = state.giftCardPayments.reduce((a, b) => a + (b.amount || 0), 0)
    state.isFullyCoveredByGiftCards = sum >= cartState.cartTotal
  },
  [_types.mutations.REMOVE_GIFT_CARD_PAYMENT] (state, giftCardData) {
    const filtered = state.giftCardPayments.filter(x => x.giftCardNumber !== giftCardData.giftCardNumber)
    state.giftCardPayments = filtered
  },
  [_types.mutations.REMOVE_ALL_GIFT_CARD_PAYMENT] (state) {
    state.giftCardPayments = []
  },
  [_types.mutations.SET_BILLING_ADDRESS_CONFIRMED] (state, bool) {
    state.billingAddressConfirmed = bool
  },
  [_types.mutations.SET_SELECTED_BILLING_ADDRESS] (state, address) {
    state.selectedBillingAddress = address
  },
  [_types.mutations.SET_BILLING_IS_SHIPPING] (state, bool) {
    state.billingIsShipping = bool
  },
  [_types.mutations.SET_PAYMENT_ERROR] (state, errorMessage) {
    state.paymentError = errorMessage
  }
}

const actions = {
  [_types.actions.setSelectedShippingAddress] ({ commit, dispatch }, payload) {
    commit(_types.mutations.SET_SELECTED_SHIPPING_ADDRESS, payload)
    dispatch(CART_TYPES.actions.setShippingAddress, payload && payload.address, { root: true })
  },
  [_types.actions.setSelectedPaymentMethod] ({ commit, rootGetters }, payload) {
    commit(_types.mutations.SET_SELECTED_PAYMENT_METHOD, payload)
    if (!!payload && payload.billingAddress) {
      commit(_types.mutations.SET_SELECTED_BILLING_ADDRESS, { address: payload.billingAddress })
    }
  },
  [_types.actions.addGiftCardPayment] ({ commit, rootState }, payload) {
    commit(_types.mutations.ADD_GIFT_CARD_PAYMENT, payload)
    commit(_types.mutations.SET_IS_COVERED_BY_GIFT_CARDS, rootState.cart)
  },
  [_types.actions.removeGiftCardPayment] ({ commit, rootState }, payload) {
    commit(_types.mutations.REMOVE_GIFT_CARD_PAYMENT, payload)
    commit(_types.mutations.SET_IS_COVERED_BY_GIFT_CARDS, rootState.cart)
  },
  [_types.actions.removeAllGiftCardPayment] ({ commit, rootState }) {
    commit(_types.mutations.REMOVE_ALL_GIFT_CARD_PAYMENT)
    commit(_types.mutations.SET_IS_COVERED_BY_GIFT_CARDS, rootState.cart)
  },
  [_types.actions.setSelectedBillingAddress] ({ commit }, payload) {
    commit(_types.mutations.SET_SELECTED_BILLING_ADDRESS, payload)
  },
  [_types.actions.setPaymentError] ({ commit }, payload) {
    commit(_types.mutations.SET_PAYMENT_ERROR, payload)
    commit(_types.mutations.SET_PROCESS_STEP, 3)
  },
  [_types.actions.setProcessStep] ({ commit }, payload) {
    if (payload > 2) {
      commit(_types.mutations.SET_PAYMENT_ERROR, null)
    }
    commit(_types.mutations.SET_PROCESS_STEP, payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
