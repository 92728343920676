<template>
  <div>
    <div>
      <conditional-confirm-dialog
      title="Update Bundle"
      :message="bundleConfirmMessage"
      :confirm-text="bundleConfirmationBtnMessage"
      cancel-text="Cancel"
      :dialog="showBundleDialog"
      :loading="false"
      @closeDialog="closeBundleDialog()"
      @dialogCallback="updateBundle()"
      />
    </div>
  <div class="my-5">
    <div class="cart-item-body d-flex">
      <div class="cart-item-img">
        <nuxt-link v-a11y-clickable.link :to="'/products/'+item.product.id" tag="div" class="clickable">
          <base-image
            :source="item.product.featuredImageUrl | fullImagePath('S')"
            :alt="item.product.title + ' Book Cover'"
            position="left top"
            :max-width="(checkout || $breakpoint.is.xs) ? '80' : ''"
            :max-height="(checkout || $breakpoint.is.xs) ? '126' : ''"
            contain
          />
        </nuxt-link>
      </div>
      <div class="cart-item-details pr-5" :class="{ 'fx-1': checkout }">
        <div v-if="!checkout">
          <nuxt-link v-a11y-clickable.link :to="'/products/'+item.product.id" tag="div" class="clickable">
            <h1 :class="{ 'font-weight-bold': addToCartDialog, 'font-weight-black': !addToCartDialog }">
              {{ item.product.title }}
            </h1>
          </nuxt-link>
          <div v-if="item.product.author" class="author font-medium" :class="{ 'font-weight-bold': addToCartDialog, 'font-weight-medium': !addToCartDialog }">
            by {{ item.product.author }}
          </div>
        </div>
        <div v-else>
          <nuxt-link v-a11y-clickable.link :to="'/products/'+item.product.id" tag="div" class="clickable">
            <h1 class="font-x-large">
              {{ item.product.title }}
              <span v-if="item.product.author"> by {{ item.product.author }}</span>
            </h1>
          </nuxt-link>
        </div>
        <div class="format-option mt-3" :class="{ 'font-default-size font-weight-thin': !isQuantityEditable, 'font-weight-bold': addToCartDialog, 'font-weight-light': !addToCartDialog }">
          {{ item.product.type }}
          <span v-if="item.product.isUsed">
            - Used <span v-if="item.product.condition">({{ item.product.condition }})</span>
          </span>
        </div>
        <div v-if="isQuantityEditable && !isGiftCard" class="mobile-select mt-4">
          <base-quantity-select
            :quantity="itemQuantity ? selectedQty : item.quantityToOrder"
            :max="item.product.isUsed ? item.product.quantityAvailable : null"
            class="v-input-restrict-width"
            outlined
            :disabled="item.product.isNotForIndividualSale && hasBundlePromotion"
            @change="updateQuantity($event)"
          />
        </div>
        <div v-if="!isQuantityEditable || isGiftCard" class="mt-2" :class="{ 'font-small': !addToCartDialog, 'font-medium font-weight-bold': addToCartDialog }">
          Quantity: {{ item.quantityToOrder }}
        </div>
        <div v-if="checkout" class="mt-2 font-small font-medium font-weight-bold">
          <span v-if="isPreorder" class="font-weight-bold font-small mt-3">Pre-Order item. This title will be released on {{ item.product.latestAvailabilityDate | formatDateLong }}</span>
        </div>
      </div>
      <div class="product-pricing-wrapper">
        <div v-if="hasBundlePromotion" class="font-x-large accent--text font-weight-bold mb-1">
            {{ bundleTotalCartPrice | formatCurrency }}
        </div>
        <div v-else class="font-x-large accent--text font-weight-bold mb-1">
          {{ item.totalCartPrice | formatCurrency }}
        </div>
        <div v-if="hasBundlePromotion">
          <div class="gray--text font-weight-light font-default-size">
            {{ bundleUnitPrice | formatCurrency }} (bundle unit price)
          </div>
          <div class="gray--text font-weight-light font-default-size">
            {{ item.unitCartPrice | formatCurrency }} ea.
          </div>
        </div>
        <div v-else-if="item.quantityToOrder > 1 " class="gray--text font-weight-light font-default-size">
          {{ item.unitCartPrice | formatCurrency }} ea.
        </div>
        <div v-if="item.unitCartPrice !== item.unitMsrp" class="gray--text font-weight-light font-default-size old-price">
          {{ item.unitMsrp | formatCurrency }}
        </div>
        <div v-if="computedDiscount && item.isBulkDiscountApplied" class="accent--text font-weight-light font-default-size">
          Bulk Discount {{ computedDiscount }}%
        </div>
        <div
          v-else-if="!(item.promotionAllocations && item.promotionAllocations.length)
            && item.unitCartPrice < item.publisherBasePrice
            && authenticatedUser
            && browsingAs
            && browsingAs.isBakerFirst"
          class="accent--text font-weight-light mt-1"
        >
          <div>Save {{ computedDiscount }}%</div>
          <div> with Baker First price</div>
        </div>
        <div v-else-if="computedDiscount" class="accent--text font-weight-light font-default-size">
          Save {{ computedDiscount }}%
        </div>
        <div v-if="item.promotionAllocations && item.promotionAllocations.length > 0">
          <v-icon class="accent--text font-default-size">
            mdi-checkbox-marked-circle
          </v-icon>
          <span class="accent--text font-weight-light font-small">Promo applied!</span>
        </div>
      </div>
      <div>
        <v-icon
          v-if="isRemovable"
          v-a11y-clickable
          class="close-btn clickable align-start"
          aria-label="close"
          @click="remove"
        >
          mdi-close
        </v-icon>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as _ from 'lodash'
import { CART_TYPES } from '@/store/cart'
import { calculateDiscount } from '@/helpers/utilities'
import { ORGANIZATIONS_TYPES } from '~/store/organizations'
import ConditionalConfirmDialog from '@/components/dialogs/conditional-confirm-dialog'

export default {
  name: 'CartListItem',
  components: { ConditionalConfirmDialog },
  props: {
    item: {
      type: Object,
      required: true
    },
    checkout: {
      type: Boolean,
      default: false
    },
    addToCartDialog: {
      type: Boolean,
      default: false
    },
    isQuantityEditable: {
      type: Boolean,
      default: true
    },
    isRemovable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showBundleDialog: false,
      bundleResult: {},
      bundleItemsToRemove: [],
      bundleConfirmMessage: "",
      bundleConfirmationBtnMessage: "",
      requestToRemoveBundleItems: false,
      selectedQty: -1,
      bundleNumber: 0
    }
  },
  computed: {
    ...mapState({
      authenticatedUser: state => state.auth.authenticatedUser,
      cart: state => state.cart
    }),
    ...mapGetters({
      browsingAs: ORGANIZATIONS_TYPES.getters.browsingAs
    }),
    computedDiscount () {
      return calculateDiscount(this.item.unitCartPrice, this.item.unitMsrp)
    },
    itemQuantity () {
      return this.selectedQty !== -1
    },
    bundleUnitPrice () {
      let discountPrice
      for (let i = 0; i < this.item.promotionAllocations.length > 0; i++) {
        if (this.item.promotionAllocations[i].promotion.promotionTypeIdentifier === 4) {
          discountPrice = Number(parseFloat(this.item.promotionAllocations[i].discountPrice).toFixed(2))
          break
        }
      }
      return discountPrice
    },
    numberOfBundles () {
      // 1) get all the products that are in the bundle with the selected product and check if all bundle products exit in the cart
      // 2) determine if all those products are available for individual sale - if so, then we find the min qty and use that for the numberOfBundles result
      // 3) if we have products that are NOT available for individual sale - then we find the min qty of that set and use that for the numberOf Bundles result

      let numOfBundles = 0
      // check if all bundle products are in the cart.
      const cartHasAllBundleProducts = _.intersection(this.cart.items.map(x => x.product.id), this.item.product.bundleProducts).length === this.item.product.bundleProducts.length ? true : false
      if (cartHasAllBundleProducts) {
        // check to see if there are any products that are marked as not for individual sale
        const itemsNotForIndividualSale = this.cart.items.filter(x => x.product.bundleId === this.item.product.bundleId && x.product.isNotForIndividualSale === true)
        if (itemsNotForIndividualSale && itemsNotForIndividualSale.length > 0) {
          numOfBundles = _.min(itemsNotForIndividualSale.map(x => x.quantityToOrder))
        } else {
          const itemsForIndividualSale = this.cart.items.filter(x => x.product.bundleId === this.item.product.bundleId && x.product.isNotForIndividualSale === false)
          numOfBundles = _.min(itemsForIndividualSale.map(x => x.quantityToOrder))
        }
      }

      return numOfBundles
    },
    hasAdditionalItems () {
      if (this.item.product.bundleId != null && this.item.promotionAllocations && this.item.promotionAllocations.length > 0 && this.item.quantityToOrder > this.numberOfBundles) {
        return true
      }
      return false
    },
    bundleTotalCartPrice () {
      if (this.item.quantityToOrder >= this.numberOfBundles) {
        // the user is purchasing a bundle(s) and have additional quantity for that item in the cart
        return ((this.numberOfBundles) * parseFloat(this.bundleUnitPrice).toFixed(2)) + ((this.item.quantityToOrder - this.numberOfBundles) * this.item.unitCartPrice)
      } else {
        return ((this.numberOfBundles) * parseFloat(this.bundleUnitPrice).toFixed(2))
      }
    },
    hasBundlePromotion () {
      const status = (this.item.promotionAllocations && this.item.promotionAllocations.find(x => x.promotion.promotionTypeIdentifier === 4) ? true : false)
      return status
    },
    isGiftCard () {
      return this.item.product.giftCards?.length > 0
    },
    isPreorder () {
      return this.item.product && this.item.product.latestAvailabilityDate &&
        new Date(this.item.product.latestAvailabilityDate) > new Date()
    }
  },
  methods: {
    ...mapActions({
      updateItemInCart: CART_TYPES.actions.updateItemInCart,
      updateItemsToCart: CART_TYPES.actions.updateItemsToCart,
      removeItemFromCart: CART_TYPES.actions.removeItemFromCart,
      removeItemsFromCart: CART_TYPES.actions.removeItemsFromCart,
      pushNotification: CART_TYPES.actions.pushNotification
    }),
    dispatchCartAction (name, message, timeout) {
      this.pushNotification({
        id: this.item.product.id,
        name,
        link: {
          route: `/products/${this.item.product.id}`,
          text: (this.item.product.author) ? `${this.item.product.title} by ${this.item.product.author}` : this.item.product.title
        },
        message,
        timeout
      })
    },
    updateQuantity (quantity) {
      this.selectedQty = quantity
      const result = this.updateNotForIndividualSaleBundleItems(this.item, quantity)
      if (result.isBundledItem && result.isBundleProductsNotForIndividualSaleInCart && result.qtyBundleProductNotForIndividualSale > quantity) {
        this.bundleResult = result
        this.bundleConfirmMessage = "This change will result in quantities for bundle items that are not available for individual sale being updated.<br/><br/>Do you want to continue?"
        this.bundleConfirmationBtnMessage = "Update Quantities"
        this.showBundleDialog = true
      } else {
        const itemToUpdate = {
          ...this.item,
          quantityToOrder: quantity
        }
        this.updateItemInCart(itemToUpdate)
      }
    },
    updateBundle () {
      if (this.requestToRemoveBundleItems) {
        this.removeItemsFromCart(this.bundleItemsToRemove)
        this.showBundleDialog = false
        this.dispatchCartAction('Removed', 'has been removed from your cart', 2000)
      } else {
        const itemsToUpdate = []
        if (this.bundleResult.itemsToUpdate.length > 0) {
          for (let i = 0; i < this.bundleResult.itemsToUpdate.length; i++) {
            itemsToUpdate.push({
              ...this.bundleResult.itemsToUpdate[i].item,
              quantityToOrder: this.bundleResult.itemsToUpdate[i].quantity
            })
          }

          this.updateItemsToCart(itemsToUpdate)
          this.showBundleDialog = false
        }
      }
    },
    closeBundleDialog () {
      this.updatingQuantity = false
      this.selectedQty = this.item.quantityToOrder
      this.bundleResult = {}
      this.bundleItemsToRemove = []
      this.requestToRemoveBundleItems = false
      this.showBundleDialog = false
      this.bundleConfirmationBtnMessage = ""
    },
    updateNotForIndividualSaleBundleItems (paramItem, selectedQuantity) {
      const itemsToUpdate = []
      let isBundledItem = false
      const item = this.cart.items.find(x => x.product.id === paramItem.product.id)
      let qtyToAdd = selectedQuantity
      let qtyBundleProductNotForIndividualSale = 0
      // check to see if any products that are not available for individual sale are also in the cart
      const isBundleProductsNotForIndividualSaleInCart = _.intersection(this.cart.items.map(x => x.product.id), item.product.bundleProductsNotForIndividualSale).length > 0 ? true : false

      if (item.product.bundleId && isBundleProductsNotForIndividualSaleInCart) {
        isBundledItem = true
        const itemsForIndividualSale = this.cart.items.filter(x => x.product.id !== item.product.id && x.product.bundleId === item.product.bundleId && x.product.isNotForIndividualSale === false)
        const itemsForNotIndividualSale = this.cart.items.filter(x => x.product.id !== item.product.id && x.product.bundleId === item.product.bundleId && x.product.isNotForIndividualSale === true)

        // items that are avaliable for individual sale, we are free to buy as many as we want so add it to the array
        // the quantity update that triggers this function call will be an item that is marked for individual sale (Qty selector for items marked for "not for individual sale is disabled")
        itemsToUpdate.push({ item: paramItem, quantity: selectedQuantity })
        qtyBundleProductNotForIndividualSale = _.min(itemsForNotIndividualSale.map(x => x.quantityToOrder))

        // // if the bundle item is "not for individual sale" and the selected quantity is  greater than the max bundle quantity (this is the smallest quantity out of all bundle items that are marked for individual sale)
        // // dont allow them to add the extra items for the "not for individual sale" product
        if (itemsForIndividualSale && itemsForIndividualSale.length > 0) {
          const maxPossibleBundleQtyForNotForIndividualSaleProducts = _.min(itemsForIndividualSale.map(x => x.quantityToOrder))
          if (selectedQuantity > maxPossibleBundleQtyForNotForIndividualSaleProducts) {
            // not for individual sale items qty shouldn't exceed the minimum amount for "individual sale products"
            qtyToAdd = maxPossibleBundleQtyForNotForIndividualSaleProducts
          }
        }

        for (let i = 0; i < item.product.bundleProductsNotForIndividualSale.length; i++) {
          const cartItem = this.cart.items.find(x => x.product.id === item.product.bundleProductsNotForIndividualSale[i])
          itemsToUpdate.push({ item: cartItem, quantity: qtyToAdd })
        }
      }

      return {
        itemsToUpdate,
        isBundledItem,
        isBundleProductsNotForIndividualSaleInCart,
        qtyBundleProductNotForIndividualSale
      }
    },
    remove () {
      this.removeNotForIndividualSaleBundleItems(this.item)
    },
    removeNotForIndividualSaleBundleItems (item) {
      const itemsToRemove = this.cart.items.filter(x => x.product.id !== item.product.id && x.product.bundleId === item.product.bundleId && x.product.isNotForIndividualSale === true)
      if (itemsToRemove && itemsToRemove.length > 0) {
        this.bundleConfirmMessage = "This change will result in bundle items that are not available for individual sale being removed as well.<br/><br/>Do you want to continue?"
        this.bundleConfirmationBtnMessage = "Delete"
        this.showBundleDialog = true
        this.requestToRemoveBundleItems = true
        itemsToRemove.push(item)
        this.bundleItemsToRemove = itemsToRemove
      } else {
        this.removeItemFromCart(item)
        this.dispatchCartAction('Removed', 'has been removed from your cart', 2000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';

  .font-x-large {
    font-size: 19px !important;
  }

  .product-pricing-wrapper {
    width: 130px;

    .old-price {
      text-decoration: line-through;
    }
  }

  .cart-item-img {
    width: 10.2em;
    max-height: 16em;
    margin-top: 0;
  }

  .cart-item-details {
    margin-left: 1.6em;
    flex: 1;

    h1 {
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 1.3em;
      white-space: normal;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .author {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .format-option {
      font-size: 12px;
      margin-top: 10px;
    }
  }
</style>
