export default function (doc) {
  if (doc.isBroken) {
    return '/not-found'
  }

  switch (doc.type) {
    case 'home':
    case 'banner_text':
    case 'product_list_marketing_content':
    case 'category':
    case 'custom_styles':
    case 'general':
    case 'newsletter_sign_up':
      return '/'
    case 'page':
      return '/pages/' + doc.uid
    case 'cart_content':
      return '/cart'
    case 'product':
      return '/products/' + doc.uid.slice(3)
    case 'baker-events':
      return '/baker-events/' + doc.uid
    case 'kiosk/cafe/panels':
      return '/kiosk/cafe/panels' + doc.uid
    case 'kiosk/cafe/full':
      return '/kiosk/cafe/full' + doc.uid
    case 'kiosk/store/static/1280x800':
      return '/kiosk/store/static/1280x800' + doc.uid
    case 'kiosk/store':
      return '/kiosk/store/' + doc.uid
    case 'kiosk/store/static':
      return '/kiosk/store/static' + doc.uid
    case 'contact/faq':
      return '/contact/faq/' + doc.uid
    case 'contact/form':
      return '/contact/form/' + doc.uid
    default:
      return '/not-found'
  }
}
