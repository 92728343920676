import https from 'https'
import axios from 'axios'
import { AUTH_TYPES } from '@/store/auth'
import { extractChapterException } from '@/helpers/utilities'

export default function ({ $axios, store }, inject) {
  const frontDoor = axios.create({
    baseURL: process.env.API_FD_URL
  })
  frontDoor.defaults.headers.post['Content-Type'] = 'application/json'
  inject('frontDoor', frontDoor)

  const eventbrite = axios.create({
    baseURL: 'https://www.eventbriteapi.com/v3/'
  })
  eventbrite.defaults.headers.common.Authorization = `Bearer ${process.env.EVENTBRITE_API_KEY}`
  inject('eventbrite', eventbrite)

  if (process.env.B2C_ENV === 'lcl') {
    $axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false })
  }

  $axios.onError((error) => {
    if (!error.response) {
      throw error.message
    }
    const code = parseInt(error.response && error.response.status)
    // extra check for 'current-user' url prevents infinite loop of 401 calls in the case that the current-user request returns a 401
    if (code === 401 && error.config && error.config.url && !error.config.url.includes('current-user')) {
      store.dispatch(AUTH_TYPES.actions.trySetAccessToken, false)
        .then(
          () => {
            const retryConfig = { ...error.config }
            // after re-authorizing, the new token must be put into the previous request, otherwise the error.config uses the old authorization
            retryConfig.headers.Authorization = $axios.defaults.headers.common.Authorization
            return $axios.request(retryConfig)
          }
        ).catch(err => console.log(err))
    }

    const chapterException = extractChapterException(error)

    if (chapterException) {
      throw { ...chapterException, statusCode: code }
    } else {
      const message = error.response.data && error.response.data.Message
        ? error.response.data.Message
        : `Error for url: ${error.response.config ? error.response.config.url : 'Unknown'}`
      throw { statusCode: code, message }
    }
  })
}
