<template>
  <div class="base-quantity-select">
    <v-select
      v-show="selected < threshold || threshold === max"
      v-model="selected"
      v-bind="$attrs"
      class="v-text-no-shadow"
      :class="{ outlined, 'mobile': $breakpoint.is.smAndDown }"
      :label="label"
      :items="items"
      solo
      hide-details
      :disabled="disabled"
      :filled="disabled"
      @change="emitChange"
    >
      <template slot="selection" slot-scope="data">
        {{ (label !== '') ? label + ': ' + data.item.text : data.item.text }}
      </template>
    </v-select>
    <div
      v-show="selected >= threshold && threshold !== max"
    >
      <div class="ten-plus-quantity-select-wrapper">
        <base-input
          v-model="txtSelection"
          class="v-input-restrict-control-height"
          :label="label"
          hide-details
          numeric
          :rules="rules"
          :disabled="disabled"
          @input="handleUpdate"
        />
        <div v-if="toggleUpdateDisplay" class="options">
        <span
          v-a11y-clickable
          class="clickable primary--text txt-underline"
          @click="updateSelected"
        >
          Update
        </span>
          <v-icon
            v-a11y-clickable
            class="clickable gray--text"
            aria-label="cancel"
            @click="cancel"
          >
            mdi-close
          </v-icon>
        </div>
      </div>
      <p v-if="max && txtSelection > max" class="error--text font-small">
        Only {{ max }} copies are available
      </p>
    </div>
  </div>
</template>

<script>
import { fillArrayTo } from '@/helpers/utilities'

export default {
  name: 'BaseQuantitySelect',
  props: {
    label: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: true
    },
    autoUpdate: {
      type: Boolean,
      default: false
    },
    quantity: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: null
    },
    inputThreshold: {
      type: Number,
      default: 30
    },
    includeZero: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      txtSelection: this.max ? Math.min(this.max, this.inputThreshold) : this.inputThreshold,
      toggleUpdateDisplay: false,
      selected: 0
    }
  },
  computed: {
    threshold () {
      if (!this.max) {
        return this.inputThreshold
      }
      return Math.min(this.inputThreshold, this.max)
    },
    items () {
      const items = fillArrayTo(this.threshold).map((i) => {
        return {
          text: (i === this.threshold && i !== this.max) ? i.toString() + '+' : i.toString(),
          value: i
        }
      })
      if (!this.includeZero) {
        return items
      }
      return [
        {
          text: '',
          value: 0
        },
        ...items
      ]
    },
    rules () {
      if (!this.max) {
        return []
      }
      return [value => value <= this.max || `Only ${this.max} copies are available.`]
    }
  },
  watch: {
    quantity () {
      if (this.selected !== this.quantity) {
        this.selected = this.quantity
        if (this.selected >= this.threshold && (this.txtSelection !== this.selected)) {
          this.txtSelection = this.selected.toString()
        }
      }
    }
  },
  mounted () {
    this.selected = this.quantity
    if (this.selected >= this.threshold && (this.txtSelection !== this.selected)) {
      this.txtSelection = this.selected.toString()
    }
  },
  methods: {
    emitChange () {
      this.$emit('change', this.selected)
    },
    handleUpdate () {
      if (!this.autoUpdate) {
        this.toggleUpdateDisplay = true
        this.$emit('isUpdatingQuantity', true)
        return
      }

      this.updateSelected()
    },
    updateSelected () {
      const input = parseInt(this.txtSelection)
      this.selected = (!isNaN(input) && input > 0) ? input : 1
      if (this.selected < this.threshold) {
        this.txtSelection = this.threshold
      }
      this.toggleUpdateDisplay = false
      if (this.max && input > this.max) {
        return
      }
      this.$emit('isUpdatingQuantity', false)
      this.emitChange()
    },
    cancel () {
      this.selected = 1
      this.txtSelection = this.threshold
      this.emitChange()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';

  .ten-plus-quantity-select-wrapper {
    display: flex;
    font-size: 10px;
    align-items: center;

    .base-input {
      flex: 1 1 auto;
      min-width: 64px;
    }

    .options {
      display: flex;
      align-items: center;

      span {
        font-size: 1.4em;
        margin-left: 1em;
      }
      .v-icon {
        margin-left: 10px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    .ten-plus-quantity-select-wrapper .base-input {
      min-width: 40px;
    }
  }
</style>
