<template>
  <v-app>
    <logo-banner />
    <div class="app-body">
      <nuxt />
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import LogoBanner from '../components/banners/logo-banner'
import { AUTH_TYPES } from '@/store/auth'

export default {
  name: 'Checkout',
  components: { LogoBanner },
  head () {
    return {
      title: 'Checkout'
    }
  },
  computed: {
    ...mapState({
      guestUser: state => state.checkout.guestUser
    }),
    ...mapGetters({
      isGuest: AUTH_TYPES.getters.isGuest
    })
  },
  mounted () {
    if (this.isGuest && !this.guestUser) {
      this.$router.push('/auth/guest')
    }
  }
}
</script>

<style lang="scss">

  .v-overlay--active {
    z-index: 200 !important;
  }

  @media (min-width: 960px) and (max-width: 1300px) {
    .container {
      max-width: calc(100% - 2em);
    }
  }
</style>
