import Vue from 'vue'

/* This plugin fixes issues with breakpoints not being set on first page load, as well as as issues with
* mismatching node warnings and appendChild errors when not running in dev mode
* https://github.com/vuetifyjs/vuetify/issues/3436
*/
Vue.prototype.$breakpoint = new Vue({
  data () {
    return {
      mountedBreakpoints: {},
      default: {
        xs: true,
        xsOnly: true,
        sm: false,
        smOnly: true,
        smAndDown: true,
        smAndUp: false,
        md: false,
        mdOnly: false,
        mdAndDown: true,
        mdAndUp: false,
        lg: false,
        lgOnly: false,
        lgAndDown: true,
        lgAndUp: false,
        xl: false,
        xlOnly: false
      }
    }
  },
  computed: {
    is () {
      const result = Object.keys(this.$data.default).reduce((breakpoints, dim) => {
        breakpoints[dim] = this.breakpointWithDefault(dim)
        return breakpoints
      }, {})
      return result
    }
  },
  methods: {
    breakpointWithDefault (breakpoint) {
      return Object.keys(this.$data.mountedBreakpoints).length > 0 ? this.$data.mountedBreakpoints[breakpoint] : this.$data.default[breakpoint]
    }
  }
})

export default function ({ app }) {
  if (!app.mixins) {
    app.mixins = []
  }

  app.mixins.push({
    mounted () {
      this.$breakpoint.$data.mountedBreakpoints = this.$vuetify.breakpoint
    }
  })
}
