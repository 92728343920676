<template>
  <v-app>
    <the-tab-nav />
    <the-mobile-nav />
    <the-main-nav />
    <div class="app-body">
      <nuxt />
    </div>
    <the-footer />
  </v-app>
</template>

<script>
import TheTabNav from '@/components/layout/the-tab-nav'
import TheMobileNav from '@/components/layout/the-mobile-nav'
import TheMainNav from '@/components/layout/the-main-nav'
import TheFooter from '@/components/layout/the-footer'
export default {
  name: 'Events',
  components: { TheMainNav, TheMobileNav, TheTabNav, TheFooter }
}
</script>

<style scoped>

</style>
