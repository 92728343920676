<template>
  <v-text-field
    v-model="input"
    v-bind="$attrs"
    class="base-input v-text-no-shadow v-input-restrict-height"
    :class="[
      { 'mobile': $vuetify.breakpoint.smAndDown },
      { 'text-uppercase': uppercase }
    ]"
    :label="_label"
    :solo="type === 'solo'"
    :outlined="type === 'outlined'"
    :rules="validationRules"
    :disabled="disabled"
    :filled="disabled"
    @input="$emit('input', input)"
    @keypress="keypress"
    @blur="onBlurValidate"
  />
</template>

<script>
import IMask from 'imask'

export default {
  name: 'BaseInput',
  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'outlined'
    },
    border: {
      type: Boolean,
      default: true
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    mask: {
      type: Object,
      default: () => null
    },
    maxLength: {
      type: Number,
      default: null
    },
    restrictLength: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    numeric: {
      type: Boolean,
      default: false
    },
    alpha: {
      type: Boolean,
      default: false
    },
    email: {
      type: Boolean,
      default: false
    },
    uppercase: {
      type: Boolean,
      default: true
    },
    noAsterisk: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      input: this.$attrs.value
    }
  },
  computed: {
    _label () {
      return this.required && !this.noAsterisk ? this.label + '*' : this.label
    },
    validationRules () {
      const rules = [...this.rules]
      if (this.required) {
        rules.push(value => !!value || 'Required')
      }
      if (this.maxLength) {
        rules.push(value => (value && value.length <= this.maxLength) || `Length must be less than ${this.maxLength}`)
      }
      return rules
    }
  },
  watch: {
    $attrs () {
      if (this.input !== this.$attrs.value) {
        this.input = this.$attrs.value
      }
    }
  },
  methods: {
    keypress ($event) {
      if (this.numeric && !$event.key.toString().match(/^[0-9]/)) {
        $event.preventDefault()
      } else if (this.alpha && !$event.key.toString().match(/[a-zA-Z\s]+/)) {
        $event.preventDefault()
      } else if (this.restrictLength && $event.target.value.length === this.maxLength) {
        $event.preventDefault()
      } else if (this.mask) {
        IMask($event.target, this.mask)
      }
    },
    onBlurValidate () {
      if (this.email) {
        this.rules.push(value => (value && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(value)) || 'Must be a valid email')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';

  .base-input {
    min-height: 50px !important;

    &.mobile {
      min-height: 35px !important;
      font-size: $font-input-size !important;
    }

    label {
      text-transform: uppercase !important;
      font-size: $font-small;
    }
  }
</style>
