<template>
  <v-container class="my-10">
    <div class="d-flex flex-column content-center">
      <h1>Oops!</h1>
      <h3>{{ error.statusCode }} - {{ title }}</h3>
      <p
        v-if="error.message"
        class="font-default-size text-center"
      >
        {{ message }}
      </p>
      <nuxt-link to="/">
        <base-button text="Home" class="mt-5" />
      </nuxt-link>
    </div>
  </v-container>
</template>

<script>
export default {
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: 500
      })
    }
  },
  computed: {
    title () {
      let title = ''
      switch (this.error.statusCode) {
        case 404:
          title = 'Page not found!'
          break
        case 401:
          title = 'Unauthorized'
          break
        default:
          title = 'Something went wrong!'
          break
      }
      return title
    },
    message () {
      if (process.env.PRODUCTION !== 'true' && this.error && this.error.message) {
        return this.error.message
      }
      return `
          We're sorry, an error has occurred and the page could not be loaded.
          If this error persists, please contact our customer service team.
        `
    }
  },
  head () {
    return {
      title: this.error.statusCode + ' ' + this.title
    }
  },
  created () {
    console.error(this.error)
    if (this.error && this.error.statusCode === 404) {
      return // Skip logging 404 errors to App Insights because there are so many
    }
    let error = null
    if (this.error instanceof Error) {
      error = this.error
    } else if (this.error.config && this.error.config.url) {
      error = new Error(`${this.error.message} for url ${this.error.config.url} with method ${this.error.config.method}`)
    } else {
      error = new Error(this.error.message)
    }
    this.$appInsights.trackException({
      exception: error
    })
  }
}
</script>

<style lang="scss" scoped>
  h1 {
    font-size: 6em;
  }
  h3 {
    font-size: 3em;
  }
  p {
    max-width: 50%;
  }
</style>
