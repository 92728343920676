const middleware = {}

middleware['clear-prismic-api'] = require('..\\middleware\\clear-prismic-api.js')
middleware['clear-prismic-api'] = middleware['clear-prismic-api'].default || middleware['clear-prismic-api']

middleware['requires-auth'] = require('..\\middleware\\requires-auth.js')
middleware['requires-auth'] = middleware['requires-auth'].default || middleware['requires-auth']

middleware['session-pipeline'] = require('..\\middleware\\session-pipeline.js')
middleware['session-pipeline'] = middleware['session-pipeline'].default || middleware['session-pipeline']

export default middleware
