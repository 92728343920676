import createPlugin from 'logrocket-vuex'
import LogRocket from 'logrocket'
import { PRODUCTS_TYPES } from '@/store/products'
import { LAYOUT_TYPES } from '@/store/layout'

const plugins = []
if (process.env.LOGROCKET_KEY) {
  const logrocketPlugin = createPlugin(LogRocket)
  plugins.push(logrocketPlugin)
}

const actions = {
  async nuxtServerInit ({ dispatch }, context) {
    if (process.env.B2C_ENV !== 'lcl' && context.req && context.req.headers && !context.req.headers['x-forwarded-host'] && !context.req.headers['x-fd-healthprobe']) {
      context.redirect(301, process.env.APP_URL)
      return
    }
    await dispatch(PRODUCTS_TYPES.actions.loadCategories, context)
    await dispatch(LAYOUT_TYPES.actions.loadNotifyBanner, context)
    await dispatch(LAYOUT_TYPES.actions.loadGeneralInfo, context)
  }
}

export default {
  actions,
  plugins
}
