import { removeNamespace } from '@/helpers/utilities'
import { AUTH_TYPES } from '@/store/auth'
import { CART_TYPES } from '@/store/cart'
import { UserOrganizationStatusEnum, BakerFirstStatusEnum } from '~/helpers/enums/organization-enums'

export const ORGANIZATIONS_TYPES = {
  getters: {
    organization: 'organizations/organization',
    organizations: 'organizations/organizations',
    administeredOrganizations: 'organizations/administeredOrganizations',
    browsingAs: 'organizations/browsingAs',
    browsingAsOrgId: 'organizations/browsingAsOrgId',
    browsingAsOptions: 'organizations/browsingAsOptions'
  },
  mutations: {
    SET_LOADING: 'organizations/SET_LOADING',
    SET_ORGANIZATIONS: 'organizations/SET_ORGANIZATIONS',
    ADD_ORGANIZATION: 'organizations/ADD_ORGANIZATION',
    SET_BAKER_FIRST_STATUS: 'organizations/SET_BAKER_FIRST_STATUS',
    ADD_MEMBERS_TO_ORGANIZATION: 'organizations/ADD_MEMBERS_TO_ORGANIZATION',
    SET_BROWSING_AS: 'organizations/SET_BROWSING_AS'
  },
  actions: {
    createOrganization: 'organizations/createOrganization',
    fetchOrganizations: 'organizations/fetchOrganizations',
    updateOrganization: 'organizations/updateOrganization',
    deleteOrganization: 'organizations/deleteOrganization',
    addMembers: 'organizations/addMembers',
    setBrowsingAs: 'organizations/setBrowsingAs',
    respondToInvitation: 'organizations/respondToInvitation',
    applyForBakerFirst: 'organizations/applyForBakerFirst'
  }
}

const _types = removeNamespace('organizations', ORGANIZATIONS_TYPES)
const selfOption = {
  id: 'Self'
}

const state = () => ({
  loading: false,
  organizations: [],
  browsingAs: selfOption
})

const getters = {
  [_types.getters.organization]: state => (id) => {
    if (state.organizations && state.organizations.length) {
      return { ...state.organizations.find(org => org.id === id) }
    }
    return null
  },
  [_types.getters.organizations] (state) {
    return state.organizations
  },
  [_types.getters.administeredOrganizations] (state, getters, rootState, rootGetters) {
    const userId = rootGetters[AUTH_TYPES.getters.userId]
    return state.organizations.filter(o => o.userOrganizations.some(uo => uo.userId === userId && uo.isAdmin))
  },
  [_types.getters.browsingAs] (state) {
    return state.browsingAs
  },
  [_types.getters.browsingAsOrgId] (state) {
    const id = state.browsingAs.id
    return id === 'Self' ? null : id
  },
  [_types.getters.browsingAsOptions] (state, getters, rootState, rootGetters) {
    const userId = rootGetters[AUTH_TYPES.getters.userId]
    const options = [ selfOption ].concat(state.organizations
      .filter(o => o.userOrganizations
        .some(uo => uo.userId === userId && uo.status === UserOrganizationStatusEnum.ACTIVE)))
    return options
  }
}

const mutations = {
  [_types.mutations.SET_LOADING] (state, bool) {
    state.loading = bool
  },
  [_types.mutations.SET_ORGANIZATIONS] (state, organizations) {
    state.organizations = organizations
  },
  [_types.mutations.ADD_ORGANIZATION] (state, organization) {
    state.organizations.push(organization)
  },
  [_types.mutations.SET_BAKER_FIRST_STATUS] (state, { id, status }) {
    const org = state.organizations.find(o => o.id === id)
    org.bakerFirstStatus = status
  },
  [_types.mutations.ADD_MEMBERS_TO_ORGANIZATION] (state, { id, members }) {
    const org = state.organizations.find(o => o.id === id)
    org.userOrganizations.push(...members)
  },
  [_types.mutations.SET_BROWSING_AS] (state, option) {
    state.browsingAs = option
  }
}

const actions = {
  [_types.actions.createOrganization] ({ commit }, organization) {
    commit(_types.mutations.SET_LOADING, true)
    return this.$axios.post('store/organizations', organization)
      .then((res) => {
        commit(_types.mutations.ADD_ORGANIZATION, res.data)
        return res.data
      })
      .finally(() => commit(_types.mutations.SET_LOADING, false))
  },
  [_types.actions.fetchOrganizations] ({ commit }) {
    commit(_types.mutations.SET_LOADING, true)
    return this.$axios.get('store/organizations')
      .then((res) => {
        commit(_types.mutations.SET_ORGANIZATIONS, res.data)
        return res.data
      })
      .finally(() => commit(_types.mutations.SET_LOADING, false))
  },
  [_types.actions.updateOrganization] ({ commit, dispatch }, organization) {
    commit(_types.mutations.SET_LOADING, true)
    return this.$axios.patch(`store/organizations/${organization.id}`, organization)
      .then(() => {
        dispatch(_types.actions.fetchOrganizations)
      })
      .finally(() => commit(_types.mutations.SET_LOADING, false))
  },
  [_types.actions.deleteOrganization] ({ state, commit, dispatch }, id) {
    commit(_types.mutations.SET_LOADING, true)
    if (state.browsingAs.id === id) {
      dispatch(_types.actions.setBrowsingAs, selfOption)
    }
    return this.$axios.delete(`store/organizations/${id}`)
      .then(() => {
        dispatch(_types.actions.fetchOrganizations)
      })
      .finally(() => commit(_types.mutations.SET_LOADING, false))
  },
  [_types.actions.addMembers] ({ state, commit, dispatch }, { id, members }) {
    commit(_types.mutations.SET_LOADING, true)
    const org = state.organizations.find(o => o.id === id)
    commit(_types.mutations.ADD_MEMBERS_TO_ORGANIZATION, { id, members })
    dispatch(_types.actions.updateOrganization, org)
    commit(_types.mutations.SET_LOADING, false)
  },
  [_types.actions.setBrowsingAs] ({ commit, dispatch }, option) {
    commit(_types.mutations.SET_BROWSING_AS, option)
    return dispatch(CART_TYPES.actions.saveCart, null, { root: true })
  },
  [_types.actions.respondToInvitation] ({ state, commit, dispatch, rootGetters }, { id, response }) {
    commit(_types.mutations.SET_LOADING, true)
    const userOrg = { ...state.organizations.find(o => o.id === id).userOrganizations.find(uo => uo.userId === rootGetters[AUTH_TYPES.getters.userId]) }
    userOrg.status = response ? UserOrganizationStatusEnum.ACTIVE : UserOrganizationStatusEnum.INVITATION_DECLINED
    return this.$axios.patch(`store/organizations/${id}/user-organizations/${userOrg.id}`, { ...userOrg })
      .then(() => {
        dispatch(_types.actions.fetchOrganizations)
      })
      .finally(() => commit(_types.mutations.SET_LOADING, false))
  },
  [_types.actions.applyForBakerFirst] ({ state, commit, dispatch }, { id }) {
    commit(_types.mutations.SET_LOADING, true)
    commit(_types.mutations.SET_BAKER_FIRST_STATUS, { id, status: BakerFirstStatusEnum.PENDING_APPROVAL })
    const org = state.organizations.find(o => o.id === id)
    return this.$axios.patch(`store/organizations/${id}`, { ...org })
      .then(() => {
        dispatch(_types.actions.fetchOrganizations)
      })
      .finally(() => commit(_types.mutations.SET_LOADING, false))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
