<template>
  <div
    class="d-flex align-center base-radio"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <v-radio
      v-bind="$attrs"
      on-icon="mdi-record-circle"
      :off-icon="hover ? 'mdi-record-circle' : 'mdi-radiobox-blank'"
      color="primary"
      :ripple="false"
      :class="{ 'hover' : hover }"
    >
      <template v-slot:label>
        <slot />
      </template>
    </v-radio>
  </div>
</template>

<script>
export default {
  name: 'BaseRadio',
  data: () => {
    return {
      hover: false
    }
  }
}
</script>
