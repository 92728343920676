import Vue from 'vue'
import Vuetify from 'vuetify'
import ViewListIcon from '@/components/icons/view-list-icon'

Vue.use(Vuetify)

export default (ctx) => {
  const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#38668e',
          secondary: '#3f434e',
          accent: '#66cc66',
          error: '#ff9933',
          gray: '#8a97a4',
          white: '#ffffff'
        }
      }
    },
    icons: {
      values: {
        'view-list': {
          component: ViewListIcon
        }
      }
    }
  })

  ctx.app.vuetify = vuetify
  ctx.$vuetify = vuetify.framework
}
