<template>
  <v-btn
    v-bind="$attrs"
    depressed
    x-large
    class="base-button"
    :ripple="false"
    :class="[
      { uppercase: uppercase },
      { hover: hover },
      { mobile: $breakpoint.is.smAndDown },
      { loading: loading },
      'btn--' + backgroundColor,
    ]"
    @click="emitClick"
  >
    <div v-if="loading" style="position: absolute">
      <base-loader :loading="loading" :color="loaderColor" margin="0" />
    </div>
    <v-icon v-if="icon" class="button-content">
      {{ icon }}
    </v-icon>
    <div v-else class="button-content">
      {{ text }}
    </div>
  </v-btn>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    backgroundColor: {
      type: String,
      default: "dark-blue"
    },
    text: {
      type: String,
      default: null
    },
    uppercase: {
      type: Boolean,
      default: true
    },
    hover: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    loaderColor () {
      if (this.loading && this.backgroundColor.includes("outline")) {
        return "white"
      }
      switch (this.backgroundColor) {
        case "none":
        case "outline-dark-blue":
        case "outline-grey":
        case "off-white":
          return "primary"
        default:
          return "white"
      }
    }
  },
  methods: {
    emitClick (event) {
      if (!this.loading) {
        this.$emit("click", event)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./scss/variables";

.base-button {
  color: $white;
  font-weight: $font-weight-bold;
  transition: background-color 0.2s ease;
  font-size: 1.6em;
  height: 50px !important;
  text-decoration: none;

  &.mobile {
    height: 35px !important;
    min-height: 35px;
    font-size: $font-medium;
  }

  &:not(.v-btn--block) {
    min-width: 218px;
  }

  &:not(.uppercase) {
    text-transform: none;
  }

  &.loading .button-content {
    visibility: hidden;
  }
}

.btn--none {
  background-color: transparent !important;
  font-size: $font-medium;
  color: $dark-blue;

  .icon-and-text {
    color: $charcoal-grey;
  }

  &:not(.v-btn--block) {
    min-width: 0;
    padding: 0 10px;
  }

  &:hover {
    background-color: $even-lighter-grey !important;
  }

  .v-icon {
    color: $dark-blue;
  }
}

.btn--light-grey {
  background-color: $light-grey !important;
}

.btn--off-white {
  @extend .base-button;
  color: $charcoal-grey;
  background-color: $off-white !important;

  &:hover,
  &.hover {
    background-color: #dae1d1 !important;
  }
  &:active {
    background-color: #c0c9b5 !important;
  }
}

.btn--grey {
  @extend .base-button;
  background-color: $grey !important;

  &:hover,
  &.hover {
    background-color: #5c6d7e !important;
  }
  &:active {
    background-color: #465462 !important;
  }
}

.btn--dark-blue {
  @extend .base-button;
  background-color: $dark-blue !important;

  &:hover,
  &.hover {
    background-color: #214a6f !important;
  }
  &:active {
    background-color: #143553 !important;
  }
}

.btn--light-green {
  @extend .base-button;
  color: $charcoal-grey !important;
  background-color: $even-lighter-green !important;
}

.btn--outline-dark-blue {
  @extend .base-button;
  background: none !important;
  color: $dark-blue;

  &:not(.v-btn--disabled) {
    border: 1px solid $dark-blue;
  }

  &:hover,
  &.hover,
  &.loading {
    color: white;
    background-color: $dark-blue !important;
  }
  &:active {
    color: white;
    background-color: #214a6f !important;
  }
}
.btn--outline-grey {
  @extend .base-button;
  background: none !important;
  border: 1px solid $grey;
  color: $grey;

  &:hover,
  &.hover {
    color: white;
    background-color: $grey !important;
  }
  &:active {
    color: white;
    background-color: #747f8b !important;
  }
}

.btn--orange {
  @extend .base-button;
  background-color: $orange !important;

  &:hover,
  &.hover {
    background-color: #c47a2c !important;
  }
  &:active {
    background-color: #a4611a !important;
  }
}
</style>
