export const ShippingMethodTypeEnum = Object.freeze({

  Standard: 0,
  Expedited: 1,
  Pickup: 2,
  Email: -1
})

export const CountryEnum = Object.freeze({
  US: 1,
  Canada: 2
})
