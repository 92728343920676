<template>
  <div class="navigation-bar">
    <div class="content-center">
      <div class="content-constraint">
        <nuxt-link
          to="/"
          tag="a"
          class="navigation-bar-item clickable"
          active-class=""
          :class="{ 'selected': isShopSelected }"
        >
          Shop
        </nuxt-link>
        <nuxt-link
          to="/events"
          tag="a"
          class="navigation-bar-item clickable"
          active-class="selected"
          no-prefetch
        >
          Events
        </nuxt-link>
        <nuxt-link to="/pages/churches" tag="a" class="navigation-bar-item clickable" active-class="selected">
          Churches
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheTabNav',
  data () {
    return {
      isShopSelected: false
    }
  },
  watch: {
    '$route': {
      handler (route) {
        this.isShopSelected = !route.fullPath.includes('/pages/churches') && !route.fullPath.includes('/events')
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';

  .navigation-bar {
    background-color: $charcoal-grey;
    color: $white;
    text-transform: uppercase;

    .content-constraint {
      display: flex;
    }
  }

  .navigation-bar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 11em;
    height: 3.2em;
    font-size:1.4em;
    color:#f5f5f7;
    font-weight:bold;

    span {
      color: $white;
      font-size: 1.2em;
    }

    .mdi {
      color: $white;
      font-size: $font-default-size;
      margin-left: 0.8em;
    }

    &.selected {
      border-bottom: 2px solid $soft-green;
    }
  }

  @media screen and (max-width: 900px) {
    .navigation-bar-item {
      min-width: 10em;
      font-size:1em;
    }
  }

  a {
    color: $white;
    text-decoration: none;
  }
</style>
