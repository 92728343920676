<template>
<transition name="fade">
  <div v-if="displayMobileMenu">
    <div class="mobile-nav-overlay">
      <div class="mobile-nav secondary">
        <div class="mobile-nav-header">
          <nuxt-link to="/" exact>
            <div class="logo">
              <img src="/logo-dark.svg" alt="logo">
            </div>
          </nuxt-link>
          <div v-a11y-clickable class="account clickable" :class="{ open: displayAccountMenu }">
            <div class="account-header white--text" @click="toggleAccountNav()">
              <div class="pt-2">
                <div class="welcome white--text">
                  {{ accountGreeting }}
                </div>
                <div class="my-account white--text">
                  My Account
                </div>
              </div>
            </div>
            <div class="account-body">
              <div v-show="!authenticatedUser" class="links">
                <div class="link" @click="signIn">
                  <span>Sign In</span>
                </div>
                <div class="link" @click="signUp">
                  <span>Create Account</span>
                </div>
              </div>
              <div v-show="authenticatedUser" class="links">
                <nuxt-link to="/account" tag="div" class="link" @click.native="toggleMobileNav(true)">
                  <span>Account Overview</span>
                </nuxt-link>
                <nuxt-link to="/account/orders" tag="div" class="link" @click.native="toggleMobileNav(true)">
                  <span>Order History</span>
                </nuxt-link>
                <nuxt-link to="/account/information" tag="div" class="link" @click.native="toggleMobileNav(true)">
                  <span>Account Information</span>
                </nuxt-link>
                <nuxt-link to="/account/payment-methods" tag="div" class="link" @click.native="toggleMobileNav(true)">
                  <span>Payment Methods</span>
                </nuxt-link>
                <nuxt-link to="/account/address-book" tag="div" class="link" @click.native="toggleMobileNav(true)">
                  <span>Address Book</span>
                </nuxt-link>
                <nuxt-link to="/account/church-organization" tag="div" class="link" @click.native="toggleMobileNav(true)">
                  <span>Church Organization</span>
                </nuxt-link>
                <div class="link">
                  <div>
                    <div @click="toggleBroswingAsMenu(!showBroswingAsMenu)">
                      <span>
                        Browse Baker as...
                      </span>
                    </div>
                  </div>
                  <div class="browsing-as-menu" :class="{ 'hide-options': !showBroswingAsMenu }">
                    <div class="options">
                      <div
                        v-for="(option) in browsingAsOptions"
                        :key="option.id"
                        class="browsing-as-option"
                        :class="{ 'font-weight-bold' : browsingAs.id === option.id }"
                        @click="setBrowsingAsOption(option)"
                      >
                        {{ option.id === 'Self' ? authenticatedUser ? authenticatedUser.userProfile.fullName : 'Self' : option.name }}
                      </div>
                    </div>
                  </div>
                </div>
                <nuxt-link to="/wishlist" tag="div" class="link" @click.native="toggleMobileNav(true)">
                  <span>My Wishlist</span>
                </nuxt-link>
                <nuxt-link to="/account/tax-exempt-certificates" tag="div" class="link" @click.native="toggleMobileNav(true)">
                  <span>Exemption Information</span>
                </nuxt-link>
                <div class="link" @click="signOut">
                  <span>Sign Out</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mobile-nav-slider">
          <div class="mobile-nav-slider-content">
            <div class="mobile-nav-scroller">
              <div class="mobile-nav-body">
                <div class="mobile-main-nav" :class="{ hidden: showSecondaryNav }">
                  <div v-for="(category, i) in categories" :key="'category_' + i" class="link has-sub" @click="toggleSecondaryNav(category)">
                    <a v-if="category.data">
                      {{ category.data.name }}
                    </a>
                  </div>

                  <nuxt-link to="/gift-cards" class="link has-sub keepCase" @click.native="toggleMobileNav(true)">
                    eGIFT CARDS
                  </nuxt-link>
                </div>
                <div class="mobile-secondary-nav" :class="{ hidden: !showSecondaryNav }">
                  <div v-if="selectedCategory && selectedCategory.data" class="link back" @click="toggleSecondaryNav()">
                    <a>{{ selectedCategory.data.name }}</a>
                  </div>
                  <div v-for="(item, i) in secondaryNavItems" :key="'item_' + i" class="link">
                    <a
                      v-if="item && item.filter_type === 'link'"
                      :href="item.item_url.url"
                    >
                      {{ item.item_name }}
                    </a>
                    <a
                      v-else
                      @click="navigate(item)"
                    >
                      {{ item.item_name }}
                    </a>
                  </div>
                  <div v-if="selectedCategory && selectedCategory.data" class="link">
                    <a @click="navigate">
                      Shop all {{ selectedCategory.data.name }}
                    </a>
                  </div>
                </div>
              </div>
              <div class="mobile-nav-footer secondary">
                <div class="mobile-nav-footer-links">
                  <nuxt-link class="link" to="/pages/churches" @click.native="toggleMobileNav(true)">
                    Churches
                  </nuxt-link>
                  <nuxt-link class="link" to="/pages/about" @click.native="toggleMobileNav(true)">
                    About Us
                  </nuxt-link>
                  <nuxt-link class="link" to="/contact" @click.native="toggleMobileNav(true)">
                    Contact Us
                  </nuxt-link>
                </div>

                <div class="mobile-nav-footer-store-info">
                  <div class="title">
                    <span>Store Hours</span>
                  </div>
                      <div v-if="!generalInfo.monday && !generalInfo.tuesday && !generalInfo.wednesday && !generalInfo.thursday && !generalInfo.friday" class="footer-info">
                        Monday&mdash;Friday:
                        <div style="display: inline-block;">
                          {{ generalInfo.weekday }}
                        </div>
                      </div>
                      <div v-if="generalInfo.monday" class="footer-info">
                        Monday:
                        <div style="display: inline-block;">
                        {{ generalInfo.monday }}
                        </div>
                        </div>
                        <div v-if="generalInfo.tuesday" class="footer-info">
                          Tuesday:
                          <div style="display: inline-block;">
                        {{ generalInfo.tuesday }}
                        </div>
                        </div>
                        <div v-if="generalInfo.wednesday" class="footer-info">
                          Wednesday:
                          <div style="display: inline-block;">
                        {{ generalInfo.wednesday }}
                        </div>
                        </div>
                        <div v-if="generalInfo.thursday" class="footer-info">
                          Thursday:
                          <div style="display: inline-block;">
                        {{ generalInfo.thursday }}
                        </div>
                        </div>
                        <div v-if="generalInfo.friday" class="footer-info">
                          Friday:
                          <div style="display: inline-block;">
                        {{ generalInfo.friday }}
                        </div>
                      </div>
                  <div class="footer-info">
                    <span>Saturday: {{ generalInfo.saturday }}</span>
                  </div>
                  <div class="footer-info">
                    <span>Sunday: {{ generalInfo.sunday }}</span>
                  </div>
                  <div v-if="generalInfo.holiday_1" class="footer-info">
                    <span>{{ generalInfo.holiday_1 }}</span>
                  </div>
                  <div v-if="generalInfo.holiday_2" class="footer-info">
                    <span>{{ generalInfo.holiday_2 }}</span>
                  </div>
                  <div class="title">
                    <span>Visit Us</span>
                  </div>
                  <div class="footer-info">
                    <span>{{ generalInfo.address }}</span>
                  </div>
                  <div class="footer-info">
                    <span>{{ generalInfo.city___state___zip }}</span>
                  </div>

                  <div class="title">
                    <span>Call Us</span>
                  </div>
                  <div class="footer-info">
                    <span>{{ generalInfo.phone }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-a11y-clickable class="close-area" @click="toggleMobileNav(true)">
        <div class="mdi mdi-close clickable" />
      </div>
    </div>
  </div>
  </transition>
</template>

<script>
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
} from 'vuex'
import { getProductQuery } from '@/helpers/utilities'
import { LAYOUT_TYPES } from '@/store/layout'
import { AUTH_TYPES } from '@/store/auth'
import { PRODUCTS_TYPES } from '@/store/products'
import { ORGANIZATIONS_TYPES } from '@/store/organizations'

export default {
  name: 'TheMobileNav',
  data () {
    return {
      displayAccountMenu: false,
      selectedCategory: null,
      showSecondaryNav: false,
      showBroswingAsMenu: false
    }
  },
  computed: {
    ...mapState({
      displayMobileMenu: state => state.layout.displayMobileMenu,
      authenticatedUser: state => state.auth.authenticatedUser,
      categories: state => state.products.categories,
      generalInfo: state => state.layout.generalInfo
    }),
    ...mapGetters({
      browsingAs: ORGANIZATIONS_TYPES.getters.browsingAs,
      browsingAsOptions: ORGANIZATIONS_TYPES.getters.browsingAsOptions
    }),
    accountGreeting () {
      let greeting = 'Sign In'
      if (this.authenticatedUser) {
        if (this.browsingAs.id === 'Self') {
          greeting = `Hi, ${this.authenticatedUser.userProfile.firstName}!`
        } else {
          greeting = `Hi, ${this.browsingAs.name}!`
        }
      }
      return greeting
    },
    secondaryNavItems () {
      if (!this.selectedCategory || !this.selectedCategory.data || !this.selectedCategory.data.navigation_columns) {
        return
      }
      return this.selectedCategory.data.navigation_columns
        .filter(x => x.filter_type === 'flag' || x.filter_type === 'category' || x.filter_type === 'link')
        .sort((a, b) => {
          return a.column_name > b.column_name
        })
    }
  },
  methods: {
    ...mapMutations({
      setDisplayMobileMenu: LAYOUT_TYPES.mutations.SET_DISPLAY_MOBILE_MENU
    }),
    ...mapActions({
      signInDispatch: AUTH_TYPES.actions.b2cSignIn,
      signUpDispatch: AUTH_TYPES.actions.b2cSignUp,
      signOutDispatch: AUTH_TYPES.actions.clearSession,
      setBrowsingAs: ORGANIZATIONS_TYPES.actions.setBrowsingAs,
      resetProductList: PRODUCTS_TYPES.actions.reset
    }),
    signIn () {
      this.signInDispatch()
      this.toggleMobileNav(true)
    },
    signUp () {
      this.signUpDispatch()
      this.toggleMobileNav(true)
    },
    signOut () {
      this.signOutDispatch()
      this.toggleMobileNav(true)
    },
    toggleMobileNav (close = false) {
      this.setDisplayMobileMenu(!close)
      this.selectedCategory = null
      this.showSecondaryNav = false
      let scrollPos = 0
      if (!close) {
        scrollPos = window.scrollY
        document.body.style.top = '-' + scrollPos + 'px'
        document.body.style.position = 'fixed'
        document.body.style.width = '100%'
      } else {
        document.body.style.position = ''
        document.body.style.top = ''
        document.body.style.width = ''
        window.scrollTo(window.scrollX, scrollPos)
      }
    },
    toggleAccountNav () {
      this.displayAccountMenu = !this.displayAccountMenu
    },
    toggleSecondaryNav (category) {
      this.showSecondaryNav = !this.showSecondaryNav
      if (category) {
        this.selectedCategory = category
      } else {
        // Delaying to allow transition to complete
        setTimeout(() => {
          this.selectedCategory = null
        }, 400)
      }
    },
    navigate (item) {
      const query = getProductQuery(this.selectedCategory, item)
      if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
        this.toggleMobileNav(true)
        return
      }
      this.resetProductList()
      this.$router.push({ name: 'products', query })
      this.toggleMobileNav(true)
    },
    toggleBroswingAsMenu (show) {
      this.showBroswingAsMenu = show
    },
    setBrowsingAsOption (option) {
      this.setBrowsingAs(option)
      setTimeout(() => {
        this.toggleBroswingAsMenu(false)
      }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';

  .link {
    cursor: pointer;
    color: $charcoal-grey;
    font-weight: 700;
    text-decoration: none;
  }

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
   .mobile-nav-overlay {
      transition: opacity .3s .5s;
      opacity: 0;
      pointer-events: none;
      .mobile-nav {
        transform: translate(-100%, 0);
        transition: transform .3s;
      }
  }
}

  .mobile-nav-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1001;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 1;
    transition: opacity .3s;
    display: flex;
    .close-area {
      position: relative;
      width: 100%;
      height: 100%;
    }
    .mdi-close {
      position: absolute;
      top: 0.4em;
      right: 0.6em;
      color: $white;
      font-size: 2.9em;
    }
  }

  @media only screen and (max-width: 500px) {
    .mobile-nav-overlay {
      font-size: 3.125vw;
      /* 10x100/320 */
    }
  }

  .mobile-nav {
    display: flex;
    flex-direction: column;
    min-width: 25em;
    height: 100%;
    transition: transform .5s .3s;
    .mobile-nav-header {
      background-color: #ffffff;
    }
  }

  .mobile-nav-header {
    .logo {
      width: 15em;
      padding: 2em;
      img {
        width: 100%;
      }
    }
    .account {
      .account-header {
        background-color: $grey;
        padding: 0 1em 0 2em;
        display: flex;
        justify-content: space-between;
        &::after {
          content: "\F142";
          display: inline-block;
          font: normal normal normal 24px/1 "Material Design Icons";
          font-size: 3em;
          text-rendering: auto;
          line-height: inherit;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          transform: rotate(90deg);
          transition: transform .5s;
        }
      }
      &.open .account-header::after {
        transform: rotate(-90deg);
      }
      .welcome {
        font-weight: $font-weight-thin;
        font-size: 1.0em;
      }
      .my-account {
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        font-size: 1.2em;
      }
      .account-body {
        background-color: #eff2f6;
        font-size: 100%;
        transition: font-size .3s;
        .links {
          padding: 2em;
          transition: opacity .3s .3s;
          opacity: 1;
        }
      }
      &:not(.open) .account-body {
        .links {
          opacity: 0;
          transition: opacity .3s;
        }
        font-size: 0;
        transition: font-size .3s .3s;
      }
      .account-body .link {
        font-weight: $font-weight-thin;
        &:not(:last-child) {
          margin-bottom: 2em;
        }
        span {
          font-size: 1.1em;
        }
      }
      .account-body .browsing-as-menu {
        font-weight: $font-weight-thin;
        margin-bottom: 2em;
        font-size: 1.1em;
        transition: font-size .3s;
        .options {
          transition: opacity .3s .3s;
          opacity: 1;
          margin-left: 1.5em;
        }
        &.hide-options {
          .options {
            opacity: 0;
            transition: opacity .3s;
          }
          font-size: 0;
          transition: font-size .3s .3s;
          margin-bottom: 0em
        }
        span {
          font-size: 1.1em;
        }
      }
      .account-body .browsing-as-option {
        font-weight: $font-weight-thin;
        margin-top: 2em;
        span {
          font-size: 1.1em;
        }
      }
    }
  }

  .mobile-nav-scroller {
    overflow-y: scroll;
    /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
    flex-shrink: 0;
    width: 100%;
  }

  .mobile-nav-slider {
    height: 100%;
    overflow: hidden;
  }

  .mobile-nav-slider-content {
    height: 100%;
    display: flex;
    position: relative;
    left: 0;
    transition: left .5s;
    &.deep {
      left: -100%;
    }
  }

  .mobile-nav-body {
    display: flex;

    .mobile-main-nav {
      position: relative;
      left: 0;
      width: 100%;
      transition: ease-out .4s;
      &.hidden {
        left: -100%;
        width: 0;
        overflow: hidden;
      }
    }
    .mobile-secondary-nav {
      position: relative;
      width: 100%;
      left: 0;
      transition: ease-out .4s;
      &.hidden {
        width: 0;
        left: 100%;
        overflow: hidden;
      }
    }
  }

  .mobile-nav-body .link {
    border-bottom: 1px solid #b6b0a3;
    height: 4em;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1em 0 2em;
    background-color: #ffffff;

    a {
      color: #3f434e;
      text-decoration: none;
      font-weight: $font-weight-bold;
    }
  }

  .mobile-nav .mobile-nav-body .link {
    span {
      font-size: 1.2em;
    }
    &.has-sub::after {
      content: "\F142";
      display: inline-block;
      font: normal normal normal 24px/1 "Material Design Icons";
      font-size: 3em;
      text-rendering: auto;
      line-height: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .mobile-nav-body .link.back {
    justify-content: flex-start;
  }

  .mobile-nav {
    .mobile-nav-body .link.back::before {
      content: "\F141";
      display: inline-block;
      font: normal normal normal 24px/1 "Material Design Icons";
      font-size: 3em;
      text-rendering: auto;
      line-height: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-left: -0.4em;
    }
    .mobile-nav-footer {
      color: $white;
      padding: 2.3em;
      font-weight: $font-weight-thin;
      .mobile-nav-footer-links .link {
        display: block;
        text-decoration: none;
        margin-bottom: 1.6em;
        color: $white;
        font-size: 1.5em;
      }
      .mobile-nav-footer-store-info {
        .title {
          text-transform: uppercase;
          &:not(:first-child) {
            margin-top: 1.6em;
          }
          span {
            color: $white;
            font-size: 1.2em;
          }
        }
        .footer-info span {
          color: $white;
          font-size: 1.2em;
        }
      }
    }
  }

</style>
