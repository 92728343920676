import prismicAPI from '@/helpers/prismic-api'

prismicAPI.getSingle('custom_styles').then((data) => {
  if (!data || !data.document) {
    return
  }
  const style = document.createElement('style')
  style.innerHTML = `
  .book-cover-reel:before {
    background-color: ${data.document.book_reel_background_color} !important;
  }
`
  document.head.appendChild(style)
})
