const momentBusiness = require('moment-business-days')

const d = new Date()
const year = d.getFullYear()
const nextYear = year + 1

const holidays = [
  '01/01/' + year, // New Years Day
  getDate(year, 4, -1, 1), // Memorial Day - Last Monday in May
  '07/04/' + year, // Independence Day
  getDate(year, 8, 0, 1), // Labor Day - First Monday in September
  getDate(year, 10, 3, 4), // Thanksgiving Day - 4th Thursday in November
  '12/25/' + year, // Christmas
  '01/01/' + nextYear,
  getDate(nextYear, 4, -1, 1),
  '07/04/' + nextYear,
  getDate(nextYear, 8, 0, 1),
  getDate(nextYear, 10, 3, 4),
  '12/25/' + nextYear
]

momentBusiness.updateLocale('us', {
  holidays,
  holidayFormat: 'MM/DD/YYYY'
})

function getDate (year, month, week, day) {
  let firstDay = 1
  if (week < 0) {
    month++
    firstDay--
  }
  const date = new Date(year, month, (week * 7) + firstDay)
  if (day < date.getDay()) {
    day += 7
  }
  date.setDate(date.getDate() - date.getDay() + day)
  return date.toLocaleDateString('en-us')
}
