<template>
  <div class="order-total-wrapper">
    <div class="ml-auto">
      <span v-if="!promotionApplied">Order Subtotal:</span>
      <span v-else>Order Subtotal before discounts:</span>
      <span class="accent--text font-weight-black">{{ totalItemCartPrice | formatCurrency }}</span>
    </div>
    <v-progress-linear
      v-if="!hideBar"
      color="accent"
      background-color="gray"
      class="ml-auto"
      :class="{'full-width': fullWidthBar}"
      :value="((totalItemCartPrice / freeShippingAmount) * 100)"
      rounded
      height="8"
    />
    <small v-if="valueNeededForFreeShipping > 0" class="ml-auto font-small">
      <div>
        You are {{ valueNeededForFreeShipping | formatCurrency }} away from free standard shipping!
      </div>
      <div class="mt-2">
        eGift Card purchases do not contribute toward order totals for free shipping
      </div>
    </small>
    <small v-else-if="freeShipping" class="ml-auto d-flex align-center" :class="{ 'mt-2': hideBar }">
      <v-icon class="accent--text font-default-size pr-1">
        mdi-checkbox-marked-circle
      </v-icon>
      <span class="accent--text font-weight-light font-small">Free shipping applied!</span>
    </small>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { CART_TYPES } from '@/store/cart'

export default {
  name: 'OrderTotalProgressBar',
  props: {
    fullWidthBar: {
      type: Boolean,
      default: false
    },
    promotionApplied: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      totalItemCartPrice: state => state.cart.totalItemCartPrice
    }),
    ...mapGetters({
      valueNeededForFreeShipping: CART_TYPES.getters.valueNeededForFreeShipping,
      freeShippingAmount: CART_TYPES.getters.freeShippingAmount,
      freeShipping: CART_TYPES.getters.freeShipping
    }),
    hideBar () {
      // If a free shipping promo code has been applied, hide the bar
      return this.totalItemCartPrice < this.freeShippingAmount && this.freeShipping
    }
  }
}
</script>

<style lang="scss" scoped>
  @import 'scss/variables';

  .order-total-wrapper {
    display: flex;
    flex-direction: column;

    span {
      font-size: 2.1em;
      &:first-child {
        margin-right: 5px;
      }
    }

    .v-progress-linear {
      margin: 1.6em 0;

      &:not(.full-width) {
        max-width: 287px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    .order-total-wrapper {
      span {
        font-size: 1.8em;
      }
    }
  }
</style>
