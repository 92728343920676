import Vue from 'vue'
import moment from 'moment-business-days'
import { OrderStatusIdentifier } from '@/helpers/enums/order-enums'
import { ShippingMethodTypeEnum } from '@/helpers/enums/shipping-enums'

Vue.filter('formatDate', function (value, format = 'MM/DD/YYYY') {
  if (value) {
    return moment(String(value)).format(format)
  }
})

Vue.filter('formatDateLong', function (value, format = 'MMMM D, YYYY') {
  if (value) {
    return moment(String(value)).format(format)
  }
})

Vue.filter('formatUtcDateLong', function (value, format = 'MMM D, YYYY') {
  if (value) {
    if (value.length && value[value.length - 1] === 'Z') {
      return moment(String(value)).format(format)
    }
    return moment(`${value}Z`).format(format)
  }
})

Vue.filter('formatUtcDate', function (value, format = 'MM/DD/YYYY') {
  if (value) {
    if (value.length && value[value.length - 1] === 'Z') {
      return moment(String(value)).format(format)
    }
    return moment(`${value}Z`).format(format)
  }
})

Vue.filter('formatUtcTime', function (value, format = 'h:mma') {
  if (value) {
    if (value.length && value[value.length - 1] === 'Z') {
      return moment(String(value)).format(format)
    }
    return moment(`${value}Z`).format(format)
  }
})

Vue.filter('formatCurrency', function (value) {
  if (typeof value !== 'number') {
    return value
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })
  return formatter.format(value)
})

Vue.filter('formatCurrencyNoSymbol', function (value) {
  if (typeof value !== 'number') {
    return value
  }
  return value.toLocaleString('en-US', { minimumFractionDigits: 2 })
})
Vue.filter('formatDateFromBusinessDays', function (value) {
  if (value) {
    return moment().businessAdd(value, 'd').format('MMM D')
  }
})

Vue.filter('formatShippingWindow', function (value) {
  if (value) {
    if (value.deliveryWindowBusinessDaysStart === value.deliveryWindowBusinessDaysEnd) {
      return `${value.deliveryWindowBusinessDaysStart} business day${value.deliveryWindowBusinessDaysStart > 1 ? 's' : ''}`
    } else {
      return `${value.deliveryWindowBusinessDaysStart} - ${value.deliveryWindowBusinessDaysEnd} business days`
    }
  }
})

Vue.filter('formatPickupWindow', function (value) {
  if (value) {
    if (value.deliveryWindowBusinessDaysStart === value.deliveryWindowBusinessDaysEnd) {
      return `${moment().businessAdd(value.deliveryWindowBusinessDaysStart, 'd').format('MMM D')}`
    } else {
      return `${moment().businessAdd(value.deliveryWindowBusinessDaysStart, 'd').format('MMM D')} - ${moment().businessAdd(value.deliveryWindowBusinessDaysEnd, 'd').format('MMM D')}`
    }
  }
})

Vue.filter('orderStatus', function (value) {
  if (value) {
    const key = Object.keys(OrderStatusIdentifier)[value - 1] // Get the status value (eg. CancelRequested)
    return key.replace(/([A-Z])/g, ' $1') // Add spaces as needed (eg. Cancel Requested)
  }
})

Vue.filter('shippingMethodType', function (value) {
  if (value >= 0) {
    const key = Object.keys(ShippingMethodTypeEnum)[value]
    return key.replace(/([A-Z])/g, ' $1')
  }
})

/* Pass in anticipatedInStockDate and get back the day the order will be ready for pickup */
Vue.filter('orderReadyForPickupDate', function (value) {
  if (!value) {
    return
  }

  let readyDate = moment(value)
  const orderSameDayCutoff = moment(17, 'HH') // Any orders created after 5pm will not be available until the next business day
  if ((readyDate.isSame(moment(), 'day') && moment() > orderSameDayCutoff) || !readyDate.isBusinessDay()) {
    readyDate = readyDate.nextBusinessDay()
  }
  if (readyDate.isSame(moment(), 'year')) {
    return readyDate.format('MMMM D')
  } else {
    return readyDate.format('MMMM D, YYYY')
  }
})

// Available sizes in pixel width (Height will vary due to maintaining aspect)
// 'XS' - 100
// 'S' - 200
// 'M' - 400
// 'L' - 600
// 'XL' - 800
// 'O' (Original)
Vue.filter('fullImagePath', function (value, size) {
  if (!value) {
    return null
  }
  if (value.startsWith('http')) {
    // remove prismic autocompression if image filter marked with F (full)
    if (size === 'F') {
      const paramIndex = value.lastIndexOf("?")
      value = paramIndex > 0 ? value.substring(0, value.lastIndexOf("?")) : value
    }
    return value
  }
  size = size || 'L'
  return process.env.CDN_URL + value + size + '.png'
})
