<template>
  <div class="main-nav">
    <div class="app-header" :class="{ sticking: sticking }">
      <div class="content-center">
        <div class="content-constraint">
          <div class="menu-and-logo-and-cart-and-account">
            <div class="menu-and-logo mr-4 mr-sm-0">
              <div v-a11y-clickable class="mdi mdi-menu clickable" @click="toggleMobileNav()" />
              <div>
                <a id="logoLink" target="_blank" href="http://www.bakerpublishinggroup.com">
                  <div id="logoImage" class="logo" />
                </a>
                <div class="catch-hover-container" />
              </div>
            </div>
            <div class="fx-1 event-title-and-cart">
              <div class="d-flex align-content-center fx-1">
                <div class="event-title fx-1 pr-md-8 pl-sm-8 primary--text" />
                <div class="cart-and-account mr-0 mr-sm-2" :class="{ 'ml-auto': events }">
                  <div id="account-dropdown-anchor" class="account">
                    <v-menu
                      content-class="account-dropdown-menu"
                      attach="#account-dropdown-anchor"
                      offset-y
                      left
                    >
                      <template v-slot:activator="{ on }">
                        <div v-a11y-clickable class="d-flex align-end clickable" v-on="on">
                          <v-icon class="mdi primary--text pb-1">
                            mdi-account
                          </v-icon>
                          <div>
                            <div class="welcome secondary--text">
                              {{ (authenticatedUser) ? 'Hi, ' + authenticatedUser.userProfile.firstName + '!' : 'Sign In' }}
                            </div>
                            <div class="my-account secondary--text">
                              My Account
                            </div>
                          </div>
                        </div>
                      </template>
                      <h1 class="text-center">
                        Account
                      </h1>
                      <client-only>
                        <v-list>
                          <div v-if="!authenticatedUser">
                            <v-list-item class="account-list-item" @click="signIn">
                              <span class="primary--text">Sign In</span>
                            </v-list-item>
                            <v-list-item class="account-list-item" @click="signUp">
                              <span class="primary--text">Create Account</span>
                            </v-list-item>
                          </div>
                          <div v-else>
                            <nuxt-link to="/account" tag="v-list-item" class="account-list-item">
                              <span class="primary--text">Account Settings</span>
                            </nuxt-link>
                            <nuxt-link to="/account/orders" tag="v-list-item" class="account-list-item">
                              <span class="primary--text">Order History</span>
                            </nuxt-link>
                            <nuxt-link to="/wishlist" tag="v-list-item" class="account-list-item">
                              <span class="primary--text">My Wishlist</span>
                            </nuxt-link>
                            <v-list-item class="account-list-item" @click="signOut">
                              <span class="primary--text">Sign Out</span>
                            </v-list-item>
                          </div>
                        </v-list>
                      </client-only>
                    </v-menu>
                  </div>
                  <div id="cart-dropdown-anchor" class="cart">
                    <client-only>
                      <nuxt-link v-a11y-clickable.link to="/cart" tag="div" exact class="clickable d-sm-none">
                        <v-badge :value="itemCount > 0" color="gray" class="v-badge-default" :class="{'mt-sm-2': !sticking}">
                          <template v-slot:badge>
                            {{ itemCount }}
                          </template>
                          <v-icon class="mdi primary--text mr-0">
                            mdi-cart
                          </v-icon>
                        </v-badge>
                      </nuxt-link>
                    </client-only>

                    <v-menu
                      v-model="showCartMenu"
                      content-class="cart-dropdown-menu overflow-y-auto"
                      attach="#cart-dropdown-anchor"
                      offset-y
                      left
                    >
                      <template v-slot:activator="{ on }">
                        <client-only>
                          <div v-a11y-clickable class="clickable d-none d-sm-flex align-end" v-on="on">
                            <v-badge :value="itemCount > 0" color="gray" class="v-badge-default">
                              <template v-slot:badge>
                                {{ itemCount }}
                              </template>
                              <v-icon class="mdi primary--text">
                                mdi-cart
                              </v-icon>
                            </v-badge>
                            <span class="subtext">Cart</span>
                          </div>
                        </client-only>
                      </template>
                      <div class="scrollable-content">
                        <h1
                          class="text-center text-uppercase"
                          :class="{'mb-8': itemCount, 'mb-4': !itemCount}"
                        >
                          Your Cart
                        </h1>
                        <div v-if="itemCount">
                          <div
                            v-for="(item, i) of cartItems"
                            :key="'cart-dropdown-item_'+i"
                            class="cart-dropdown-item-wrapper"
                            @click="$event.stopPropagation()"
                          >
                            <cart-list-item
                              :item="item"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="absolute-content">
                        <div v-if="!itemCount">
                          <p class="cart-empty text-center font-large py-6">
                            Your cart is empty
                          </p>
                        </div>
                        <order-total-progress-bar v-if="itemCount" class="mt-3 mb-6" full-width-bar />
                        <nuxt-link to="/cart" tag="div" exact>
                          <base-button
                            text="View Cart"
                            x-large
                            block
                          />
                        </nuxt-link>
                      </div>
                    </v-menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters
} from 'vuex'
import { AUTH_TYPES } from '@/store/auth'
import { LAYOUT_TYPES } from '@/store/layout'
import { CART_TYPES } from '@/store/cart'
import CartListItem from '@/components/snippets/cart-list-item'
import OrderTotalProgressBar from '@/components/snippets/order-total-progress-bar'
import { PRODUCTS_TYPES } from '@/store/products'

export default {
  name: 'TheMainNav',
  components: {
    CartListItem,
    OrderTotalProgressBar
  },
  data () {
    return {
      sticking: false,
      showCartMenu: false,
      ignoreHover: false
    }
  },
  computed: {
    ...mapState({
      cartItems: state => state.cart.items,
      authenticatedUser: state => state.auth.authenticatedUser
    }),
    ...mapGetters({
      itemCount: CART_TYPES.getters.itemCount
    }),
    events () {
      return this.$router.currentRoute.fullPath.includes('/events')
    }
  },
  mounted () {
    this.headerSize()
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapMutations({
      setDisplayMobileMenu: LAYOUT_TYPES.mutations.SET_DISPLAY_MOBILE_MENU
    }),
    ...mapActions({
      signIn: AUTH_TYPES.actions.b2cSignIn,
      signUp: AUTH_TYPES.actions.b2cSignUp,
      signOut: AUTH_TYPES.actions.clearSession,
      resetProductList: PRODUCTS_TYPES.actions.reset
    }),
    handleScroll () {
      this.showCartMenu = false
      this.headerSize()
    },
    headerSize () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight || 0
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight || 0
      /* On stick, the tab nav disappears and the main nav shrinks, which caused scrollTop to change to 0 and back
       * on certain window sizes, and therefore alternate quickly between sticking and not sticking.
       * The following line checks the height difference against a number that is slightly higher than
       * the combination of the tab nav height and the height of the main nav that is lost when it's sticking,
       * and the 'sticking' value is not set when within that range. */
      const ignoreForRange = (scrollHeight - clientHeight) <= 125

      if (scrollTop > 35 && !this.sticking && !ignoreForRange) {
        this.sticking = true
      } else if (scrollTop < 35 && this.sticking) {
        this.sticking = false
      }
    },
    toggleMobileNav (close = false) {
      this.setDisplayMobileMenu(!this.displayMobileMenu)
      let scrollPos = 0
      if (!close) {
        scrollPos = window.scrollY
        document.body.style.top = '-' + scrollPos + 'px'
        document.body.style.position = 'fixed'
        document.body.style.width = '100%'
      } else {
        document.body.style.position = ''
        document.body.style.top = ''
        document.body.style.width = ''
        window.scrollTo(window.scrollX, scrollPos)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .catch-hover-container {
    width: 100%;
    height: 50%;
  }

  .main-nav {
    position: sticky;
    top: 0;
    z-index: 200;
  }

  .app-header {
    position: sticky;
    top: 0;
    z-index: 200;
    background-color: $white;
    transition: box-shadow .5s;

    .content-constraint {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      height: 12em;
      transition: height .5s;
    }

    &.sticking {
      box-shadow: 0 0.5em 0.5em 0 rgba(0, 0, 0, 0.4);

      .content-constraint {
        justify-content: space-between;
        height: 7em;
      }

      .logo {
        margin: 0 2em;
        width: 13em;
        height: 4em;
      }

      .event-title-and-cart {
        display: flex;

        .event-title {
          display: flex;
          margin-right: 0;
          align-items: center;
          justify-content: space-between;
          flex-grow: 1;
          max-width: none;
          padding-right: 0 !important;
        }
      }

      .menu-and-logo-and-cart-and-account {
        align-items: center;
        margin-top: 0;

        .cart-and-account {
          align-items: center;
        }
      }
    }

    .menu-and-logo-and-cart-and-account {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
    }

    .menu-and-logo {
      display: flex;
      transition: width .5s;
      justify-content: space-around;
    }

    .mdi-menu {
      font-size: 3.5em;
      color: $grey;
      flex-shrink: 0;
    }

    .cart-and-account {
      transition: width .5s;
      display: flex !important;
      align-items: flex-end;
      margin: 0.5em 2em;

      .mdi {
        font-size: 2.2em;
        margin-bottom: -0.1em;
        margin-right: 0.2em;
      }

      .account {
        display: flex;
        align-items: flex-end;
        margin-right: 2em;
        white-space: nowrap;

        .welcome {
          font-weight: 300;
          font-size: 1.2em;
        }

        .my-account {
          font-weight: 700;
          font-size: 1.4em;
          text-transform: uppercase;
        }

        .account-dropdown-menu {
          min-width: 28em !important;
          background: $white;
          padding: 1.6em;
          border-collapse: collapse;

          .account-list-item {
            border-top: 1px solid $grey;
            border-bottom: 1px solid $grey;
            margin: -1px 0;

            span {
              font-size: 1.7em;
            }
          }
        }
      }

      .cart {
        white-space: nowrap;
        display: flex;
        align-items: flex-end;

        span.subtext {
          font-size: 1.4em;
          text-transform: uppercase;
          font-weight: 700;
        }

        .mdi {
          margin-bottom: 0.08em;
        }

        .cart-dropdown-menu {
          min-width: 48.8em !important;
          background: $white;

          .scrollable-content {
            padding: 3.2em 3.2em 1em 3.2em;
            max-height: 50vh;
            overflow-y: auto;

            .cart-dropdown-item-wrapper {
              padding-top: 7px;
              border-top: 1px solid $light-grey;
            }
          }

          .absolute-content {
            padding: 0 3.2em 3.2em 3.2em;

            .cart-empty {
              border-top: 1px solid $light-grey;
            }
          }
        }
      }
    }

    &.sticking .cart-and-account {
      .mdi {
        font-size: 3em;
      }

      .account, .cart span.subtext {
        /* display: none; */
      }
    }

    .logo {
     width: 20.5em;
      height: 7em;
      margin: 0.5em 0;
      flex-shrink: 0;
      background-image: url('../../static/bpg-logo-light.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }

    .event-title {
      max-width: 68.9em;
      font-size:4em;
    }

    .event-title-bar {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1150px) {
    .app-header .cart-and-account {
      align-items: center;

      .cart {
        .mdi {
          margin-bottom: 0;
        }
      }

      .mdi {
        font-size: 3em;
      }

      .welcome, .my-account, .cart span.subtext {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 1010px) {
    .app-header {
      font-size: .99vw;

      &.sticking {
        .content-constraint {
          align-items: center;
        }
      }
    }
  }

  @media only screen and (min-width: 771px) {
    .app-header {
      .mdi-menu {
        display: none;
      }

      &.sticking .content-constraint {
        padding: 0;
      }
    }
  }

  @media only screen and (max-width: 770px) {
    .app-header {
      font-size: 10px;

      .content-constraint, &.sticking .content-constraint {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
      }

      .menu-and-logo-and-cart-and-account {
        margin: 1em 0 !important;
        align-items: center;
      }

      .menu-and-logo {
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 8px;
      }

      &.sticking {
        .menu-and-logo {
          width: 13em;
          margin-bottom: 0;
        }
        .cart-and-account {
          width: 40px;
        }
      }

      .menu-and-logo-and-cart-and-account {
        flex-wrap: wrap;
        width: 100%;
      }

      &.sticking {
        .menu-and-logo-and-cart-and-account {
          flex-wrap: nowrap;

          &.focus-in-event-title  {
            .menu-and-logo,
            .cart-and-account,
            #cart-dropdown-anchor {
              width: 0;
              overflow: hidden;
              margin: 0 !important;
            }

          }
        }
      }

      .logo {
        width: 10em;
        height: 4em;
        margin: 0 2em;
      }

      &.sticking .logo {
        width: 10em;
        height: 7em;
        margin: 0 2em;
        background-image: url('../../static/bpg-logo-light.svg');
      }

      .cart-and-account .account {
        display: none;
      }
    }
  }
</style>
