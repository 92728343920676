export const CartStateEnum = Object.freeze({
  Draft: 0,
  SubmittedForCheckout: 1,
  Checkout: 2,
  SubmittedForOrder: 3,
  ReadyForOrder: 4
})

export const CartErrorTypeEnum = Object.freeze({
  PromotionExpired: 0,
  TotalItemCartPriceIncorrect: 1,
  TaxIncorrect: 2,
  ShippingCostIncorrect: 3,
  CartTotalIncorrect: 4,
  CartItemsChanged: 5,
  CheckoutTimeLimitExpired: 6,
  CartEmpty: 7,
  InvalidOrganization: 8
})

export const CartItemErrorTypeEnum = Object.freeze({
  PriceChanged: 0,
  QuantityNotAvailable: 1,
  ProductDoesNotExist: 2,
  ProductNotAvailable: 3
})
