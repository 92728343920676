<template>
  <v-app>
    <div class="default-layout">
      <the-tab-nav />
      <the-mobile-nav />
      <the-main-nav class="text-default" />
      <div class="app-body text-default">
        <notify-banner />
        <nuxt v-if="!$slots.default" />
        <slot />
      </div>
      <the-newsletter-sign-up />
      <the-footer />
      <optin-monster />
    </div>
  </v-app>
</template>

<script>
import TheMainNav from '@/components/layout/the-main-nav'
import TheTabNav from '@/components/layout/the-tab-nav'
import TheMobileNav from '@/components/layout/the-mobile-nav'
import NotifyBanner from '@/components/banners/notify-banner'
import TheNewsletterSignUp from '@/components/layout/the-newsletter-sign-up'
import OptinMonster from '@/components/layout/optin-monster'
import TheFooter from '@/components/layout/the-footer'

export default {
  name: 'Default',
  components: {
    TheMainNav,
    TheTabNav,
    TheMobileNav,
    NotifyBanner,
    TheNewsletterSignUp,
    TheFooter,
    OptinMonster
  },
  middleware: 'session-pipeline'
}
</script>
<style lang="scss" scoped>

  .default-layout {
    min-height: 100%;
    display: grid;
    grid-template-rows: auto auto auto 1fr auto auto;
    grid-template-columns: 100%;
  }

</style>
