import { render, staticRenderFns } from "./base-quantity-select.vue?vue&type=template&id=fe87efce&scoped=true&"
import script from "./base-quantity-select.vue?vue&type=script&lang=js&"
export * from "./base-quantity-select.vue?vue&type=script&lang=js&"
import style0 from "./base-quantity-select.vue?vue&type=style&index=0&id=fe87efce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe87efce",
  null
  
)

export default component.exports