<template>
  <div class="sub-nav-placeholder" @mouseleave="$emit('mouseleave', $event)">
    <div class="sub-nav" :class="{ 'collapsed': !category }">
      <transition name="slide-fade">
        <div v-if="category && category.data" class="mt-6 mb-10 px-8 d-flex justify-center">
          <div class="d-flex flex-column category-container">
            <h1 class="pb-2">
              {{ category.data.name }} <v-icon class="font-medium font-weight-thin">
mdi-greater-than
</v-icon>
            </h1>
            <div class="d-flex justify-start">
              <div class="link-container pt-2 fx-1">
                <div class="d-flex">
                  <div v-for="(column, i) in columns" :key="'column_' + i" class="link-column">
                    <span v-if="column.length" class="column-title">
                      {{ column[0].column_name }}
                    </span>
                    <div class="column-items">
                      <div v-for="(item, j) in column" :key="'item_' + j" @click="$emit('close')">
                      <a
                        v-if="item && item.item_url && item.item_url.url"
                        class="text-no-underline link clickable"
                        :href="item.item_url.url"
>
                        {{ item.item_name }}
                      </a>
                      <a
                        v-if="item && item.item_name && item.item_name.toLowerCase() !== 'see all' && !item.item_url.url"
                        v-a11y-clickable.link
                        class="link clickable"
                        @click="navigate(item)"
                        @touchstart="navigate(item)"
                      >
                        {{ item.item_name }}
                      </a>
                      <a
                        v-if="item && item.item_name && item.item_name.toLowerCase() === 'see all'"
                        v-a11y-clickable.link
                        class="see-all text-uppercase clickable font-small mt-5"
                        @click="navigate"
                        @touchstart="navigate"
                      >
                        See All >
                      </a>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <sub-nav-spotlight
                v-if="category.data.body && category.data.body.length && category.data.body[0].primary"
                :slice="category.data.body[0].primary"
              /> -->
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { getProductQuery } from '@/helpers/utilities'
// import SubNavSpotlight from '@/components/layout/sub-nav-spotlight'
import { PRODUCTS_TYPES } from '@/store/products'

export default {
  // components: { SubNavSpotlight },
  props: {
    category: {
      type: Object,
      default: null
    }
  },
  computed: {
    columns () {
      if (!this.category || !this.category.data || !this.category.data.navigation_columns) { return }
      /* Group the columns by column name */
      return this.category.data.navigation_columns.reduce((result, col) => {
        result[col.column_name] = result[col.column_name] || []
        result[col.column_name].push(col)
        return result
      }, {})
    }
  },
  methods: {
    navigate (item) {
      const query = getProductQuery(this.category, item)
      if (JSON.stringify(query) === JSON.stringify(this.$route.query)) {
        return
      }
      this.resetProductList()
      this.$router.push({ name: 'products', query })
    },
    resetProductList () {
      this.$store.dispatch(PRODUCTS_TYPES.actions.reset)
    }
  }
}
</script>
<style lang="scss" scoped>
  @import './scss/variables';

  .slide-fade-enter-active {
    transition: all .4s ease;
  }

  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(-10px);
    opacity: 0;
  }

  .sub-nav-placeholder {
    position: relative;

    .sub-nav {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 200;
      background-color: white;
      border-top: solid 1px $light-grey;
      border-bottom: solid 1px $light-grey;
      transition: height .3s ease-out;
      overflow: hidden;
      height: 420px;

      &.collapsed {
        height: 0;
        border: none;
      }

      .category-container {
        width: 960px;
      }

      h1 {
        font-size: $font-x-large;
        font-weight: $font-weight-extra !important;
      }

      .link-container {
        padding-right: 33px;
        // border-right: 1px solid $light-grey;

        .link-column {
          width: 25%;
        }
        .column-title {
          font-size: $font-medium;
          font-weight:bold;
          text-transform: uppercase;
          padding-bottom: 5px;
          border-bottom: 2px solid $baker-green;
        }
        .column-items {
          margin-top:10px;
        }
        h2 {
          font-size: $font-medium;
          text-transform: uppercase;
          padding-bottom: 10px;
        }

        .link {
          font-weight: $font-weight-thin;
          font-size: $font-small;
          line-height: $font-small * 2;
          color: $charcoal-grey;
        }

        .see-all {
          display: inline-block;
        }
      }

      .spotlight-container {
        padding-left: 78px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .link-container {
      padding-right: 0 !important;
    }
    .link-column {
      padding-right: 28px !important;
    }
    .spotlight-container {
      padding-left: 30px !important;
    }
  }
</style>
