import { AUTH_TYPES } from '@/store/auth'
import { SESSION_TYPES } from '@/store/session'
import { CART_TYPES } from '@/store/cart'
import { getItem } from '@/helpers/local-storage'
import isAuthenticated from '@/helpers/isAuthenticated'

export default function ({ store }) {
  if (!process.server) {
    const ignoreCheck = getItem('ignore_auth_check')
    const userData = getItem('user_data', true)
    if (isAuthenticated() && !ignoreCheck) {
      if (userData) {
        store.dispatch(AUTH_TYPES.actions.setAuthUser, userData)
      }
      store.dispatch(AUTH_TYPES.actions.trySetAccessToken, true)
    } else if (!isAuthenticated() && store.state.auth.authenticatedUser && store.state.auth.authenticatedUser.id) {
      store.dispatch(AUTH_TYPES.actions.clearSession, true)
    }
    store.dispatch(SESSION_TYPES.actions.updateLastActivity)

    store.dispatch(CART_TYPES.actions.fetchCart)
    // all pages except checkout use this pipeline
    // if leaving checkout, need to reset the cart state back to draft
    store.dispatch(CART_TYPES.actions.resetCartState)
  }
}
