<template>
  <div id="newsletterSignUp" class="newsletterSignUp">
    <div class="content-center">
      <div class="content-constraint d-flex flex-column justify-center">
        <div class="d-sm-flex justify-center pt-2 pb-6">
          <div id="dynamicsForm" data-form-block-id="4f3feccb-3eef-ed11-8849-0022482434c6" />
          <script src="https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007" />
          <div id="doqgnUxlLzVJD2iX8iExoZBwnBHvNeWuKNqnhEJaqVvk" />
          <script src="https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007" />
          <div
class="d365-mkt-config"
style="display:none"
data-website-id="oqgnUxlLzVJD2iX8iExoZBwnBHvNeWuKNqnhEJaqVvk"
            data-hostname="79c8d9a20a2b457ca15433516724077d.svc.dynamics.com"
/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NewsletterSignUp',
  components: {},
  data () {
    return {
      showDialog: false,
      valid: false,
      email: null,
      submitting: false,
      newsletterOptions: null
    }
  },
  mounted () {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          const button = document.querySelector('.lp-form-button')
          if (button) {
            button.classList.add('form-submit-button')
            button.style.backgroundColor = '#38668e'
            button.style.color = '#fff'
            button.style.borderRadius = '4px'
            button.style.border = 'none'
            button.style.padding = '5px 10px'
            button.style.fontSize = '1em'
            button.style.fontWeight = '700'
            button.style.textTransform = 'uppercase'
            button.style.letterSpacing = '.0892857143em'
            button.style.marginTop = '5px'
            button.style.marginBottom = '5px'
            button.style.cursor = 'pointer'
          }
          const input = document.querySelector('input[type="email"]')
          if (input) {
            input.classList.add('form-input')
            input.style.backgroundColor = '#fff'
            input.style.padding = '6px'
            input.style.marginTop = '5px'
            input.style.marginBottom = '5px'
          }
        }
      })
    })

    // Start observing the container
    const container = document.getElementById('dynamicsForm')

    // Observe the container and its descendants
    observer.observe(container, { childList: true, subtree: true })
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import './scss/variables';
.form-input {
    background:#fff !important;
    padding:6px;
    margin-top:5px;
    margin-bottom:5px;
  }

  .form-submit-button {
    background-color: #38668e!important;
    color: #fff;
    border-radius: 4px;
    border: none;
    padding: 5px 10px;
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .0892857143em;
    margin-top: 5px;
    margin-bottom: 5px;
    &:hover {
      background-color: #214a6f !important;
      color: #fff;
    }
}
.newsletterSignUp {
  background-color: $very-light-green;
  z-index: 1;
  font-size:14px;
  .content-constraint {
    padding: 18px 20px 18px 20px;
  }
}
  </style>
