import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c044c6ac = () => interopDefault(import('..\\pages\\account\\index.vue' /* webpackChunkName: "pages/account/index" */))
const _ebc1c7b4 = () => interopDefault(import('..\\pages\\bundles\\index.vue' /* webpackChunkName: "pages/bundles/index" */))
const _4246aff6 = () => interopDefault(import('..\\pages\\cart.vue' /* webpackChunkName: "pages/cart" */))
const _53524577 = () => interopDefault(import('..\\pages\\checkout\\index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _7e905cd7 = () => interopDefault(import('..\\pages\\contact\\index.vue' /* webpackChunkName: "pages/contact/index" */))
const _26e4e884 = () => interopDefault(import('..\\pages\\events\\index.vue' /* webpackChunkName: "pages/events/index" */))
const _56451cab = () => interopDefault(import('..\\pages\\gift-cards.vue' /* webpackChunkName: "pages/gift-cards" */))
const _08beb177 = () => interopDefault(import('..\\pages\\gift-cards\\index.vue' /* webpackChunkName: "pages/gift-cards/index" */))
const _07c7303c = () => interopDefault(import('..\\pages\\gift-cards\\check-balance.vue' /* webpackChunkName: "pages/gift-cards/check-balance" */))
const _4f250578 = () => interopDefault(import('..\\pages\\gift-cards\\buy\\_type\\index.vue' /* webpackChunkName: "pages/gift-cards/buy/_type/index" */))
const _c331213e = () => interopDefault(import('..\\pages\\health\\index.vue' /* webpackChunkName: "pages/health/index" */))
const _5645ae1a = () => interopDefault(import('..\\pages\\preview.vue' /* webpackChunkName: "pages/preview" */))
const _2391c37e = () => interopDefault(import('..\\pages\\preview-prismic.vue' /* webpackChunkName: "pages/preview-prismic" */))
const _70d8a299 = () => interopDefault(import('..\\pages\\products\\index.vue' /* webpackChunkName: "pages/products/index" */))
const _090219aa = () => interopDefault(import('..\\pages\\wishlist.vue' /* webpackChunkName: "pages/wishlist" */))
const _c2946270 = () => interopDefault(import('..\\pages\\account\\address-book\\index.vue' /* webpackChunkName: "pages/account/address-book/index" */))
const _df92291e = () => interopDefault(import('..\\pages\\account\\church-organization\\index.vue' /* webpackChunkName: "pages/account/church-organization/index" */))
const _e83de804 = () => interopDefault(import('..\\pages\\account\\information\\index.vue' /* webpackChunkName: "pages/account/information/index" */))
const _4f26e5b6 = () => interopDefault(import('..\\pages\\account\\orders\\index.vue' /* webpackChunkName: "pages/account/orders/index" */))
const _1611e8df = () => interopDefault(import('..\\pages\\account\\payment-methods\\index.vue' /* webpackChunkName: "pages/account/payment-methods/index" */))
const _8350203c = () => interopDefault(import('..\\pages\\account\\tax-exempt-certificates\\index.vue' /* webpackChunkName: "pages/account/tax-exempt-certificates/index" */))
const _3963ccdc = () => interopDefault(import('..\\pages\\auth\\callback.vue' /* webpackChunkName: "pages/auth/callback" */))
const _2e4ed75b = () => interopDefault(import('..\\pages\\auth\\guest.vue' /* webpackChunkName: "pages/auth/guest" */))
const _457114ab = () => interopDefault(import('..\\pages\\baker-first\\apply\\index.vue' /* webpackChunkName: "pages/baker-first/apply/index" */))
const _d913091a = () => interopDefault(import('..\\pages\\checkout\\processed.vue' /* webpackChunkName: "pages/checkout/processed" */))
const _4abd1e97 = () => interopDefault(import('..\\pages\\account\\address-book\\add.vue' /* webpackChunkName: "pages/account/address-book/add" */))
const _f8015d00 = () => interopDefault(import('..\\pages\\account\\church-organization\\add.vue' /* webpackChunkName: "pages/account/church-organization/add" */))
const _1b2a9f8e = () => interopDefault(import('..\\pages\\account\\information\\edit.vue' /* webpackChunkName: "pages/account/information/edit" */))
const _cc925b24 = () => interopDefault(import('..\\pages\\account\\payment-methods\\add.vue' /* webpackChunkName: "pages/account/payment-methods/add" */))
const _20e2d2ee = () => interopDefault(import('..\\pages\\account\\payment-methods\\add-gift-card.vue' /* webpackChunkName: "pages/account/payment-methods/add-gift-card" */))
const _3ec8be31 = () => interopDefault(import('..\\pages\\account\\tax-exempt-certificates\\add.vue' /* webpackChunkName: "pages/account/tax-exempt-certificates/add" */))
const _de84897e = () => interopDefault(import('..\\pages\\auth\\signup\\redirect.vue' /* webpackChunkName: "pages/auth/signup/redirect" */))
const _222c7907 = () => interopDefault(import('..\\pages\\kiosk\\store\\static\\1280x800\\_display.vue' /* webpackChunkName: "pages/kiosk/store/static/1280x800/_display" */))
const _f7c27f08 = () => interopDefault(import('..\\pages\\kiosk\\store\\static\\1280x800\\_uuid\\index.vue' /* webpackChunkName: "pages/kiosk/store/static/1280x800/_uuid/index" */))
const _2e5f2b43 = () => interopDefault(import('..\\pages\\kiosk\\cafe\\full\\_display.vue' /* webpackChunkName: "pages/kiosk/cafe/full/_display" */))
const _e804753a = () => interopDefault(import('..\\pages\\kiosk\\cafe\\panels\\_display.vue' /* webpackChunkName: "pages/kiosk/cafe/panels/_display" */))
const _091e7fee = () => interopDefault(import('..\\pages\\kiosk\\store\\static\\_display.vue' /* webpackChunkName: "pages/kiosk/store/static/_display" */))
const _04c29213 = () => interopDefault(import('..\\pages\\contact\\faq\\_uuid\\index.vue' /* webpackChunkName: "pages/contact/faq/_uuid/index" */))
const _6c352407 = () => interopDefault(import('..\\pages\\contact\\form\\_uuid\\index.vue' /* webpackChunkName: "pages/contact/form/_uuid/index" */))
const _bd662dea = () => interopDefault(import('..\\pages\\contact\\order\\_uuid\\index.vue' /* webpackChunkName: "pages/contact/order/_uuid/index" */))
const _14efd675 = () => interopDefault(import('..\\pages\\kiosk\\store\\_uuid\\index.vue' /* webpackChunkName: "pages/kiosk/store/_uuid/index" */))
const _43cc148c = () => interopDefault(import('..\\pages\\account\\address-book\\_id\\edit.vue' /* webpackChunkName: "pages/account/address-book/_id/edit" */))
const _c67d19fa = () => interopDefault(import('..\\pages\\account\\church-organization\\_uuid\\edit.vue' /* webpackChunkName: "pages/account/church-organization/_uuid/edit" */))
const _78fca8a4 = () => interopDefault(import('..\\pages\\account\\church-organization\\_uuid\\orders\\index.vue' /* webpackChunkName: "pages/account/church-organization/_uuid/orders/index" */))
const _7b36a952 = () => interopDefault(import('..\\pages\\account\\orders\\_number\\details.vue' /* webpackChunkName: "pages/account/orders/_number/details" */))
const _2dde0cfc = () => interopDefault(import('..\\pages\\account\\orders\\_number\\print.vue' /* webpackChunkName: "pages/account/orders/_number/print" */))
const _95328b10 = () => interopDefault(import('..\\pages\\account\\church-organization\\_uuid\\orders\\_number\\details.vue' /* webpackChunkName: "pages/account/church-organization/_uuid/orders/_number/details" */))
const _481506ad = () => interopDefault(import('..\\pages\\baker-events\\_event.vue' /* webpackChunkName: "pages/baker-events/_event" */))
const _3fcfb7ff = () => interopDefault(import('..\\pages\\baker-first\\_uuid\\index.vue' /* webpackChunkName: "pages/baker-first/_uuid/index" */))
const _78db30d4 = () => interopDefault(import('..\\pages\\bundles\\_uuid\\index.vue' /* webpackChunkName: "pages/bundles/_uuid/index" */))
const _1190716a = () => interopDefault(import('..\\pages\\featured\\_uuid\\index.vue' /* webpackChunkName: "pages/featured/_uuid/index" */))
const _497858a8 = () => interopDefault(import('..\\pages\\lookup\\_isbn13.vue' /* webpackChunkName: "pages/lookup/_isbn13" */))
const _3ed020ab = () => interopDefault(import('..\\pages\\pages\\_slug.vue' /* webpackChunkName: "pages/pages/_slug" */))
const _70b3d9fe = () => interopDefault(import('..\\pages\\products\\_uuid\\index.vue' /* webpackChunkName: "pages/products/_uuid/index" */))
const _7af73c86 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _c044c6ac,
    name: "account"
  }, {
    path: "/bundles",
    component: _ebc1c7b4,
    name: "bundles"
  }, {
    path: "/cart",
    component: _4246aff6,
    name: "cart"
  }, {
    path: "/checkout",
    component: _53524577,
    name: "checkout"
  }, {
    path: "/contact",
    component: _7e905cd7,
    name: "contact"
  }, {
    path: "/events",
    component: _26e4e884,
    name: "events"
  }, {
    path: "/gift-cards",
    component: _56451cab,
    children: [{
      path: "",
      component: _08beb177,
      name: "gift-cards"
    }, {
      path: "check-balance",
      component: _07c7303c,
      name: "gift-cards-check-balance"
    }, {
      path: "buy/:type",
      component: _4f250578,
      name: "gift-cards-buy-type"
    }]
  }, {
    path: "/health",
    component: _c331213e,
    name: "health"
  }, {
    path: "/preview",
    component: _5645ae1a,
    name: "preview"
  }, {
    path: "/preview-prismic",
    component: _2391c37e,
    name: "preview-prismic"
  }, {
    path: "/products",
    component: _70d8a299,
    name: "products"
  }, {
    path: "/wishlist",
    component: _090219aa,
    name: "wishlist"
  }, {
    path: "/account/address-book",
    component: _c2946270,
    name: "account-address-book"
  }, {
    path: "/account/church-organization",
    component: _df92291e,
    name: "account-church-organization"
  }, {
    path: "/account/information",
    component: _e83de804,
    name: "account-information"
  }, {
    path: "/account/orders",
    component: _4f26e5b6,
    name: "account-orders"
  }, {
    path: "/account/payment-methods",
    component: _1611e8df,
    name: "account-payment-methods"
  }, {
    path: "/account/tax-exempt-certificates",
    component: _8350203c,
    name: "account-tax-exempt-certificates"
  }, {
    path: "/auth/callback",
    component: _3963ccdc,
    name: "auth-callback"
  }, {
    path: "/auth/guest",
    component: _2e4ed75b,
    name: "auth-guest"
  }, {
    path: "/baker-first/apply",
    component: _457114ab,
    name: "baker-first-apply"
  }, {
    path: "/checkout/processed",
    component: _d913091a,
    name: "checkout-processed"
  }, {
    path: "/account/address-book/add",
    component: _4abd1e97,
    name: "account-address-book-add"
  }, {
    path: "/account/church-organization/add",
    component: _f8015d00,
    name: "account-church-organization-add"
  }, {
    path: "/account/information/edit",
    component: _1b2a9f8e,
    name: "account-information-edit"
  }, {
    path: "/account/payment-methods/add",
    component: _cc925b24,
    name: "account-payment-methods-add"
  }, {
    path: "/account/payment-methods/add-gift-card",
    component: _20e2d2ee,
    name: "account-payment-methods-add-gift-card"
  }, {
    path: "/account/tax-exempt-certificates/add",
    component: _3ec8be31,
    name: "account-tax-exempt-certificates-add"
  }, {
    path: "/auth/signup/redirect",
    component: _de84897e,
    name: "auth-signup-redirect"
  }, {
    path: "/kiosk/store/static/1280x800/:display?",
    component: _222c7907,
    name: "kiosk-store-static-1280x800-display"
  }, {
    path: "/kiosk/store/static/1280x800/:uuid",
    component: _f7c27f08,
    name: "kiosk-store-static-1280x800-uuid"
  }, {
    path: "/kiosk/cafe/full/:display?",
    component: _2e5f2b43,
    name: "kiosk-cafe-full-display"
  }, {
    path: "/kiosk/cafe/panels/:display?",
    component: _e804753a,
    name: "kiosk-cafe-panels-display"
  }, {
    path: "/kiosk/store/static/:display?",
    component: _091e7fee,
    name: "kiosk-store-static-display"
  }, {
    path: "/contact/faq/:uuid",
    component: _04c29213,
    name: "contact-faq-uuid"
  }, {
    path: "/contact/form/:uuid",
    component: _6c352407,
    name: "contact-form-uuid"
  }, {
    path: "/contact/order/:uuid",
    component: _bd662dea,
    name: "contact-order-uuid"
  }, {
    path: "/kiosk/store/:uuid",
    component: _14efd675,
    name: "kiosk-store-uuid"
  }, {
    path: "/account/address-book/:id?/edit",
    component: _43cc148c,
    name: "account-address-book-id-edit"
  }, {
    path: "/account/church-organization/:uuid?/edit",
    component: _c67d19fa,
    name: "account-church-organization-uuid-edit"
  }, {
    path: "/account/church-organization/:uuid?/orders",
    component: _78fca8a4,
    name: "account-church-organization-uuid-orders"
  }, {
    path: "/account/orders/:number/details",
    component: _7b36a952,
    name: "account-orders-number-details"
  }, {
    path: "/account/orders/:number/print",
    component: _2dde0cfc,
    name: "account-orders-number-print"
  }, {
    path: "/account/church-organization/:uuid?/orders/:number?/details",
    component: _95328b10,
    name: "account-church-organization-uuid-orders-number-details"
  }, {
    path: "/baker-events/:event?",
    component: _481506ad,
    name: "baker-events-event"
  }, {
    path: "/baker-first/:uuid",
    component: _3fcfb7ff,
    name: "baker-first-uuid"
  }, {
    path: "/bundles/:uuid",
    component: _78db30d4,
    name: "bundles-uuid"
  }, {
    path: "/featured/:uuid",
    component: _1190716a,
    name: "featured-uuid"
  }, {
    path: "/lookup/:isbn13?",
    component: _497858a8,
    name: "lookup-isbn13"
  }, {
    path: "/pages/:slug?",
    component: _3ed020ab,
    name: "pages-slug"
  }, {
    path: "/products/:uuid",
    component: _70b3d9fe,
    name: "products-uuid"
  }, {
    path: "/",
    component: _7af73c86,
    name: "index"
  }, {
    path: "/sell",
    component: _7af73c86,
    name: "custom-redirect",
    beforeEnter: function(to, from, next) {
          // Redirecting from /sell to /featured/used-books
          next('/featured/used-books');
        }
  }, {
    path: "/theholypost",
    component: _7af73c86,
    name: "custom-redirect",
    beforeEnter: function(to, from, next) {
          next('/featured/theholypost');
        }
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
