import * as moment from 'moment'
import * as cloneDeep from 'lodash.clonedeep'

export const fillArrayTo = (i, startFrom = 0, zeroBase = false) => {
  const diff = i - startFrom
  return new Array(diff)
    .fill(null)
    .map((v, i) => {
      const val = (!zeroBase) ? i + 1 : i
      return startFrom + val
    })
}

export const calculateDiscount = (price, msrp) => {
  return Math.round((1 - (price / msrp)) * 100)
}

export const formatDate = (date, format) => {
  return moment(date).format(format)
}

export const removeNamespace = (namespace, types) => {
  const typeKeys = Object.keys(types)
  return Object.values(types).reduce((typeObj, typeValue, j) => {
    const typeObjectKeys = Object.keys(typeValue)
    typeObj[typeKeys[j]] = Object.values(typeValue).reduce((obj, v, k) => {
      obj[typeObjectKeys[k]] = v.replace(namespace + '/', '')
      return obj
    }, {})
    return typeObj
  }, {})
}

export const setProps = (source, target, createIfNotExists = false) => {
  for (const prop in source) {
    if (source.hasOwnProperty(prop)) {
      if (target.hasOwnProperty(prop) || createIfNotExists) {
        target[prop] = cloneDeep(source[prop])
      }
    }
  }
}

export const objectToString = (object) => {
  let str = ''
  const props = []
  for (const prop in object) {
    if (object.hasOwnProperty(prop) && object[prop]) {
      props.push(prop)
    }
  }
  props.sort()
    .forEach((p) => {
      str += object[p].toString() + ' '
    })

  return str
}

export const reduxUpdate = (arr, updated) => {
  return arr.map((x) => {
    if (x.id === updated.id) {
      return updated
    }

    return x
  })
}

export const tempReduxCartUpdate = (arr, updated) => {
  return arr.map((i) => {
    if (i.product.id === updated.product.id) {
      return updated
    }
    return i
  })
}

export const defaultCheck = (arr, object, commit, setMutation) => {
  const currentDefault = arr.find(x => x.isDefault)
  if (currentDefault && currentDefault.id !== object.id && object.isDefault) {
    const clone = cloneDeep(currentDefault)
    clone.isDefault = false
    commit(setMutation, clone)
  }
}

export const setCreatedAndModified = (item, isNew) => {
  const dt = new Date()
  const dateFormatted = `${
    (dt.getMonth() + 1).toString().padStart(2, '0')}/${
    dt.getDate().toString().padStart(2, '0')}/${
    dt.getFullYear().toString().padStart(4, '0')} ${
    dt.getHours().toString().padStart(2, '0')}:${
    dt.getMinutes().toString().padStart(2, '0')}:${
    dt.getSeconds().toString().padStart(2, '0')}`
  item.createdAt = dateFormatted
  if (!isNew) {
    item.updatedAt = dateFormatted
  }
}

export const generateUUID = () => {
  let d = new Date().getTime()
  let d2 = (performance && performance.now && (performance.now() * 1000)) || 0
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16
    if (d > 0) {
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
  })
}

export const getProductQuery = (category, item) => {
  const params = {}
  if (category && category.data) {
    if (category.data.category_value) {
      params.category = category.data.category_value
    }
    if (category.data.used_only) {
      params.used = true
    }
    if (category.data.search_type !== 'Products') {
      params.type = category.data.search_type
    }
  }
  if (item && item.filter_type && item.filter_value) {
    params[item.filter_type] = item.filter_value
  }
  return params
}

export const extractChapterException = (err) => {
  if (err) {
    if (err.isChapterException) {
      return err
    }
    if (err.response && err.response.data && err.response.data.isChapterException) {
      return err.response.data
    }
  }
  return null
}
