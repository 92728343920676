<template>
  <v-app>
    <the-mobile-nav />
    <baker-events-nav />
    <div class="app-body">
      <nuxt />
    </div>
  </v-app>
</template>

<script>
import BakerEventsNav from '@/components/layout/baker-events-nav'
import TheMobileNav from '@/components/layout/the-mobile-nav'

export default {
  name: 'Events',
  components: { BakerEventsNav, TheMobileNav }
}
</script>

<style scoped>

</style>
