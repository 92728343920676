import { SESSION_TYPES } from '@/store/session'
import { AUTH_TYPES } from '@/store/auth'
import { CART_TYPES } from '@/store/cart'
import { WISHLISTS_TYPES } from '@/store/wishlists'
// import { getItem, getCookie } from '@/helpers/local-storage'
import { getItem } from '@/helpers/local-storage'
import isAuthenticated from '@/helpers/isAuthenticated'

export default async ({ store, error }) => {
  try {
    await store.dispatch(SESSION_TYPES.actions.getBrowserSession)
    // const userData = getItem('user_data', true)
    const ignoreCheck = getItem('ignore_auth_check')

    if (isAuthenticated() && !ignoreCheck) {
      await store.dispatch(AUTH_TYPES.actions.trySetAccessToken, true)
    } else if (!isAuthenticated() && store.state.auth.authenticatedUser && store.state.auth.authenticatedUser.id) {
      store.dispatch(AUTH_TYPES.actions.clearSession, true)
    }
    await store.dispatch(CART_TYPES.actions.fetchCart)
    if (isAuthenticated()) {
      // don't need to wait for wishlists to load, allow it to load async
      if (store.state.auth.authenticatedUser && store.state.auth.authenticatedUser.id) {
        store.dispatch(WISHLISTS_TYPES.actions.fetchWishLists)
      }
    }
  } catch (e) {
    error(e)
  }
}
