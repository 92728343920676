import Vue from 'vue'
import isAuthenticated from '@/helpers/isAuthenticated'

Vue.directive('sensitive', {
  bind (el) {
    el.removeAttribute('name')
  }
})

Vue.directive('click-outside', {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        if (binding.expression) {
          vnode.context[binding.expression](event)
        }
      }
    }
    if (document && document.body) {
      document.body.addEventListener('click', el.clickOutsideEvent)
    }
  },
  unbind (el) {
    if (document && document.body) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    }
  }
})

Vue.directive('hide-if-not-authenticated', {
  bind (el) {
    if (!isAuthenticated()) {
      el.style.display = 'none'
    }
  }
})

/** For accessibility. Makes any element a fully accessible button.
 * If you place a @click listener on an html element that is not interactable,
 * (eg. on a div instead of a button) use this directive.
 * @param link add link as a binding (v-a11y-clickable.link) to make this function
 * as a link instead of a button.
 */
Vue.directive('a11y-clickable', {
  bind (el, binding) {
    console.log('binding', binding, binding.modifiers.link)
    el.setAttribute('tabindex', 0)
    el.setAttribute('role', binding.modifiers.link ? 'link' : 'button')
    el.classList.add('a11y-focus')

    // buttons should not scroll page on space bar
    el.addEventListener('keydown', (event) => {
      if (event.code === 'Space' && !binding.modifiers.link) {
        event.preventDefault()
      }
    })
    el.addEventListener('keyup', (event) => {
      if ((event.code === 'Space' && !binding.modifiers.link) || event.code === 'Enter') {
        el.click()
      }
    })
  }
})
