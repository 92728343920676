import { removeNamespace, extractChapterException } from '@/helpers/utilities'
import { CART_TYPES } from '@/store/cart'
import { CHECKOUT_TYPES } from '@/store/checkout'
import { CheckoutSteps } from '@/helpers/enums/checkout-enums'

export const ORDER_TYPES = {
  getters: {
    mostRecentOrder: 'orders/mostRecentOrders'
  },
  mutations: {
    SET_LOADING: 'orders/SET_LOADING',
    SET_ORDERS: 'orders/SET_ORDERS',
    SET_TOTAL: 'orders/SET_TOTAL',
    SET_PAGE: 'orders/SET_PAGE',
    ADD_ORDER: 'orders/ADD_ORDER',
    SET_SAVING: 'orders/SET_SAVING',
    SET_NUMBER_OF_TIME_CART_ORDER_HAS_BEEN_PLACED: 'orders/SET_NUMBER_OF_TIME_CART_ORDER_HAS_BEEN_PLACED'
  },
  actions: {
    placeOrder: 'orders/placeOrder',
    fetchOrders: 'orders/fetchOrders',
    fetchMostRecentOrder: 'orders/fetchMostRecentOrder',
    loadMore: 'orders/loadMore',
    resetCart: 'orders/resetCart'
  }
}

const _types = removeNamespace('orders', ORDER_TYPES)

const state = () => ({
  loading: false,
  orders: [],
  total: 0,
  page: 0,
  pageSize: 5,
  saving: false,
  numberOfTimesCartOrderHasBeenPlaced: 0
})

const getters = {
  [_types.getters.mostRecentOrder] (state) {
    if (state.orders.length > 0) {
      return state.orders[0]
    }
    return null
  }
}

const mutations = {
  [_types.mutations.SET_LOADING] (state, bool) {
    state.loading = bool
  },
  [_types.mutations.SET_ORDERS] (state, orders) {
    state.orders = orders
  },
  [_types.mutations.SET_PAGE] (state, page) {
    state.page = page
  },
  [_types.mutations.SET_TOTAL] (state, total) {
    state.total = total
  },
  [_types.mutations.ADD_ORDER] (state, order) {
    state.orders = [
      order,
      ...state.orders
    ]
  },
  [_types.mutations.SET_SAVING] (state, bool) {
    state.saving = bool
  },
  [_types.mutations.SET_NUMBER_OF_TIME_CART_ORDER_HAS_BEEN_PLACED] (state, count) {
    state.numberOfTimesCartOrderHasBeenPlaced = count
  }
}

const actions = {
  [_types.actions.fetchMostRecentOrder] ({ commit }) {
    commit(_types.mutations.SET_LOADING, true)
    return this.$axios.get('store/orders', {
      params: {
        skipCount: 0,
        maxResultCount: 1
      }
    })
      .then((res) => {
        commit(_types.mutations.SET_ORDERS, res.data.items)
      })
      .finally(() => commit(_types.mutations.SET_LOADING, false))
  },
  [_types.actions.fetchOrders] ({ state, commit }, organizationId) {
    commit(_types.mutations.SET_LOADING, true)
    commit(_types.mutations.SET_PAGE, 0)
    return this.$axios.get('store/orders', {
      params: {
        organizationId,
        skipCount: state.page * state.pageSize,
        maxResultCount: state.pageSize
      }
    })
      .then((res) => {
        commit(_types.mutations.SET_ORDERS, res.data.items)
        commit(_types.mutations.SET_TOTAL, res.data.totalCount)
      })
      .finally(() => commit(_types.mutations.SET_LOADING, false))
  },
  [_types.actions.loadMore] ({ state, commit }, organizationId) {
    commit(_types.mutations.SET_LOADING, true)
    commit(_types.mutations.SET_PAGE, state.page + 1)
    return this.$axios.get('store/orders', {
      params: {
        organizationId,
        skipCount: state.page * state.pageSize,
        maxResultCount: state.pageSize
      }
    })
      .then((res) => {
        commit(_types.mutations.SET_ORDERS, [...state.orders, ...res.data.items])
        commit(_types.mutations.SET_TOTAL, res.data.totalCount)
      })
      .finally(() => commit(_types.mutations.SET_LOADING, false))
  },
  [_types.actions.placeOrder] ({ state, commit, dispatch }) {
    if (state.numberOfTimesCartOrderHasBeenPlaced === 0) {
      commit(_types.mutations.SET_SAVING, true)
      commit(_types.mutations.SET_NUMBER_OF_TIME_CART_ORDER_HAS_BEEN_PLACED, state.numberOfTimesCartOrderHasBeenPlaced + 1)
      return dispatch(CART_TYPES.actions.submitCartForOrder, state.numberOfTimesCartOrderHasBeenPlaced, { root: true })
        .then((order) => {
          commit(_types.mutations.ADD_ORDER, order)
          this.$router.push('/checkout/processed')
        })
        .catch((err) => {
          const exception = extractChapterException(err)
          commit(_types.mutations.SET_SAVING, false)
          // reset the number of times the cart order has been placed back to zero if the cart order failed to submit
          commit(_types.mutations.SET_NUMBER_OF_TIME_CART_ORDER_HAS_BEEN_PLACED, 0)
          if (exception) {
            if (exception.isPaymentException) {
              dispatch(CHECKOUT_TYPES.actions.setPaymentError,
                exception.isUserFriendly ? exception.message : 'Encountered an error processing the payment.',
                { root: true })
              dispatch(CHECKOUT_TYPES.actions.setProcessStep, CheckoutSteps.Payment, { root: true })
            }
            throw exception
          }
          throw err
        })
    }
  },
  [_types.actions.resetCart] ({ commit }, status) {
    commit(_types.mutations.SET_SAVING, status)
    commit(_types.mutations.SET_NUMBER_OF_TIME_CART_ORDER_HAS_BEEN_PLACED, 0)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
