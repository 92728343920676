<template>
  <div class="account-side-nav-wrapper">
    <div class="mt-8 mb-3">
      <h1>
        Hi, {{ (isAuthenticated) ? authenticatedUser.userProfile.firstName : 'Guest' }}!
      </h1>
    </div>
    <v-list class="account-nav-list">
      <nuxt-link
        to="/account"
        tag="v-list-item"
        class="account-nav-item"
        exact
      >
        <span class="font-large font-weight-bold">Account Overview</span>
      </nuxt-link>
      <nuxt-link
        to="/account/orders"
        tag="v-list-item"
        class="account-nav-item"
      >
        <span class="font-large font-weight-bold">Order History</span>
      </nuxt-link>
      <nuxt-link
        to="/account/information"
        tag="v-list-item"
        class="account-nav-item"
      >
        <span class="font-large font-weight-bold">Account Information</span>
      </nuxt-link>
      <nuxt-link
        to="/account/payment-methods"
        tag="v-list-item"
        class="account-nav-item"
      >
        <span class="font-large font-weight-bold">Payment Methods</span>
      </nuxt-link>
      <nuxt-link
        to="/account/address-book"
        tag="v-list-item"
        class="account-nav-item"
      >
        <span class="font-large font-weight-bold">Address Book</span>
      </nuxt-link>
      <nuxt-link
        to="/account/church-organization"
        tag="v-list-item"
        class="account-nav-item"
      >
        <span class="font-large font-weight-bold">Church Organization</span>
      </nuxt-link>
      <nuxt-link
        to="/account/tax-exempt-certificates"
        tag="v-list-item"
        class="account-nav-item"
      >
        <span class="font-large font-weight-bold">Exemption Information</span>
      </nuxt-link>
      <nuxt-link
        to="/wishlist"
        tag="v-list-item"
        class="account-nav-item"
      >
        <span class="font-large font-weight-bold">My Wishlist</span>
      </nuxt-link>
      <!--      <nuxt-link-->
      <!--        to="/account/membership"-->
      <!--        tag="v-list-item"-->
      <!--        class="account-nav-item"-->
      <!--      >-->
      <!--        <span class="font-large font-weight-bold">Membership Information</span>-->
      <!--      </nuxt-link>-->
      <!--      <nuxt-link-->
      <!--        to="/account/events"-->
      <!--        tag="v-list-item"-->
      <!--        class="account-nav-item"-->
      <!--      >-->
      <!--        <span class="font-large font-weight-bold">My Events</span>-->
      <!--      </nuxt-link>-->
    </v-list>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { AUTH_TYPES } from '@/store/auth'

export default {
  name: 'AccountSideNav',
  computed: {
    ...mapState({
      authenticatedUser: state => state.auth.authenticatedUser
    }),
    ...mapGetters({
      isAuthenticated: AUTH_TYPES.getters.isAuthenticated
    })
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';

  .nuxt-link-active:hover, a:hover {
    text-decoration: none;
  }

  h1 {
    font-size: 30px;
  }

  .account-side-nav-wrapper {
    flex: 0 1 auto;
    border-right: 2px solid $grey;
    padding: 0;
    min-width: 252px;

    .account-nav-list {
      background: none;
      padding-right: 10px;

      .account-nav-item {
        background: none;
        border-bottom: 1px solid $grey;

        &.v-list-item:hover:before {
          opacity: 0;
        }

        span {
          color: $dark-blue;
        }

        &.nuxt-link-active {
          background-color: $dark-blue;
          margin-top: -1px;
          border-top: 1px solid $grey;
          border-bottom: 1px solid $grey;
          margin-right: -10px;

          span {
            color: $white;
          }
        }
      }
    }
  }
</style>
