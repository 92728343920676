export const OrderStatusIdentifier = Object.freeze({
  Accepted: 1,
  Processing: 2,
  Fulfilled: 3,
  ReadyToShip: 4,
  ReadyForPickup: 5,
  Complete: 6,
  CancelRequested: 7,
  Cancelled: 8
})

export const OrderAlertTypeEnum = Object.freeze({
  UnknownError: 0,
  CancelRequested: 1,
  PaymentFailure: 2,
  TaxExemptionPending: 3,
  TaxExemptionCalculationError: 4,
  TaxCalculationError: 5,
  FulfillmentFailure: 6
})
