import { removeNamespace } from '@/helpers/utilities'

export const SELECTS_TYPES = {
  getters: {
    stateById: 'selects/stateById',
    countryById: 'selects/countrById'
  },
  mutations: {
    SET_STATES: 'selects/SET_STATES',
    SET_COUNTRIES: 'selects/SET_COUNTRIES'
  },
  actions: {
    fetchStates: 'selects/fetchStates',
    fetchCountries: 'selects/fetchCountries'
  }
}

const _types = removeNamespace('selects', SELECTS_TYPES)

const state = () => ({
  states: [],
  countries: []
})

const getters = {
  [_types.getters.stateById]: state => (id) => {
    return state.states.filter(x => x.id === id)
  },
  [_types.getters.countryById]: state => (id) => {
    return state.countries.filter(x => x.id === id)
  }
}

const mutations = {
  [_types.mutations.SET_STATES] (state, states) {
    state.states = states
  },
  [_types.mutations.SET_COUNTRIES] (state, countries) {
    state.countries = countries
  }
}

const actions = {
  async [_types.actions.fetchStates] ({ state, commit, error }) {
    if (state.states && state.states.length) {
      return
    }
    try {
      const res = await this.$axios.get('store/states')
      commit(_types.mutations.SET_STATES, res.data)
    } catch (e) {
      error(e)
    }
  },
  async [_types.actions.fetchCountries] ({ state, commit, error }) {
    if (state.countries && state.countries.length) {
      return
    }
    try {
      const res = await this.$axios.get('store/countries')
      commit(_types.mutations.SET_COUNTRIES, res.data)
    } catch (e) {
      error(e)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
