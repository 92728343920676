<template>
  <div :class="'social-follow-icons icon-theme--' + theme">
    <div v-if="title.length > 0" class="social-follow-title">
      {{ title }}
    </div>
    <div class="social-follow-links">
      <div v-for="socialLink in socialLinks" :key="socialLink.icon_name">
        <a v-if="socialLink.link" :href="socialLink.link.url" :target="socialLink.link.target" :aria-label="socialLink.icon_name">
          <i class="mdi" :class="'mdi-' + socialLink.icon_name" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SocialShareIcons',
  props: {
    title: {
      type: String,
      default: 'Follow Us'
    },
    theme: {
      type: String,
      default: 'default'
    },
    socialLinks: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';

  .social-follow-icons {
    .social-follow-title {
      font-size: $font-medium;
      font-weight: $font-weight-bold;
      margin-bottom: 0.6em;
    }
    .social-follow-links {
      display: flex;
      a {
        text-decoration: none;
      }
      .mdi {
        font-size: 24px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        margin-right: 0.3em;
      }
    }
  }

  .icon-theme--default {
    .social-follow-title {
      color: $white;
    }
    .mdi {
      background-color: $white;
      color: $charcoal-grey;
    }
  }
  .icon-theme--dark-blue {
    .social-follow-title {
      color: $charcoal-grey;
    }
    .mdi {
      background-color: $dark-blue;
      color: $white;
    }
  }
</style>
