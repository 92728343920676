<template>
  <div>
    <client-only>
      <div v-if="banner && banner.banner_message" class="notify-text-banner">
        <span>{{ banner.banner_message }}</span>
      </div>
      <div
        v-if="banner && banner.banner_click_url && banner.banner_click_url.url && banner.banner_message == null && (banner.mobile_image || banner.desktop_image)"
        class="notify-banner"
      >
        <a
          :href="banner.banner_click_url.url"
          class="text-no-underline clickable"
        >
        <div
          v-if="$breakpoint.is.xs && banner.mobile_image && banner.mobile_image.url && banner.mobile_image.url.length > 0"
          class="banner-image"
        >
          <img :src="banner.mobile_image.url | fullImagePath('S')" :alt="'Baker Book House Banner Image'" class="banner-image">
        </div>
        <div
          v-if="$breakpoint.is.smAndUp && banner.desktop_image && banner.desktop_image.url && banner.desktop_image.url.length > 0"
          class="banner-image"
        >
          <img :src="banner.desktop_image.url | fullImagePath('S')" :alt="'Baker Book House Banner Image'" class="banner-image">
        </div>
      </a>
      </div>
      <div
        v-else-if="banner && banner.banner_message == null && (banner.mobile_image || banner.desktop_image)"
        class="notify-banner"
      >
        <div
          v-if="$breakpoint.is.xs && banner.mobile_image && banner.mobile_image.url && banner.mobile_image.url.length > 0"
          class="banner-image"
        >
        <img :src="banner.mobile_image.url | fullImagePath('S')" :alt="'Baker Book House Banner Image'" class="banner-image">
        </div>
        <div
          v-if="$breakpoint.is.smAndUp && banner.desktop_image && banner.desktop_image.url && banner.desktop_image.url.length > 0"
          class="banner-image"
        >
        <img :src="banner.desktop_image.url | fullImagePath('S')" :alt="'Baker Book House Banner Image'" class="banner-image">
        </div>
      </div>
    </client-only>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'NotifyBanner',
  computed: {
    ...mapState({
      banner: state => state.layout.notifyBanner
    })
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';
  .notify-text-banner {
    background-color: $off-white;
    height: 4.8em;
    padding-bottom:8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    span {
      font-size: 1.2em;
      font-weight: $font-weight-bold;
    }
  }
  .notify-banner {
    // background-color: $off-white;
    // height: 4.8em;
    padding-bottom:8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    .banner-image {
      width:100%;
    }
    span {
      font-size: 1.2em;
      font-weight: $font-weight-bold;
    }
  }
  .banner-image {
    width:100%;
  }
</style>
