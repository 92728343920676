import { removeNamespace, setCreatedAndModified } from '@/helpers/utilities'
import { AUTH_TYPES } from '@/store/auth'

export const WISHLISTS_TYPES = {
  mutations: {
    SET_WISHLISTS: 'wishlists/SET_WISHLISTS',
    ADD_PRODUCT_TO_WISHLIST: 'wishlists/ADD_PRODUCT_TO_WISHLIST',
    REMOVE_PRODUCT_FROM_WISHLIST: 'wishlists/REMOVE_PRODUCT_FROM_WISHLIST'
  },
  actions: {
    fetchWishLists: 'wishlists/fetchWishLists',
    setWishLists: 'wishlists/setWishLists',
    addProductToWishList: 'wishlists/addProductToWishList',
    removeProductFromWishList: 'wishlists/removeProductFromWishList',
    saveWishLists: 'wishlists/saveWishLists'
  }
}

const _types = removeNamespace('wishlists', WISHLISTS_TYPES)

const fetchWishLists = ($axios, identifier) => {
  return $axios.get(`store/wishlists/${identifier}`)
}

const setWishListsFromResponse = (commit, data, rootState) => {
  commit(_types.mutations.SET_WISHLISTS, data.lists)
}

const state = () => ({
  lists: [{
    name: 'My WishList',
    private: true,
    items: []
  }]
})

const getters = {}

const mutations = {
  [_types.mutations.SET_WISHLISTS] (state, lists) {
    state.lists = lists
  },
  [_types.mutations.ADD_PRODUCT_TO_WISHLIST] (state, product) {
    const existing = state.lists[0].items.find(i => i.product.id === product.id)
    if (existing) {
      return
    }
    const item = {
      product,
      quantityToOrder: 1
    }
    setCreatedAndModified(item, true)
    state.lists[0].items.push(item)
  },
  [_types.mutations.REMOVE_PRODUCT_FROM_WISHLIST] (state, product) {
    state.lists[0].items = state.lists[0].items.filter(i => i.product.id !== product.id)
  }
}

const actions = {
  [_types.actions.fetchWishLists] ({ rootGetters, dispatch }, payload = null) {
    const identifier = (payload) ? payload.userDataId : rootGetters[AUTH_TYPES.getters.userDataId]
    return fetchWishLists(this.$axios, identifier)
      .then((res) => {
        if (res.status === 200 && (!payload || payload.setWishLists)) {
          dispatch(_types.actions.setWishLists, res.data)
        }
      })
  },
  [_types.actions.setWishLists] ({ commit, rootState }, payload) {
    setWishListsFromResponse(commit, payload, rootState)
  },
  [_types.actions.addProductToWishList] ({ commit, dispatch }, payload) {
    commit(_types.mutations.ADD_PRODUCT_TO_WISHLIST, payload)
    return dispatch(_types.actions.saveWishLists)
  },
  [_types.actions.removeProductFromWishList] ({ commit, dispatch }, payload) {
    commit(_types.mutations.REMOVE_PRODUCT_FROM_WISHLIST, payload)
    return dispatch(_types.actions.saveWishLists)
  },
  [_types.actions.saveWishLists] ({ state, commit, dispatch, rootState, rootGetters }, payload = null) {
    const identifier = (payload) ? payload.userDataId : rootGetters[AUTH_TYPES.getters.userDataId]
    const model = {
      lists: (payload) ? payload.lists : state.lists
    }
    return this.$axios.post(`store/wishlists/${identifier}`, { ...model })
      .then((res) => {
        if (res.status === 200 && (!payload || payload.setWishLists)) {
          dispatch(_types.actions.setWishLists, res.data)
        }
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
