import LogRocket from 'logrocket'
import { InteractionRequiredAuthError } from 'msal'
import { removeNamespace } from '@/helpers/utilities'
import {
  azureADSignIn,
  azureADSignOut,
  acquireTokenSilent
} from '@/helpers/msal'
import {
  clearCookie,
  clearSessionItem,
  storeItem,
  clearItem,
  // getSessionItem,
  getCookie,
  setCookie,
  storeSessionItem
} from '@/helpers/local-storage'
import { SESSION_TYPES } from '@/store/session'
import { ORGANIZATIONS_TYPES } from '@/store/organizations'

const sessionCookie = getCookie('session_data', true)
// const sessionCookie = getSessionItem('session_data', true)

export const AUTH_TYPES = {
  getters: {
    isAuthenticated: 'auth/isAuthenticated',
    isGuest: 'auth/isGuest',
    userId: 'auth/userId',
    userDataId: 'auth/userDataId'
  },
  mutations: {
    SET_LOADING: 'auth/SET_LOADING',
    SET_AUTH_USER: 'auth/SET_AUTH_USER',
    SET_ACCESS_TOKEN: 'auth/SET_ACCESS_TOKEN'
  },
  actions: {
    clearSession: 'auth/clearSession',
    b2cSignIn: 'auth/b2cSignIn',
    b2cSignUp: 'auth/b2cSignUp',
    b2cCheckoutSignIn: 'auth/b2cCheckoutSignIn',
    b2cSignOut: 'auth/b2cSignOut',
    trySetAccessToken: 'auth/trySetAccessToken',
    setAuthUser: 'auth/setAuthUser',
    fetchAuthUser: 'auth/fetchAuthUser',
    updateAuthUser: 'auth/updateAuthUser'
  }
}

const _types = removeNamespace('auth', AUTH_TYPES)

const updateUserData = (dispatch, data) => {
  dispatch(_types.actions.setAuthUser, data)
  storeItem('user_data', data, true)
}

const state = () => ({
  loading: false,
  authenticatedUser: null,
  accessToken: null
})

const getters = {
  [_types.getters.isAuthenticated] (state) {
    return !!state.accessToken
  },
  [_types.getters.isGuest] (state) {
    return !state.accessToken || !state.authenticatedUser || !state.authenticatedUser.objectId
  },
  [_types.getters.userId] (state, getters) {
    if (getters.isAuthenticated) {
      return state.authenticatedUser && state.authenticatedUser.id
    }
  },
  [_types.getters.userDataId] (state, getters, rootState) {
    if (getters.isAuthenticated) {
      return state.authenticatedUser && state.authenticatedUser.userDataId
    } else {
      return rootState.session.current ? rootState.session.current.sessionGuid : null
    }
  }
}

const mutations = {
  [_types.mutations.SET_LOADING] (state, bool) {
    state.loading = bool
  },
  [_types.mutations.SET_AUTH_USER] (state, user) {
    this.$logger.debug(`Set auth user: ${JSON.stringify(user)}`)
    state.authenticatedUser = user
  },
  [_types.mutations.SET_ACCESS_TOKEN] (state, token) {
    this.$logger.debug(`Set access token: ${JSON.stringify(token)}`)
    state.accessToken = token
  }
}

const actions = {
  [_types.actions.clearSession] ({ dispatch }, b2cSignOut = true) {
    clearCookie('refresh_valid_till')
    // clearItem('user_data')
    /// ///clearSessionItem('user_data')
    clearItem('user_data')
    clearSessionItem('redirect_on_callback')
    // clearSessionItem('session_data')
    clearCookie('refresh_valid_till')
    this.$appInsights.clearAuthenticatedUserContext()
    if (b2cSignOut) {
      return dispatch(_types.actions.b2cSignOut)
    }
  },
  [_types.actions.b2cSignIn] () {
    const redirectFromQuery = this.$router.currentRoute.query.redirect
    if (redirectFromQuery) {
      storeSessionItem('redirect_on_callback', redirectFromQuery)
    } else {
      storeSessionItem('redirect_on_callback', this.$router.currentRoute.fullPath)
    }
    azureADSignIn(null)
  },
  [_types.actions.b2cSignUp] () {
    azureADSignIn(process.env.B2C_SIGN_UP)
  },
  [_types.actions.b2cCheckoutSignIn] () {
    storeSessionItem('redirect_on_callback', '/checkout')
    azureADSignIn(process.env.B2C_AUTHORITY_CHECKOUT)
  },
  [_types.actions.b2cSignOut] () {
    azureADSignOut()
  },
  async [_types.actions.trySetAccessToken] ({ commit, dispatch }, fetchUser = true) {
    try {
      await acquireTokenSilent()
        .then(
          (authResponse) => {
            // set token
            commit(_types.mutations.SET_ACCESS_TOKEN, authResponse.accessToken || '')
            this.$axios.setToken(authResponse.accessToken, 'Bearer')
            // set refresh lifetime
            const validUntil = Date.now() + (0.042 * 24 * 60 * 60 * 1000) // 1 hour refresh token lifetime
            setCookie('refresh_valid_till', validUntil, 0.0416666667, true)
            // fetch user
            if (!authResponse.accessToken) {
              fetchUser = false
              dispatch(_types.actions.clearSession, false)
            }

            return (fetchUser)
              ? dispatch(_types.actions.fetchAuthUser)
              : new Promise(resolve => resolve(true))
          },
          (error) => {
            this.$logger.debug(`Error acquiring token silently: ${error}`)
            dispatch(_types.actions.clearSession, false)
            // If user is trying to access an account page (besides order details) redirect them to the login screen
            // If they're trying to access any other page, do nothing - the session has already been cleared
            const redirectPath = this.$router.currentRoute.query.redirect
            const regex = /account\/orders\/[\d-]+\/details/g
            if ((error instanceof InteractionRequiredAuthError || error.errorCode === 'user_login_error') &&
              redirectPath && redirectPath.includes('account') && !redirectPath.match(regex)) {
              dispatch(_types.actions.b2cSignIn)
            }
          }
        )
    } catch (ex) {
      console.log(ex)
    }
  },
  [_types.actions.setAuthUser] ({ commit }, payload) {
    commit(_types.mutations.SET_AUTH_USER, payload)
  },
  [_types.actions.fetchAuthUser] ({ commit, dispatch }) {
    commit(_types.mutations.SET_LOADING, true)
    return this.$axios.get('store/users/current-user')
      .then((res) => {
        const user = res.data
        updateUserData(dispatch, user)
        if (user && user.email) {
          this.$appInsights.setAuthenticatedUserContext(user.email)
          if (process.env.LOGROCKET_KEY) {
            LogRocket.identify(user.email, {
              name: user.userProfile ? `${user.userProfile.firstName} ${user.userProfile.lastName}` : user.email,
              email: user.email
            })
          }
        }
        if (sessionCookie && !sessionCookie.userId) {
          return dispatch(SESSION_TYPES.actions.associateUserWithSession, sessionCookie.id, { root: true })
        }
      })
      .then(async () => {
        await dispatch(ORGANIZATIONS_TYPES.actions.fetchOrganizations, null, { root: true })
      })
      .finally(() => commit(_types.mutations.SET_LOADING, false))
  },
  [_types.actions.updateAuthUser] ({ commit, dispatch }, payload) {
    commit(_types.mutations.SET_LOADING, true)
    return this.$axios.put(`store/users/${payload.id}`, { ...payload })
      .then((res) => {
        updateUserData(dispatch, res.data)
      }).finally(() => commit(_types.mutations.SET_LOADING, false))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
