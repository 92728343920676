<template>
  <v-textarea
    v-model="input"
    v-bind="$attrs"
    class="base-textarea v-text-no-shadow v-input-restrict-height"
    :class="[
      { 'mobile': $vuetify.breakpoint.smAndDown },
      { 'text-uppercase': uppercase }
    ]"
    :rules="validationRules"
    :label="_label"
    :outlined="type === 'outlined'"
    required
    @input="$emit('input', input)"
  />
</template>

<script>
export default {
  name: 'BaseTextarea',
  props: {
    label: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'outlined'
    },
    border: {
      type: Boolean,
      default: true
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    required: {
      type: Boolean,
      default: false
    },
    uppercase: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      input: this.$attrs.value
    }
  },
  computed: {
    _label () {
      return this.required ? this.label + '*' : this.label
    },
    validationRules () {
      const rules = [...this.rules]
      if (this.required) {
        rules.push(value => !!value || 'Required')
      }
      return rules
    }
  },
  watch: {
    $attrs () {
      if (this.input !== this.$attrs.value) {
        this.input = this.$attrs.value
      }
    }
  }
}
</script>
