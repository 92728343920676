<template>
  <div class="divider" :class="color" />
</template>

<script>
export default {
  name: "BaseDivider",
  props: {
    color: {
      type: String,
      default: "light-grey"
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./scss/variables";

.divider {
  width: 100%;
  height: 1px;
}
</style>
