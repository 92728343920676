<template>
  <v-rating
    v-bind="$attrs"
    :ripple="false"
    half-increments
    hover
    empty-icon="mdi-star"
    color="accent"
    background-color="light-green"
    :size="size"
    @input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: 'BaseRating',
  props: {
    size: {
      type: Number,
      default: 25
    }
  }
}
</script>
