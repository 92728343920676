import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { setupVueErrorHandling, setupPageTracking } from './appinsights-vue'
import Vue from 'vue'

export default function (ctx, inject) {
  const config = {"instrumentationKey":"5dbdf755-72be-4265-8b6c-aa90ef3e16dc"}
  // runtimeConfig
  const runtimeConfig = ctx.$config && ctx.$config.appInsights || {}

  const appInsights = new ApplicationInsights({
     config: {...config, ...runtimeConfig}
  })

  inject('appInsights', appInsights)

  // Initialize appInsights

  // Initialize appInsights

  appInsights.loadAppInsights()
  setupVueErrorHandling(Vue, appInsights)

  setupPageTracking(ctx.app.router, appInsights)
}
