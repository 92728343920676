import prismicAPI from '@/helpers/prismic-api'
import { removeNamespace } from '@/helpers/utilities'

export const LAYOUT_TYPES = {
  getters: {
    conditionDescriptionByCondition: 'layout/conditionDescriptionByCondition'
  },
  mutations: {
    SET_DISPLAY_MOBILE_MENU: 'layout/SET_DISPLAY_MOBILE_MENU',
    SET_GENERAL_INFO: 'layout/SET_GENERAL_INFO',
    SET_NOTIFY_BANNER: 'layout/SET_NOTIFY_BANNER'
  },
  actions: {
    loadGeneralInfo: 'layout/loadGeneralInfo',
    loadNotifyBanner: 'layout/loadNotifyBanner'
  }
}

const _types = removeNamespace('layout', LAYOUT_TYPES)

const state = () => ({
  displayMobileMenu: false,
  generalInfo: {},
  notifyBanner: null
})

const getters = {
  [_types.getters.conditionDescriptionByCondition]: state => (condition) => {
    if (!state.generalInfo.condition_descriptions) {
      return
    }
    const conditionDescription = state.generalInfo.condition_descriptions.find(x => x.condition === condition)
    return conditionDescription ? conditionDescription.description : null
  }
}

const mutations = {
  [_types.mutations.SET_DISPLAY_MOBILE_MENU] (state, displayMobileMenu) {
    state.displayMobileMenu = displayMobileMenu
  },
  [_types.mutations.SET_GENERAL_INFO] (state, generalInfo) {
    state.generalInfo = generalInfo
  },
  [_types.mutations.SET_NOTIFY_BANNER] (state, banner) {
    if (banner && banner.desktop_image && banner.desktop_image.url) {
      const findUrl = banner.desktop_image.url
      const paramIndex = findUrl.lastIndexOf("?")
      banner.desktop_image.url = paramIndex > 0 ? findUrl.substring(0, findUrl.lastIndexOf("?")) : findUrl
    }
    state.notifyBanner = banner
  }
}

const actions = {
  async [_types.actions.loadGeneralInfo] ({ commit }, { req, error }) {
    try {
      const generalInfo = await prismicAPI.getByUID('general', 'general', req)
      if (generalInfo && generalInfo.document) {
        commit(_types.mutations.SET_GENERAL_INFO, generalInfo.document)
      }
    } catch (e) {
      error(e)
    }
  },
  async [_types.actions.loadNotifyBanner] ({ commit }, { req, error }) {
    try {
      const res = await prismicAPI.getSingle('banner_text', req)
      if (res && res.document) {
        commit(_types.mutations.SET_NOTIFY_BANNER, res.document)
      }
    } catch (e) {
      error(e)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
