<template>
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
    style="enable-background:new 0 0 20 20;"
    xml:space="preserve"
  >
    <g>
      <rect x="1" y="11" class="st0" width="8" height="8" />
      <rect x="1" y="1" class="st0" width="8" height="8" />
      <g>
        <rect x="10.5" y="1.75" class="st0" width="8.5" height="1" />
        <rect x="10.5" y="4.5" class="st0" width="6.38" height="1" />
        <rect x="10.5" y="7.25" class="st0" width="4.25" height="1" />
      </g>
      <g>
        <rect x="10.5" y="11.75" class="st0" width="8.5" height="1" />
        <rect x="10.5" y="14.5" class="st0" width="6.38" height="1" />
        <rect x="10.5" y="17.25" class="st0" width="4.25" height="1" />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'ViewListIcon'
}
</script>
<style scoped>
  .st0 {
    fill: currentColor;
  }
</style>
