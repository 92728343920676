<template>
  <div class="logo-banner" :class="{ 'mobile': !mdAndUp }">
    <v-container>
      <client-only>
        <img
          v-if="mdAndUp"
          src="/logo-light.svg"
          alt="logo"
          class="logo-banner-logo"
        >
        <div v-else class="d-flex justify-center align-center">
          <nuxt-link to="/cart" class="cancel-btn font-small font-weight-thin clickable">
            Cancel
          </nuxt-link>
          <h1 class="text--white">
            Checkout
          </h1>
        </div>
      </client-only>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'LogoBanner',
  computed: {
    mdAndUp () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './scss/variables';

  .logo-banner {
    width: 100%;
    height: 19.1em;
    background: linear-gradient(278deg, #737886, #686d7a 17%, $charcoal-grey);
    display: flex;
    align-items: center;

    .logo-banner-logo {
      width: 20.5em;
    }

    .cancel-btn {
      position: absolute;
      left: 20px;
      color: $grey;
      text-decoration: none;
    }

    &.mobile {
      background: $charcoal-grey;
      height: 64px;
    }
  }
</style>
