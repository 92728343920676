<template>
  <div v-show="loading" class="v-spinner" :style="[loaderStyle]">
    <div class="v-pulse v-pulse1" :class="[color]" :style="[spinnerStyle,spinnerDelay1]" />
    <div class="v-pulse v-pulse2" :class="[color]" :style="[spinnerStyle,spinnerDelay2]" />
    <div class="v-pulse v-pulse3" :class="[color]" :style="[spinnerStyle,spinnerDelay3]" />
  </div>
</template>

<script>
export default {
  name: 'BaseLoader',
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'light-grey'
    },
    size: {
      type: String,
      default: '9px'
    },
    dotMargin: {
      type: String,
      default: '4px 2px 0 2px'
    },
    radius: {
      type: String,
      default: '100%'
    },
    margin: {
      type: String,
      default: '25px auto'
    }
  },
  data () {
    return {
      spinnerStyle: {
        width: this.size,
        height: this.size,
        margin: this.dotMargin,
        borderRadius: this.radius,
        display: 'inline-block',
        animationName: 'v-pulseStretchDelay',
        animationDuration: '0.75s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'cubic-bezier(.2,.68,.18,1.08)',
        animationFillMode: 'both'
      },
      spinnerDelay1: {
        animationDelay: '0.12s'
      },
      spinnerDelay2: {
        animationDelay: '0.24s'
      },
      spinnerDelay3: {
        animationDelay: '0.36s'
      },
      loaderStyle: {
        margin: this.margin
      }
    }
  }
}
</script>

<style>
  .v-spinner {
    text-align: center;
  }
  @-webkit-keyframes v-pulseStretchDelay
  {
    0%,
    80%
    {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-opacity: 1;
      opacity: 1;
    }
    45%
    {
      -webkit-transform: scale(0.1);
      transform: scale(0.1);
      -webkit-opacity: 0.7;
      opacity: 0.7;
    }
  }
  @keyframes v-pulseStretchDelay
  {
    0%,
    80%
    {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-opacity: 1;
      opacity: 1;
    }
    45%
    {
      -webkit-transform: scale(0.1);
      transform: scale(0.1);
      -webkit-opacity: 0.7;
      opacity: 0.7;
    }
  }
</style>
