<template>
  <v-app>
    <div class="app-body">
      <nuxt />
    </div>
  </v-app>
</template>

<script>

export default {
  name: 'BakerKiosk'
}
</script>
