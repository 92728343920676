import { render, staticRenderFns } from "./logo-banner.vue?vue&type=template&id=66c2b3d2&scoped=true&"
import script from "./logo-banner.vue?vue&type=script&lang=js&"
export * from "./logo-banner.vue?vue&type=script&lang=js&"
import style0 from "./logo-banner.vue?vue&type=style&index=0&id=66c2b3d2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66c2b3d2",
  null
  
)

export default component.exports