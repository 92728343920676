<template>
  <div>
    <v-img
      v-if="src"
      :src="src"
      v-bind="$attrs"
      @error="src = defaultImageUrl"
      @load="$emit('imageLoaded', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseImage',
  props: {
    source: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {
      src: null,
      defaultImageUrl: '/no-image-available.svg'
    }
  },
  watch: {
    source: {
      immediate: true,
      handler (source) {
        this.src = source || this.defaultImageUrl
      }
    }
  }
}
</script>
